<template>
  <div>
    <button
      class="d-none"
      data-target="#createEventModal"
      data-toggle="modal"
      ref="openEditEventModal"
      v-on:click="resetForms"
    >
    </button>

    <div
      class="modal fade overflow-hidden"
      id="createEventModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog product-cart-modal" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-left-wrapper">
              <div class="left-content">
                <div class="modal-header">
                  <span class="modal-title" id="exampleModalLabel"
                    >Edit Event</span
                  >
                  <button
                    type="button"
                    class="close-modal d-block d-md-none"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeEventModal"
                  >
                    <img src="../../../../assets/images/cross.png" alt="" />
                  </button>
                </div>
                <div class="event-image my-3">
                  <div class="event-image-edit">
                    <img
                      v-if="
                        this.eventAvatar.length == 0 &&
                          this.eventFinalAvatar == ''
                      "
                      src="../../../../assets/event-avatar.png"
                      alt=""
                      class="default-image"
                    />
                    <img
                      v-else-if="this.eventAvatar.length == 0"
                      :src="this.$eventAvatarStorageURL + singleEvent.avatar"
                      alt=""
                    />
                    <clipper-basic
                      v-else-if="this.eventAvatar.length > 0"
                      :src="this.rawData"
                      preview="preview"
                      :border="4"
                      :outline="0"
                      :corner="false"
                      :ratio="1"
                      :wrap-ratio="1"
                       :init-width="100"
                        :init-height="100"
                      ref="clipper"
                    ></clipper-basic>
                    <div class="profile-photo-edit-icon-wrapper" @click="openinput()">
                      <img src="../../../../assets/images/edit.png" alt="" />
                    </div>
                  </div>
                  <div class="preview-image">
                    <div v-if="this.eventAvatar.length > 0">
                      <clipper-preview name="preview"></clipper-preview>
                    </div>
                    <div
                      v-else-if="this.eventAvatar.length == 0"
                      class="no-image-wrapper"
                    >
                      <div slot="placeholder">No image</div>
                    </div>
                  </div>
                </div>
                <div class="event-detail">
                  <div class="container-fluid no-pdd">
                    <div class="row">
                      <div
                        class="col-sm-12"
                        v-if="
                          selectedRoomTeam.sportType ==
                            'Softball (Slow Pitch)' ||
                            selectedRoomTeam.sportType ==
                              'Softball (Fast Pitch)' ||
                            selectedRoomTeam.sportType == 'Softball'
                        "
                      >
                        <div class="form-group">
                          <label for="eventName">Event Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="eventName"
                            v-model="eventName"
                            @input="errorNull"
                            required
                            maxlength="100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6" v-else>
                        <div class="form-group">
                          <label for="eventName">Event Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="eventName"
                            v-model="eventName"
                            @input="errorNull"
                            required
                            maxlength="100"
                          />
                        </div>
                      </div>
                      <div
                        class="col-sm-12 col-md-6"
                        v-if="
                          selectedRoomTeam.sportType ==
                            'Softball (Slow Pitch)' ||
                            selectedRoomTeam.sportType ==
                              'Softball (Fast Pitch)' ||
                            selectedRoomTeam.sportType == 'Softball'
                        "
                      >
                        <div class="">
                          <label class="typo__label" for="ajaxsingle"
                            >Associations</label
                          >
                          <multiselect
                            v-model="association"
                            id="ajaxsingle"
                            label="association_name"
                            track-by="id"
                            placeholder="Type to search"
                            open-direction="bottom"
                            :options="associationsAdd"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFind"
                            @change="errorNull"
                            @select="dispatchAction"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag"
                                >&nbsp;<span>
                                  {{ option.association_name }}</span
                                ><span
                                  class="custom__remove"
                                  v-on:click="remove(option)"
                                  >❌</span
                                ></span
                              ></template
                            >
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="associationsAdd.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div> </template
                            ><span slot="noResult"
                              >Oops! No User found. Consider changing the search
                              query.</span
                            >
                            <span slot="noOptions">
                              No User Found
                            </span>
                            <template slot="option" slot-scope="{ option }">
                              <div class="option__desc">
                                <span> {{ option.association_name }}</span>
                              </div>
                            </template>
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group">
                          <label class="typo__label" for="ajaxsingle"
                            >Event Type</label
                          >
                          <select
                            class="form-control form-control-lg"
                            id="eventType"
                            v-model="eventType"
                            @change="errorNull"
                          >
                            <option value="" selected disabled
                              >Event Type</option
                            >
                            <option
                              v-for="option in filteredEventTypeOptions"
                              :value="option.value"
                              :key="option.value"
                              
                            >
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div
                          class="label-type error"
                          v-if="startDateError != ''"
                        >
                          Please select Start Date.
                        </div>
                        <div class="form-group">
                          <label for="startDateCheck">Start Date</label>
                          <span
                            class="label-type error event-error ml-2"
                            v-if="startDateError != ''"
                          >
                            Please select Start Date.
                          </span>
                          <!-- <div
                            class="input-group date"
                            id="startDateCheck"
                            data-target-input="nearest"
                          >
                            <input
                              class="form-control"
                              placeholder="Start Date"
                              required="required"
                              id="startDate"
                              type="date"
                              v-model="startDate"
                              v-on:change="checkdate"
                              onkeydown="return false"
                            />
                          </div> -->
                          <date-picker
                            :open="open"
                            @close="open = false"
                            :editable="false"
                            :clearable="false"
                            @click.native="handleClickStart"
                            v-model="startDate"
                            valueType="format"
                            format="MMM DD, YYYY"
                            placeholder="mmm dd, yyyy"
                            @change="checkdate"
                          >
                            <i slot="icon-calendar" class="cursor-pointer">
                              <img src="../../../../assets/images/calendar-input.png" />
                            </i>
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group">
                          <label for="endDateCheck">End Date</label>
                          <span
                            class="label-type error event-error ml-2"
                            v-if="endDateError != ''"
                          >
                            Please select End Date.
                          </span>
                          <span
                            class="label-type error event-error ml-2"
                            v-if="endDateErrorGreater != ''"
                          >
                            End Date should be greater than start date
                          </span>
                          <!-- <div
                            class="input-group date"
                            id="endDateCheck"
                            data-target-input="nearest"
                          >
                            <input
                              class="form-control"
                              placeholder="End Date"
                              required="required"
                              id="endDate"
                              type="date"
                              v-model="endDate"
                              v-on:change="checkdate"
                              onkeydown="return false"
                            />
                          </div> -->
                          <date-picker
                            :open="openEnd"
                            @close="openEnd = false"
                            :editable="false"
                            :clearable="false"
                            @click.native="handleClickEnd"
                            v-model="endDate"
                            valueType="format"
                            format="MMM DD, YYYY"
                            placeholder="mmm dd, yyyy"
                            @change="checkdate"
                          >
                            <i slot="icon-calendar" class="cursor-pointer">
                              <img src="../../../../assets/images/calendar-input.png" />
                            </i>
                          </date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div
                          class="form-group d-flex align-items-center justify-content-between"
                        >
                          <label class="mt-2 mt-lg-4">All Day Event</label>
                          <toggle-button
                            class="toogle m-0 mt-2 mt-lg-4"
                            @change="showHideTimeDiv()"
                            :value="allDay"
                            :sync="true"
                            :width="34"
                            v-model="allDay"
                            ref="toggleBtn"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-6 mt-1 startTime">
                        <label for="startTimeCheck">Start Time</label>
                        <div class="form-group">
                          <span
                            class="label-type error event-error ml-2"
                            v-if="timeError != ''"
                          >
                            Event Time is Invalid.
                          </span>
                          <div
                            class="input-group time"
                            id="startTimeCheck"
                            data-target-input="nearest"
                          >
                            <vue-timepicker
                              id="startTime"
                              class="time-input"
                              placeholder="Start Time"
                              required="required"
                              v-model="startTime"
                              v-on:change="checkTime"
                              close-on-complete
                              hide-clear-button
                            >
                              <template v-slot:icon>
                                <img
                                  src="../../../../assets/images/clock-input.png"
                                />
                              </template>
                            </vue-timepicker>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-1 endTime">
                        <label for="endTimeCheck">End Time</label>
                        <div class="form-group">
                          <div
                            class="input-group time"
                            id="endTimeCheck"
                            data-target-input="nearest"
                          >
                            <vue-timepicker
                              id="endTime"
                              class="time-input"
                              placeholder="End Time"
                              required="required"
                              v-model="endTime"
                              v-on:change="checkTime"
                              close-on-complete
                              hide-clear-button
                            >
                              <template v-slot:icon>
                                <img
                                  src="../../../../assets/images/clock-input.png"
                                />
                              </template>
                            </vue-timepicker>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="form-group pdd-adjust-type">
                          <label class="typo__label" for="ajaxsingle"
                            >Select Time Zone</label
                          >
                          <select
                            class="form-control form-control-lg"
                            id="time_zone"
                            v-model="time_zone"
                            @change="onTimeZoneChange"
                          >
                            <option value="" disabled
                              >Time Zone
                            </option>
                            <!-- <option :value="formattedDeviceTimeZone">{{ formattedDeviceTimeZone }}</option> -->
                            <option
                              :value="country.gmt + ' ' + country.name"
                              v-for="country in timeZoneList"
                              :key="country.name"
                            >
                              {{ country.gmt }} {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="note">Event Notes</label>
                          <textarea
                            type="text"
                            class="form-control pt-3 mt-2 add-notes"
                            id="note"
                            rows="4"
                            v-model="note"
                            @keyup="errorNull"
                            maxlength="100"
                          ></textarea>
                        </div>
                      </div>
                      <input
                        type="file"
                        class="d-none"
                        id="vue-file"
                        v-on:change="addEventAvatar"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-content container-fluid">
                <div>
                  <button
                    type="button"
                    class="close-modal d-none d-md-block w-100 text-right pb-2"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeEventModal"
                  >
                    <img src="../../../../assets/images/cross.png" alt="" />
                  </button>
                  <ul class="event-menu-list">
                    <li
                      class="list-item"
                      v-bind:class="[openTabEvent == 1 ? 'active' : '']"
                      @click="InPerson"
                    >
                      <img
                        v-if="openTabEvent == 1"
                        src="../../../../assets/images/inperson-blue.png"
                      />
                      <img
                        v-else
                        src="../../../../assets/images/inperson-grey.png"
                      />
                      InPerson
                    </li>
                    <li
                      class="list-item"
                      v-bind:class="[openTabEvent == 2 ? 'active' : '']"
                      @click="onlineContent"
                    >
                      <img
                        v-if="openTabEvent == 2"
                        src="../../../../assets/images/online-blue.png"
                      />
                      <img
                        v-else
                        src="../../../../assets/images/online-grey.png"
                      />
                      Online
                    </li>
                  </ul>
                  <div class="row" v-if="openTabEvent == 1">
                    <div class="col-12">
                      <div class="form-group">
                        <gmap-autocomplete
                          :value="location"
                          placeholder="Enter an address, zipcode, or location"
                          @place_changed="setPlace"
                          ref="autocompleteRef"
                        >
                        </gmap-autocomplete>
                        <!-- <place-autocomplete-field
                          id="location"
                          v-model="location"
                          placeholder="Enter an address, zipcode, or location"
                          name="location"
                          @autocomplete-select="errorNull"
                          api-key="AIzaSyAEKVEK50teUWnFIJ6vA4ONqe8ZI27V6J8"
                          v-place-autofill:street="street"
                          v-place-autofill:city="city"
                          v-place-autofill:state="state"
                          v-place-autofill:zipcode="zipCode"
                          v-place-autofill:country="country"
                          v-place-autofill:latitude="marker.position.lat"
                          v-place-autofill:longitude="marker.position.lng"
                        ></place-autocomplete-field> -->
                      </div>
                    </div>
                    <div class="col-12">
                      <google-map
                        :center="marker.position"
                        :zoom="18"
                        map-style-id="roadmap"
                        :options="mapOptions"
                        style="width: 100vmin; height: 45vmin; margin-bottom: 10px;"
                        ref="mapRef"
                        id="map"
                        @click="handleMapClick"
                      >
                        <google-maps-marker
                          :position="marker.position"
                          :clickable="true"
                          :draggable="true"
                          @drag="handleThrottledMarkerDrag"
                          @click="panToMarker"
                        ></google-maps-marker>
                      </google-map>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="street"
                          v-model="street"
                          @keyup="errorNull"
                          required
                          placeholder="Street Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="openTabEvent == 1">
                    <div class="col-12 col-md-4 pr-md-2">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="city"
                          v-model="city"
                          @keyup="errorNull"
                          required
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-4 px-md-0">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="state"
                          v-model="state"
                          @keyup="errorNull"
                          required
                          placeholder="State"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pl-md-2">
                      <div class="form-group">
                        <input
                          type="number"
                          class="form-control"
                          id="zipCode"
                          v-model="zipCode"
                          @keyup="errorNull"
                          required
                          placeholder="ZipCode"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="openTabEvent == 2">
                    <div class="col-12">
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg"
                          id="medium"
                          v-model="medium"
                          @change="errorNull"
                        >
                          <option value="" selected>Select Medium</option>
                          <option
                            v-for="option in mediumOptions"
                            :key="option.id"
                            :value="option.id"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="zipCode"
                          v-model="Url"
                          @keyup="errorNull"
                          @input="change($event)"
                          @change="change($event)"
                          required
                          placeholder="Enter URL"
                        />
                        <div class="error" v-if="!isValid">URL is Invalid</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="event-btns-wrapper">
                  <button class="btn btn-danger w-50" @click="deleteEvent()">
                    <img src="../../../../assets/images/trash.png" />
                    <span>Delete Event</span>
                  </button>
                  <button
                    v-if="this.isDisabledEvent == false"
                    type="button"
                    class="btn btn-primary w-50"
                    v-on:click="editEvent(0)" 
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Update</span>
                  </button>
                  <button
                    v-else-if="this.isDisabledEvent == true"
                    type="button"
                    class="btn btn-primary-disabled w-50"
                    :disabled="(true, isLoadingArray[0])"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Update</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    ref="mydetailTab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  ></a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mr-0"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  ></a>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div class="container-fluid mt-3 no-pdd">
                  <div class="row">
                    <div v-if="checkedOnline == true" class="col-6">
                      <p class="mt-2" style="color:#557491">
                        Make it physical.
                      </p>
                    </div>
                    <div v-if="checkedOnline == false" class="col-6">
                      <p class="mt-2" style="color:#557491">
                        Want to make it an online event ?
                      </p>
                    </div>
                    <div class="col-6">
                      <toggle-button
                        class="float-right toogle"
                        v-model="onlineStatus"
                        :value="false"
                        :sync="true"
                        :width="34"
                        @change="ifOnline"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="join-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import * as firestoreService from "../../../../database/firestore";
import $ from "jquery";
import Multiselect from "vue-multiselect";
//let countries = require("../../../../assets/js/country");
let eventType = require("../../../../assets/js/eventType");

let usTime = require("../../../../assets/js/usTimeZone");
import _ from 'lodash';
import { clipperBasic, clipperPreview } from "vuejs-clipper";

export default {
  components: {
    Multiselect,
    clipperBasic,
    clipperPreview,
    VueTimepicker,
  },
  data() {
    return {
      open: false,
      openEnd: false,
      eventURL: this.$eventAvatarStorageURL,
      userName: localStorage.getItem("userName"),
      isDisabledEvent: true,
      showLoaderEvent: 0,
      eventName: null,
      eventType: "",
      eventTypeOptions: eventType,
      association: "",
      location: "",
      exactEndTime: "11:00 AM",
      exactStartTime: "10:00 AM",
      exactStartDate: "",
      exactEndDate: "",
      startDate: "",
      endDate: "",
      startTime: "10:00 AM",
      endTime: "11:00 AM",
      city: "",
      state: "",
      zipCode: "",
      street: "",
      country: "",
      lan: "",
      long: "",
      note: "",
      allDay: false,
      rawData: "",
      openTabEvent: 1,
      isLoadingArray: [],
      eventAvatar: [],
      eventFinalAvatar: "",
      options: [],
      medium: "",
      mediumOptions: [],
      marker: { position: { lat: 10, lng: 10 } },
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: false,
      },
      startDateError: "",
      endDateError: "",
      endDateErrorGreater: "",
      exactStartDateformat: "",
      savedLocationData: null,
      savedEventData: null,
      exactEndDateformat: "",
      timeError: "",
      onlineStatus: false,
      checkedOnline: false,
      Url: "",
      isValid: true,
      associationsAdd: [],
      isLoading: false,
      assoChange: 0,
      timeZoneList: usTime,
      time_zone: "",
      deviceTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      Storage_URL: this.$googleMapApi,
      latLng: {},
      userInteracted: false,
    };
  },

  watch: {
    time_zone() {
      if (this.userInteracted) {
        return;
      }
      this.setDefaultTimeZone();
    },
    singleEvent: {
    handler() {
      this.checkOpenTabEvent();
    },
    deep: true
  },
    city(newValue) {
      this.city = newValue;
      this.errorNull();
    },
    state(newValue) {
      this.state = newValue;
      this.errorNull();
    },
    street(newValue) {
      this.street = newValue;
      this.errorNull();
    },
    zipCode(newValue) {
      this.zipCode = newValue;
      this.errorNull();
    },
  },
  computed: {
    formattedDeviceTimeZone() {
      const gmtOffset = this.getDynamicGmtOffset(this.deviceTimeZone);
      const normalizedTimeZone = this.getNormalizedDeviceTimeZone(this.deviceTimeZone);
      return gmtOffset ? `${gmtOffset} ${normalizedTimeZone}` : this.deviceTimeZone;
    },
    filteredEventTypeOptions() {
        return this.eventTypeOptions.filter(option => option.status === 1);
    }
  },
  methods: {
    onTimeZoneChange() {
      this.userInteracted = true;
      this.errorNull();
    },
      // Normalize device time zone to a more familiar name if needed
  getNormalizedDeviceTimeZone(deviceTimeZone) {
    if (deviceTimeZone === "Pacific/Honolulu") {
      return "Hawaii Time";
    }
    else if (deviceTimeZone === "America/Los_Angeles") {
      return "Pacific Time";
    }
    else if (deviceTimeZone === "America/Anchorage") {
      return "Alaska Time";
    }
    else if (deviceTimeZone === "America/Denver") {
      return "Mountain Time";
    }
    else if (deviceTimeZone === "America/Chicago") {
      return "Central Time";
    }
    else if (deviceTimeZone === "America/New_York") {
      return "Eastern Time";
    }
    return deviceTimeZone;
  },
  //   findTimeZoneMatch(timezone) {
  //   return this.timeZoneList.some(
  //     country => timezone === (country.gmt + ' ' + country.name)
  //   );
  // },
    getDynamicGmtOffset() {
      const date = new Date();
      const utcOffset = date.getTimezoneOffset(); // Offset in minutes from UTC
      const hoursOffset = Math.floor(Math.abs(utcOffset) / 60);
      const minutesOffset = Math.abs(utcOffset) % 60;

      // Determine the sign for the offset
      const sign = utcOffset < 0 ? '+' : '-';

      // Format the GMT offset
      return `(UTC${sign}${String(hoursOffset).padStart(2, '0')}:${String(minutesOffset).padStart(2, '0')})`;
    },
    setDefaultTimeZone() {
    const match = this.timeZoneList.find(
      (country) => `${country.gmt} ${country.name}` === this.formattedDeviceTimeZone
    );
    if (this.singleEvent.time_zone !== null) {
      this.time_zone = this.singleEvent.time_zone;
    }
    else if (match) {
      this.$nextTick(() => {
        this.time_zone = `${match.gmt} ${match.name}`;
      });
    } else {
      this.time_zone = ""; // Shows "Time Zone" if no match is found
    }
    },
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    handleClickEnd(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.openEnd = true;
      }
    },

    onlineContent() {
  if (!this.onlineStatus) {
    this.savedLocationData = {
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      street: this.street,
    };
    // console.log("Saved Location Data:", this.savedLocationData);
  }
  // Save medium and Url data
  this.savedEventData = {
    medium: this.medium,
    Url: this.Url
  };
  // console.log("Saved Event Data:", this.savedEventData);
  this.$root.$emit("openTabTeamEvent", 2, 0);
  this.city = "";
  this.state = "";
  this.zipCode = "";
  this.street = "";
  if (this.medium != "" && this.Url != "") {
    this.isDisabledEvent = false;
  } 
  // console.log("Location fields cleared for Online tab");
  this.checkTab(2);
},
InPerson() {
  if (this.savedLocationData) {
    this.city = this.savedLocationData.city;
    this.state = this.savedLocationData.state;
    this.zipCode = this.savedLocationData.zipCode;
    this.street = this.savedLocationData.street;
  }
  // Restore medium and Url data
  if (this.savedEventData) {
    this.medium = this.savedEventData.medium;
    this.Url = this.savedEventData.Url;
  }
  // console.log("Restored Event Data:", this.savedEventData);
  // console.log("Restored Url:", this.Url); // Add this line to check the restored Url
  this.$root.$emit("openTabTeamEvent", 1, 0);
  if (this.medium == "" && this.Url == "") {
    this.isDisabledEvent = true;
  } else if (this.city === "" && this.state === "" && this.zipCode === "" && this.street === "") {
    this.isDisabledEvent = true;
  } else {
    this.isDisabledEvent = false;
  }
  this.checkTab(1);
},

    checkTab(check) {
      if (check == 1) {
        this.onlineStatus = false;
      } else {
        this.onlineStatus = true;
      }
      if (this.onlineStatus == true) {
        this.checkedOnline = true;
      } else {
        this.checkedOnline = false;
        // this.Url = "";
      }
    },
    checkOpenTabEvent() {
      if (this.singleEvent && this.singleEvent.medium_id !== null && this.singleEvent.medium_id !== 0) {
        this.openTabEvent = 2;
      } else {
        this.openTabEvent = 1;
      }
    },
    async deleteEvent() {
      this.$confirm(
        "About to remove this event from the team. Continue ?",
        "Delete Event."
      ).then(() => {
        var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
        axios
          .post(process.env.VUE_APP_API_URL + "event/delete", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            firestoreService.updateRoom(this.selectedRoomTeam.roomId, {
              eventCount: this.selectedRoomTeam.eventCount - 1,
              // lastUpdated: new Date(),
            });

            this.$root.$emit("showEventList");
            this.$root.$emit("fetchEvents");
            this.$root.$emit("ChatSettings", 0);
            this.$root.$emit("showEventDetailPageBack");

            var elem = this.$refs.closeEventModal;
            if(elem) {
              this.editEventOption = false;
              elem.click();
            }
            this.showLoaderEvent = 0;
            this.assoChange = 0;
            var eventOrigin = localStorage.getItem("eventOrigin")
            if (eventOrigin == 'Teams') {
              this.$router.push(`/my-team/events/${this.selectedRoomTeam.id}`);
            }
            else if (eventOrigin == 'Events') {            
              this.$router.push("/my-events");
            }
          });
      });
    },
    resetForms: function() {
      this.getMedium();
      var element = this.$refs.mydetailTab;
      element.click();
      this.isDisabledEvent = true;
      this.showLoaderEvent = 0;
      this.event_id = "";
      this.eventName = "";
      this.eventType = "";
      this.association = "";
      this.location = "";
      this.exactEndTime = "11:00 AM";
      this.exactStartTime = "10:00 AM";
      this.exactStartDate = "";
      this.exactEndDate = "";
      this.startDate = "";
      this.endDate = "";
      this.startTime = "10:00";
      this.endTime = "11:00";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.street = "";
      this.lan = "";
      this.long = "";
      this.note = "";
      this.allDay = false;
      this.rawData = "";
      this.eventAvatar = [];
      this.eventFinalAvatar = "";
      (this.time_zone = ""), (this.startDateError = "");
      this.endDateError = "";
      this.endDateErrorGreater = "";
      this.timeError = "";

      this.exactStartDateformat = "";
      this.exactEndDateformat = "";
      (this.medium = ""), (this.Url = "");
      this.fetchdata();
    },
    // Fetch Data
    dispatchAction(actionName) {
      switch (actionName.association_name) {
        case "Add Park":
          var tabs = 1;
          this.$root.$emit("AddParkInitiate", tabs);
          var elem = this.$refs.callPark;
          elem.click();
          break;
        default:
          this.assoChange = 1;
          this.association = actionName;
      }
    },
    
    fetchdata() {
      this.eventName = this.singleEvent.eventName;
      this.eventType = this.singleEvent.eventType;
      this.association = this.singleEvent;
      this.location =
        this.singleEvent.location == "null" ? "" : this.singleEvent.location;
      this.city = this.singleEvent.city == "null" ? "" : this.singleEvent.city;
      this.note = this.singleEvent.note == "null" ? "" : this.singleEvent.note;
      this.state = this.singleEvent.state;
      this.zipCode = this.singleEvent.zipCode;
      this.street =
        this.singleEvent.address == "null" ? "" : this.singleEvent.address;

      this.exactStartTime = this.singleEvent.exactStartTime;
      this.exactEndTime = this.singleEvent.exactEndTime;

      this.startTime = this.singleEvent.startTime;
      this.endTime = this.singleEvent.endTime;

      // this.startDate = this.singleEvent.startDateForField;
      // this.endDate = this.singleEvent.endDateForField;
      // if (this.singleEvent.time_zone == null) {
      //   this.time_zone = "";
      // } else {
      //   this.time_zone = this.singleEvent.time_zone;
      // }

      this.time_zone = this.singleEvent.time_zone;

      this.exactStartDateformat = this.singleEvent.startDate;
      this.exactEndDateformat = this.singleEvent.endDate;
      this.marker.position.lng = this.singleEvent.lan;
      this.marker.position.lat = this.singleEvent.long;

      this.exactStartDate = this.singleEvent.exactStartDate;
      this.exactEndDate = this.singleEvent.exactEndDate;
      this.allDay = this.singleEvent.allDay == "true" ? true : false;
      this.rawData = "";
      this.eventAvatar = [];
      this.createdAt = this.singleEvent.createdAt;
      this.createdBy = this.singleEvent.createdBy;
      this.medium = this.singleEvent.medium_id ? this.singleEvent.medium_id: "";
      this.Url = this.singleEvent.Url ? this.singleEvent.Url: "";

      if (this.singleEvent.avatar != null) {
        this.eventFinalAvatar = this.singleEvent.avatar;
        this.rawData = this.eventURL + this.eventFinalAvatar;
      }
      if (this.singleEvent.status == 2) {
        this.checkedOnline = true;
        this.onlineStatus = true;
      } else {
        this.checkedOnline = false;
        this.onlineStatus = false;
      }
      if(this.onlineStatus == true) {
        this.openTabEvent = 2
        this.street = "";
        this.state = "";
        this.zipCode = "";
        this.city = ""
      }
      else {
        this.openTabEvent = 1
      }
      let lat = parseFloat(this.singleEvent.lan);
      let lng = parseFloat(this.singleEvent.long);
      this.marker.position = {
        lat: lat,
        lng: lng,
      };
      this.initializeDate();
      this.showHideTimeDiv2();
      this.errorNull();
      this.panToMarker();
    },

    errorNull: function() {
      if (this.checkedOnline != true) {
        if (
          this.eventName == "" ||
          this.exactStartDate == "" ||
          this.exactEndDate == "" ||
          this.eventType == "" ||
          this.association == "" ||
          this.city == "" ||
          this.city == null ||
          this.street == "" ||
          this.street == null ||
          this.state == "" ||
          this.state == null ||
          this.zipCode == null ||
          this.zipCode == "" ||
          this.timeError != ""
        ) {
          this.isDisabledEvent = true;
        } else {
          this.isDisabledEvent = false;
        }
      }
      if (this.checkedOnline == true) {
        if (
          this.eventName == "" ||
          this.exactStartDate == "" ||
          this.exactEndDate == "" ||
          this.eventType == "" ||
          this.association == "" ||
          this.medium == "" ||
          this.medium == null ||
          this.Url == "" ||
          this.Url == null ||
          this.timeError != "" ||
          this.isValid == false
        ) {
          this.isDisabledEvent = true;
        } else {
          if (
            (this.exactStartTime == "" || this.exactEndTime == "") &&
            this.allDay == false
          ) {
            this.isDisabledEvent = true;
          } else {
            this.isDisabledEvent = false;
          }
        }
      }
    },

    checkTime: function() {
      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes,
          meridian;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          meridian = "PM";
          hours -= 12;
        } else if (hours < 12) {
          meridian = "AM";
          if (hours == 0) {
            hours = 12;
          }
        } else {
          meridian = "PM";
        }
        this.exactStartTime = hours + ":" + minutes + " " + meridian;
      }

      if (this.endTime != "") {
        var timeSplitend = this.endTime.split(":"),
          hoursend,
          minutesend,
          meridianend;
        hoursend = timeSplitend[0];
        minutesend = timeSplitend[1];
        if (hoursend > 12) {
          meridianend = "PM";
          hoursend -= 12;
        } else if (hoursend < 12) {
          meridianend = "AM";
          if (hoursend == 0) {
            hoursend = 12;
          }
        } else {
          meridianend = "PM";
        }
        this.exactEndTime = hoursend + ":" + minutesend + " " + meridianend;
      }
      this.checkTimeDate();
    },

    // Check dates
    // checkdate: function() {
    //   const month = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "July",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   // Start Date
    //   if (this.startDate.length == 10) {
    //     const startDateArray = this.formatDateForDB(this.startDate).split("-");
    //     var startMonth = startDateArray[1];
    //     var startDay = startDateArray[2];
    //     var startYear = startDateArray[0];

    //     var varDate = startMonth + "-" + startDay + "-" + startYear;
    //     var startDateConvert = new Date(varDate);
    //     this.exactStartDateformat = startDateConvert;
    //     this.exactStartDate =
    //       month[startMonth - 1] + " " + startDay + ", " + startYear;
    //   }

    //   // End Date-----
    //   if (this.endDate.length == 10) {
    //     const endDateArray = this.formatDateForDB(this.endDate).split("-");
    //     var endMonth = endDateArray[1];
    //     var endDay = endDateArray[2];
    //     var endYear = endDateArray[0];

    //     var varDatee = endMonth + "-" + endDay + "-" + endYear;
    //     var endDateConvert = new Date(varDatee);
    //     if (endDateConvert < startDateConvert) {
    //       this.endDateError = "";
    //       this.endDateErrorGreater = "on";
    //       this.exactEndDate = "";
    //       this.isDisabledEvent = true;
    //     } else {
    //       this.exactEndDateformat = endDateConvert;
    //       this.endDateError = "";
    //       this.endDateErrorGreater = "";
    //       this.exactEndDate =
    //         month[endMonth - 1] + " " + endDay + ", " + endYear;
    //     }
    //   }

    //   this.checkTimeDate();
    // },
    checkdate: function () {
      const month = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      console.log("this.startDate", this.startDate);
      console.log("this.endDate", this.endDate);

      let startDateConvert, endDateConvert;

      // Convert Start Date
      if (this.startDate && this.startDate.length === 12) {  // Format: "Mar 21, 2025"
        const startDateArray = this.startDate.split(" ");
        let startMonth = month.indexOf(startDateArray[0]) + 1; // Convert month to number
        startMonth = startMonth < 10 ? `0${startMonth}` : startMonth; // Add leading zero
        const startDay = startDateArray[1].replace(",", ""); // Remove comma
        const startYear = startDateArray[2];

        const formattedStartDate = `${startMonth}-${startDay}-${startYear}`;
        startDateConvert = new Date(formattedStartDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate = `${month[startMonth - 1]} ${startDay}, ${startYear}`;
      }

      // Convert End Date
      if (this.endDate && this.endDate.length === 12) {  // Format: "Mar 21, 2025"
        const endDateArray = this.endDate.split(" ");
        let endMonth = month.indexOf(endDateArray[0]) + 1; // Convert month to number
        endMonth = endMonth < 10 ? `0${endMonth}` : endMonth; // Add leading zero
        const endDay = endDateArray[1].replace(",", ""); // Remove comma
        const endYear = endDateArray[2];

        const formattedEndDate = `${endMonth}-${endDay}-${endYear}`;
        endDateConvert = new Date(formattedEndDate);

        if (startDateConvert && endDateConvert < startDateConvert) {
          this.endDateError = "";
          this.endDateErrorGreater = "on";
          this.exactEndDate = "";
          this.isDisabledEvent = true;
        } else {
          this.exactEndDateformat = endDateConvert;
          this.endDateError = "";
          this.endDateErrorGreater = "";
          this.exactEndDate = `${month[endMonth - 1]} ${endDay}, ${endYear}`;
        }
      }

      this.checkTimeDate();
    },

    // check timeDate
    checkTimeDate: function() {
      this.startDateError = this.exactStartDate != "" ? "" : "on";
      this.endDateError = this.exactEndDate != "" ? "" : "on";

      if (this.exactStartTime != "" && this.exactStartDate != "") {
        // var today = new Date();
        // // Start Date
        // const startDateArray = this.startDate.split("-");
        // var startMonth = startDateArray[1];
        // var startDay = startDateArray[2];
        // var startYear = startDateArray[0];

        // const startTimeArray = this.exactStartTime.split(":");
        // const startTimeAMArray = startTimeArray[1].split(" ");

        // var hours = startTimeArray[0];
        // var minutes = startTimeAMArray[0];
        // var AMPM = startTimeAMArray[1];
        // if (AMPM == "PM" && hours < 12) hours = parseInt(hours) + 12;
        // if (AMPM == "AM" && hours == 12) hours = parseInt(hours) - 12;
        // var sHours = hours;
        // var sMinutes = minutes;
        // if (hours < 10) sHours = "0" + sHours;
        // if (minutes < 10) sMinutes = "0" + sMinutes;
        // var finalStartDateTime = new Date(
        //   startYear,
        //   startMonth - 1,
        //   startDay,
        //   sHours,
        //   sMinutes
        // );

        this.errorNull();
        // if (finalStartDateTime < today) {
        //   this.timeError = "on";
        //   this.isDisabledEvent = true;
        // } else {
        //   this.timeError = "";
        // }
      }

      // if (
      //   this.exactStartTime != "" &&
      //   this.exactEndTime != "" &&
      //   this.exactStartDate != "" &&
      //   this.exactEndDate != ""
      // ) {
      //   // End Date
      //   const endDateArray = this.endDate.split("-");
      //   var endMonth = endDateArray[1];
      //   var endDay = endDateArray[2];
      //   var endYear = endDateArray[0];

      //   const endTimeArray = this.exactEndTime.split(":");
      //   const endTimeAMArray = endTimeArray[1].split(" ");

      //   var endhours = endTimeArray[0];
      //   var endminutes = endTimeAMArray[0];
      //   var endAMPM = endTimeAMArray[1];
      //   if (endAMPM == "PM" && endhours < 12)
      //     endhours = parseInt(endhours) + 12;
      //   if (endAMPM == "AM" && endhours == 12)
      //     endhours = parseInt(endhours) - 12;
      //   var endsHours = endhours;
      //   var endsMinutes = endminutes;
      //   if (endhours < 10) endsHours = "0" + endsHours;
      //   if (endminutes < 10) endsMinutes = "0" + endsMinutes;
      //   var finalEndDateTime = new Date(
      //     endYear,
      //     endMonth - 1,
      //     endDay,
      //     endsHours,
      //     endsMinutes
      //   );

      //   var hoursFinal = Math.abs(finalStartDateTime - finalEndDateTime);
      //   if (hoursFinal < 1800000) {
      //     this.timeError = "on";
      //     this.isDisabledEvent = true;
      //   } else if (
      //     this.exactStartDateformat.toString() ==
      //       this.exactEndDateformat.toString() &&
      //     AMPM == "PM" &&
      //     endAMPM == "AM"
      //   ) {
      //     this.timeError = "on";
      //     this.isDisabledEvent = true;
      //   }
      //   else if (
      //     this.exactStartDateformat.toString() ==
      //       this.exactEndDateformat.toString() &&
      //     AMPM == "AM" &&
      //     endAMPM == "AM" &&
      //     (endsHours < sHours)
      //   ) {
      //     this.timeError = "on";
      //     this.isDisabledEvent = true;
      //   } else if (
      //     this.exactStartDateformat.toString() ==
      //     this.exactEndDateformat.toString() &&
      //     AMPM == "PM" &&
      //     endAMPM == "PM" &&
      //     (endsHours < sHours)
      //   ) {
      //     this.timeError = "on";
      //     this.isDisabledEvent = true;
      //   }
      //   else {
      //     if (finalStartDateTime < today) {
      //       this.timeError = "on";
      //       this.isDisabledEvent = true;
      //     } else {
      //       this.timeError = "";
      //       this.errorNull();
      //     }
      //   }
      // }
    },

    // Upload Image
    openinput: function() {
      document.getElementById("vue-file").click();
    },
    setPlace(place) {
      if (!place) return;
      this.$refs.autocompleteRef.$el.classList.add("custom-dropdown-open");
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.street = place.formatted_address;
      this.location = place.formatted_address;
      const addressComponents = place.address_components;

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          this.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        } else if (types.includes("postal_code")) {
          this.zipCode = component.long_name;
        }
      }
      this.panToMarker();
    },
    addEventAvatar: function(event) {
      const self = this;
      var fileInput = document.getElementById("vue-file");
      var file_ = fileInput.value;
      var files = event.target.files;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        const file_ = files[0];
        this.eventAvatar.push({
          target: event.target.files[0],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawData = e.target.result;
        };
        reader.readAsDataURL(file_);
        this.errorNull();
      }
    },

    dataURItoBlob: function(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    // Create Event
    async editEvent(index) {
      this.editEventOption = false;
      // Add Avator to storage
      this.$set(this.isLoadingArray, index, true);
      this.isDisabledEvent = true;
      var formData = new FormData();

      if (this.eventAvatar.length > 0) {
        // this.eventAvatar.forEach((value, index) => {
        //   formData.append("avatar[" + index + "]", value.target);
        // });

        const canvas = this.$refs.clipper.clip();
        var dataURL = canvas.toDataURL("image/jpeg", 0.95);
        var blob = this.dataURItoBlob(dataURL);
        this.eventAvatar.forEach((value, index) => {
          formData.append("avatar[" + index + "]", blob);
        });
      }
      this.startTime = this.allDay == true ? "00:00" : this.startTime;
      this.exactStartTime =
        this.allDay == true ? "12:00 AM" : this.exactStartTime;

      this.endTime = this.allDay == true ? "24:00" : this.endTime;
      this.exactEndTime = this.allDay == true ? "12:00 PM" : this.exactEndTime;

      // Convert user array to string
      const userCIDArray = [];
      this.selectedRoomTeam.users.forEach((user) => {
        userCIDArray.push(user.c_id);
      });
      var userString = userCIDArray.toString();

      formData.append("id", this.singleEvent.id);
      formData.append("eventName", this.eventName ? this.eventName : "");
      formData.append("eventType", this.eventType);
      if (this.assoChange == 1) {
        formData.append("association", this.association.id);
      }
      formData.append("startDateForField", this.formatDateForDB(this.startDate));
      formData.append("endDateForField", this.formatDateForDB(this.endDate));
      formData.append("startDate", this.exactStartDateformat);
      formData.append("users", userString);
      formData.append("endDate", this.exactEndDateformat);
      formData.append("startTime", this.startTime);
      formData.append("endTime", this.endTime);
      formData.append("exactStartDate", this.exactStartDate);
      formData.append("exactEndDate", this.exactEndDate);
      formData.append("exactStartTime", this.exactStartTime);
      formData.append("exactEndTime", this.exactEndTime);
      formData.append("allDay", this.allDay);
      formData.append("note", this.note ? this.note : "");
      formData.append("time_zone", this.time_zone ? this.time_zone : "");
      if (this.checkedOnline != true) {
        formData.append("lan", this.marker.position.lat);
        formData.append("long", this.marker.position.lng);
        formData.append("address", this.street ? this.street : "");
        formData.append("location", this.location ? this.location : "");
        formData.append("city", this.city ? this.city : "");
        formData.append("state", this.state ? this.state : "");
        formData.append("zipCode", this.zipCode ? this.zipCode : "");
        formData.append("medium_id","");
        formData.append("Url", "");
        formData.append("online", this.onlineStatus);
      } else if (this.checkedOnline == true) {
        formData.append("medium_id", this.medium );
        formData.append("Url", this.Url);
        formData.append("location", "");
        formData.append("city", "");
        formData.append("state", "");
        formData.append("address", "");
        formData.append("zipCode", "");
        formData.append("online", this.onlineStatus);
      }

      await axios
        .post(process.env.VUE_APP_API_URL + "event/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          firestoreService.updateRoom(this.selectedRoomTeam.roomId, {
            eventCount: this.selectedRoomTeam.eventCount + 1,
            // lastUpdated: new Date(),
          });

          this.$root.$emit("showEventList");
          this.$root.$emit("fetchEvents");
          this.$root.$emit("ChatSettings", 0);
          this.$root.$emit("showEventDetailPageBack");

          var currentRouteName = this.$router.currentRoute.name;

            if (currentRouteName === "SingleEventDetail") {
              this.$root.$emit("showEventPageGames")
            }

          var elem = this.$refs.closeEventModal;
          elem.click();
          this.$set(this.isLoadingArray, index, false);
          this.assoChange = 0;
        });
    },
    getMedium() {
      axios.get(`${process.env.VUE_APP_API_URL}getMedium`).then((response) => {
        if (response.data.statusCode == 200) {
          this.mediumOptions = response.data.data.medium;
        } else {
          this.errorMessage = response.data.message;
        }
      });
    },
    asyncFind(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${
            searchQuery ? searchQuery : ""
          }`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationsAdd = response.data.data.association;
          this.isLoading = false;
        });
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    },
    handleThrottledMarkerDrag: _.throttle(function(event) {
      this.handleMarkerDrag(event);
    }, 50),
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[1]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "locality") {
                    window.test.city = results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route" ||
                results[i]?.types[0] == "neighborhood"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] == "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "postal_code") {
                    window.test.zipCode = results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    getCity(addressArray) {
      let city = "";
      for (let i = 0; i < addressArray.length; i++) {
        // eslint-disable-next-line
        if (
          addressArray[i].types[0] &&
          "locality" == addressArray[i].types[0]
        ) {
          city = addressArray[i].long_name;
          return city;
        }
      }
    },
    //Moves the map view port to marker
    panToMarker() {
      console.log(this.marker.position);
      this.$refs.mapRef.panTo(this.marker.position);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "locality") {
                    window.test.city = results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] == "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "postal_code") {
                    window.test.zipCode = results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    ifOnline() {
      if (this.onlineStatus == true) {
        this.checkedOnline = true;
        this.errorNull();
      } else {
        this.checkedOnline = false;
        this.geolocate();
        this.errorNull();
      }
    },
    change(e) {
      const url = e.target.value;
      this.isURLValid(url);
    },
    isURLValid(inputUrl) {
      var regx = new RegExp(
        "^" +
          // protocol identifier
          "(?:(?:https?|http)://)" +
          // user:pass authentication
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broacast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host name
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          // domain name
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          // TLD identifier
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          ")" +
          // port number
          "(?::\\d{2,5})?" +
          // resource path
          "(?:/\\S*)?" +
          "$",
        "i"
      );
      this.isValid = regx.test(inputUrl);
    },
    showHideTimeDiv() {
      if (this.allDay == true) {
        $(".startTime").hide();
        $(".endTime").hide();
        this.exactStartTime = "";
        this.startTime = "";
        this.endTime = "";
        this.exactEndTime = "";
        // this.allDay = true;
      } else {
        $(".startTime").show();
        $(".endTime").show();

        this.allDay = false;
      }
      this.errorNull();
    },
    showHideTimeDiv2() {
      if (this.allDay == true) {
        $(".startTime").hide();
        $(".endTime").hide();
        this.exactStartTime = "";
        this.startTime = "";
        this.endTime = "";
        this.exactEndTime = "";
        // this.allDay = true;
      } else {
        $(".startTime").show();
        $(".endTime").show();
        this.allDay = false;
      }
    },
    initializeDate() {
        // Ensure the date is valid and set it to the date picker
        if (this.exactStartDateformat) {
            this.startDate = this.parseAndFormatDate(this.exactStartDateformat);
        }
        if (this.exactEndDateformat) {
            this.endDate = this.parseAndFormatDate(this.exactEndDateformat);
        }
        console.log("Formatted Start Date:", this.startDate);
        console.log("Formatted End Date:", this.endDate);
    },

    parseAndFormatDate(dateString) {
        if (!dateString) return "";

        let parsedDate;

        // Normalize the date string (remove timezone text like "Pakistan Standard Time")
        const cleanDateString = dateString.replace(/\sGMT.*$/, ""); 

        // Try parsing as a standard date string
        parsedDate = new Date(cleanDateString);

        if (isNaN(parsedDate.getTime())) {
            // Fallback: Try parsing manually
            try {
                parsedDate = new Date(Date.parse(dateString));
            } catch (e) {
                return "Invalid Date";
            }
        }

        return this.formatDate(parsedDate);
    },

    formatDate(date) {
        if (!(date instanceof Date) || isNaN(date.getTime())) return "Invalid Date";

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date(date);
        const month = monthNames[d.getMonth()];
        const day = String(d.getDate()).padStart(2, '0');
        const year = d.getFullYear();

        return `${month} ${day}, ${year}`;
    },

    formatDateForDB(date) {
          if (!date) return null; // Ensure there's a valid date
          const d = new Date(date);
          return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
      },
    showLocation() {
      var elem = this.$refs.locationTabGet;
      elem.click();
      this.$refs.topSection.scrollTo(0, 0);
      this.geolocate();
    },
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    singleEvent: {
      type: Object,
    },
    editEventOption: {
      type: Boolean
    }
  },
  mounted() {
    this.setDefaultTimeZone();
    this.geolocate();
    this.checkOpenTabEvent();
    this.asyncFind();
    //  Don't allow to select past date
    // var dtToday = new Date();
    // var month = dtToday.getMonth() + 1;
    // var day = dtToday.getDate();
    // var year = dtToday.getFullYear();

    // if(month < 10)
    //     month = '0' + month.toString();
    // if(day < 10)
    //     day = '0' + day.toString();

    // var maxDate = year + '-' + month + '-' + day;
    // $('#startDate').attr('min', maxDate);
    // $('#endDate').attr('min', maxDate);

    // -----------------------------------
    //this.geolocate();
    this.$root.$on("openTabTeamEvent", (id) => {
      this.openTabEvent = id;
    });
    this.$root.$on("openEditEvent", () => {
      var elem = this.$refs.openEditEventModal;
      if(elem)
      {
          elem.click();
      }
      
    });
  },
  name: "EditEvent",
};
</script>
<style scoped>
.event-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 15px;
}

.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}

.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}

.nav-tabs {
  list-style-type: none;
}
.nav-tabs li a {
  display: inline-block;
  padding: 12px 0;
  margin: 0 20px 0 0;
  font-weight: 600;
  font-size: 16px;
  color: #869cb1 !important;
}
.nav-tabs li a.active {
  color: #188ef5 !important;
  border: none;
  border-bottom: 3px solid #188ef5;
}
.nav-tabs .nav-link {
  border: none;
}
.event-image {
  display: flex;
  gap: 10px;
}
.event-image-edit {
  width: 250px;
  height: 250px;
  position: relative;
  border: 2px solid #f1f3f6;
  overflow: hidden;
}
.event-image-edit .default-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.event-image-edit img {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
.input-group-text {
  border-radius: 0 11px 11px 0;
}
div.form-group .add-notes {
  font-size: 14px;
}
.event-detail-btn {
  background-color: #188ef5;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  padding: 10px 12px;
  border: none;
  width: 150px;
  margin: 0 auto;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.event-detail-btn:hover {
  color: #ffffff;
}

.nav-tabs .nav-link:hover {
  border-color: none;
}
.nav-tabs .nav-link.active {
  border-bottom: 3px solid #188ef5;
}
.vue-map-container,
.vue-map-container .vue-map {
  width: 100% !important;
}
.join-footer .btn {
  background-color: #188ef5;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}
.event-detail label {
  font-size: 0.8rem;
}

div.form-group input[type="checkbox"] {
  width: 16px;
}
.profile-photo-edit-icon-wrapper {
  position: absolute;
  top: 82%;
  background-color: #e5f2fe;
  right: 4%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.profile-photo-edit-icon-wrapper img {
  width: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
</style>
