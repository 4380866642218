var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('div',{staticClass:"setting-main-wrapper"},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring-team"}):_vm._e(),_c('div',{staticClass:"main-left-menu"},[_c('LeftMenu')],1),_c('Banner'),_c('div',{staticClass:"team-side"},[_c('div',{staticClass:"chat-side p-0"},[_c('div',{staticClass:"chat-system-options p-0 bg-white"},[_c('ChatSystem',{attrs:{"currentUserId":_vm.currentUserId,"allUsersRef":_vm.allUsersRef,"teamsChatCounter":_vm.teamsChatCounter,"individualChatCounter":_vm.individualChatCounter,"rooms":_vm.rooms}}),(
              _vm.rooms.length == 0 &&
                !_vm.loadingShimmers &&
                _vm.noChatIcon &&
                _vm.tapArchive == 1
            )?_c('NoChat'):_vm._e(),(_vm.rooms.length == 0 && !_vm.loadingShimmers && _vm.tapArchive == 2)?_c('NoArchives'):_vm._e()],1),(!_vm.loadingShimmers)?_c('chat-window',{ref:"chatWindow",attrs:{"show-footer":_vm.showFooter,"current-user-id":_vm.currentUserId,"room-id":_vm.roomId,"textMessages":_vm.locale,"rooms":_vm.displayedRooms,"loading-rooms":_vm.loadingRooms,"messages":_vm.messages,"message-actions":_vm.messageActions,"messages-loaded":_vm.messagesLoaded,"load-first-room":_vm.loadFirstRoom,"rooms-loaded":_vm.roomsLoaded,"room-message":_vm.roomMessage,"link-options":{
            disabled: false,
            target: '_blank',
            rel: null,
          },"menu-actions":_vm.menuActions},on:{"fetch-more-rooms":_vm.fetchMoreRooms,"fetch-messages":_vm.fetchMessages,"send-message":_vm.sendMessage,"delete-message":_vm.deleteMessage,"message-action-handler":_vm.messageActionHandler,"open-file":_vm.openFile,"add-room":_vm.addRoom,"menu-action-handler":_vm.menuActionHandler,"handle-input":_vm.handleInput,"clear-search":_vm.clearSearch}}):_vm._e(),(_vm.selectedRoomType == 'team' && _vm.currentIsFan == '1' && _vm.isMessagesVisible)?_c('div',{staticClass:"no-message-info"},[_vm._v(" Only teammates & admin can send message. ")]):_vm._e(),(_vm.showNoDataFound && !_vm.circleLoader)?_c('div',{staticClass:"no-data-message"},[_vm._v("No data found")]):_vm._e(),_c('div',{staticClass:"col-md-4 col-12 bg-white p-0"},[(_vm.loadingShimmers)?_c('chat-shimmers'):_vm._e()],1)],1),_c('div',{staticClass:"chat-right"},[(_vm.selectedRoomType == 'team' && !_vm.loadingShimmers)?_c('ChatSettings',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam,"currentUserId":_vm.currentUserId,"currentIsAdmin":_vm.currentIsAdmin,"currentIsFan":_vm.currentIsFan,"currentTeamIsActive":_vm.currentTeamIsActive,"showLoader":_vm.showLoaderGameRecord,"totalGame":_vm.totalGame,"win":_vm.win,"loss":_vm.loss}}):_vm._e(),(_vm.loadingShimmers)?_c('chatRightSims'):_vm._e(),(
            _vm.selectedRoomType == 'individual' &&
              !_vm.loadingShimmers &&
              _vm.deleteUserFound == 0
          )?_c('div',[_c('IndChatSetting',{attrs:{"selectedRoomTeam":_vm.selectedRoomTeam,"currentUserId":_vm.currentUserId,"userBlockFlag":_vm.userBlockFlag,"showBlockLoader":_vm.showBlockLoader}})],1):_vm._e(),(_vm.selectedRoomTeam.length == 0 && !_vm.loadingShimmers)?_c('div',{staticClass:"rooms-empty"}):_vm._e(),(_vm.deleteUserFound == 1)?_c('div',{staticClass:"rooms-empty"}):_vm._e()],1)])],1),_c('FlashMessage'),_c('div',{ref:"openMessageInfoModal",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#messageInfo"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"messageInfo","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body p-0"},[_c('div',{staticClass:"message-content"},[(_vm.selectedMessage.content)?_c('p',[_vm._v(" "+_vm._s(_vm.selectedMessage.content)+" ")]):_vm._e(),(
                _vm.selectedMessage.files &&
                  _vm.selectedMessage.files.length == 1 &&
                  _vm.selectedMessage.files[0].extension != 'audio/mp3'
              )?_c('p',{staticClass:"font-style-italic"},[_vm._v(" "+_vm._s(_vm.selectedMessage.files[0].name)+"."+_vm._s(_vm.selectedMessage.files[0].extension)+" ")]):(
                _vm.selectedMessage.files && _vm.selectedMessage.files.length > 1
              )?_c('p',{staticClass:"font-style-italic"},[_vm._v(" Files ")]):(
                _vm.selectedMessage.files &&
                  _vm.selectedMessage.files.length == 1 &&
                  _vm.selectedMessage.files[0].extension == 'audio/mp3'
              )?_c('p',{staticClass:"font-style-italic"},[_vm._v(" Audio File ")]):_vm._e(),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.messageDateTime))])]),_c('div',{staticClass:"message-read-sent"},[_vm._m(1),(_vm.messageSeen.length > 0)?_c('div',{staticClass:"read-by-wrapper"},_vm._l((_vm.messageSeen),function(user){return _c('div',{key:user.name,staticClass:"message-reader-detail"},[_c('div',{staticClass:"user-img"},[(user.avatar != '')?_c('img',{attrs:{"src":user.avatar,"onerror":"this.src='https://whoifollow.tech/api/public/user-default-new.png'","alt":""}}):_vm._e()]),_c('div',{staticClass:"user-name-time"},[_c('h6',[_vm._v(_vm._s(user.name))]),_c('span',[_vm._v(_vm._s(user.date))])])])}),0):_c('div',{staticClass:"read-by"},[_vm._v("---")]),(_vm.messageDelivered.length > 0)?_c('div',{staticClass:"delivered-to"},[_c('h6',[_vm._v("Delivered to")])]):_vm._e(),(_vm.messageDelivered.length > 0)?_c('div',{staticClass:"delivered-to-wrapper"},_vm._l((_vm.messageDelivered),function(user){return _c('div',{key:user.name,staticClass:"message-reader-detail"},[_c('div',{staticClass:"user-img"},[(user.avatar != '')?_c('img',{attrs:{"src":user.avatar,"onerror":"this.src='https://whoifollow.tech/api/public/user-default-new.png'","alt":""}}):_vm._e()]),_c('div',{staticClass:"user-name-time"},[_c('h6',[_vm._v(_vm._s(user.name))]),_c('span',[_vm._v(_vm._s(user.date))])])])}),0):_vm._e()])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header align-items-center border-bottom-0"},[_c('h6',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("Message Info")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"read-by"},[_c('h6',[_vm._v("Read By")])])
}]

export { render, staticRenderFns }