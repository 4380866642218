<template>
  <div>
    <div class="products-list-shim" v-if="events.length == 0 && showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>

    <div class="events-wrapper" v-if="events.length > 0">
      <div class="event-list" v-for="event in events" :key="event.index">
        <div class="event-list-details event-info-wrap">
          <EventWrapper :event="event" :selectedRoomTeam="selectedRoomTeam" />
        </div>
        <UserGoingList
          :selectedRoomTeam="selectedRoomTeam"
          :attendeeListing="event.event_attendee_all"
        />
      </div>
    </div>

    <!-- Fix: Ensure 'No past events' message is displayed after API call -->
    <div class="no-event-content" v-if="events.length == 0 && showLoader == 0">
      <div class="no-event-list">
        <img src="../../../../assets/images/calendar-search.png" alt="" />
        <p class="no-event-header">No past events</p>
        <p class="no-event-text">
          All past events for this team will appear here.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventWrapper from "./EventWrapper.vue";
import UserGoingList from "./UserGoingList.vue";

export default {
  components: { UserGoingList, EventWrapper },
  name: "PastEvents",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  data() {
    return {
      events: [],
      showLoader: 1,
      roomID: "",
      eventURL: this.$eventAvatarStorageURL,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async fetchPastEvents() {
      try {
        this.showLoader = 1;
        const formData = new FormData();
        formData.append("team_id", this.selectedRoomTeam.id);
        let response = await axios.post("/event/eventPastListing", formData);
        if (response.data.statusCode === 200) {
          this.events = response.data.data.listing.data;
          this.$root.$emit("eventsLengths", this.events.length);
        } else {
          console.error("Error fetching past events:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      } finally {
        this.showLoader = 0; // Ensure showLoader is set to 0 after API call
      }
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    showDetails: function (event) {
      this.$root.$emit("showEventDetailPage", event);
    },
  },
  mounted() {
    this.fetchPastEvents();
    this.roomID = this.selectedRoomTeam.roomID;
    this.$root.$on("fetchEventPast", (events) => {
      if (this.events.length != events.length) {
        this.$root.$emit("showEventDetailPageBack");
      }
      this.events = events;
    });
  },
};
</script>
