<template>
  <div class="menuMain">
    <div v-if="showMain == 0" class="menu-items">
      <div @click="showSharedFilesDiv" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/images/shared-files.png" alt="" />
          </div>
          <span>Shared Files</span>
        </div>
        <img src="../../../../assets/images/arrow-right.png" alt="" />
      </div>
      <!-- <div @click="showChatOptionsDiv" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/images/team-options.png" alt="" />
          </div>
          <span>Chat Options</span>
        </div>
        <img src="../../../../assets/images/arrow-right.png" alt="" />
      </div> -->
    </div>

    <div v-else-if="showMain == 1">
      <transition name="slide" enter-active-class="slideInLeft">
        <SharedFiles
          v-if="showSharedFiles == 0"
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
        />

        <ChatOptions
          v-if="showChatOptions == 0"
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
          :userBlockFlag="userBlockFlag"
          :showBlockLoader="showBlockLoader"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import SharedFiles from "../ChatSettingComponents/SharedFiles.vue";
import ChatOptions from "../ChatSettingComponents/ChatOptions.vue";
export default {
  components: {
    ChatOptions,
    SharedFiles,
  },
  name: "IndSettingMenu",
  data: function() {
    return {
      showSharedFiles: 1,
      showChatOptions: 1,
      showMain: 0,
    };
  },
  props: ["selectedRoomTeam", "currentUserId", "userBlockFlag", "showBlockLoader"],
  methods: {
    showSharedFilesDiv: function() {
      this.showSharedFiles = 0;
      this.showChatOptions = 1;
      this.showMain = 1;
      this.$root.$emit("IndChatSettings", 0,1);
    },
    showChatOptionsDiv: function() {
      this.showSharedFiles = 1;
      this.showChatOptions = 0;
      this.showMain = 1;
      this.$root.$emit("IndChatSettings", 0,1);
    },
  },

  mounted() {
    this.$root.$on("IndMenuSettingsDiv", (id) => {
      this.showMain = id;
    });
  },
};
</script>