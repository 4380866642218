<template>
  <div>
    <IndHead :selectedRoomTeam="selectedRoomTeam" :currentUserId="currentUserId" :userBlockFlag="userBlockFlag"
        :showBlockLoader="showBlockLoader" v-if="showHead == 0" />

    <!-- <transition name="slide" enter-active-class="slideInLeft"> -->
    <div class="chat-right-sidebar">
      <IndSettingMenu
        v-if="showMainComponents == 0"
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
        :userBlockFlag="userBlockFlag"
        :showBlockLoader="showBlockLoader"
      />
    </div>
    <!-- </transition> -->
  </div>
</template>
<script>
import IndHead from "../ChatRight/ChatSettingComponents/IndHead";
import IndSettingMenu from "../ChatRight/ChatSettingComponents/IndSettingMenu";


export default {
  components: {
    IndHead,
    IndSettingMenu,
  },
  name: "IndChatSetting",
  props: ["selectedRoomTeam", "currentUserId", "userBlockFlag", "showBlockLoader"],
  data: function () {
    return {
      showMainComponents: 0,
      showHead: 0,
    };
  },

  mounted() {
    this.$root.$on("IndChatSettings", (id, head) => {
      this.showMainComponents = id;
      this.showHead = head;
    });
  },
};
</script>