<template>
  <div class="all-event-wrapper">
    <MembersInfos
      :selectedRoomTeam="selectedRoomTeam"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
      :currentIsFan="currentIsFan"
      :currentTeamIsActive="currentTeamIsActive"
    />

    <TeamInviteList
      :selectedRoomTeam="selectedRoomTeam"
      :currentIsAdmin="currentIsAdmin"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TeamInviteList from "../ChatSettingComponents/TeamInviteList.vue";
import MembersInfos from "./MembersInfos.vue";

export default {
  name: "Members",
  props: {
    currentUserId: {
      type: String,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  components: {
    TeamInviteList,
    MembersInfos,
  },
  data() {
    return {
      imgLoader: 1,
      searchMember: false,
      showLoaderMember: 0,
      showLoaderSend: 0,
      showLinkLoader: 0,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      isDisabled: true,
      isDisabledMmeber: true,
      errorMessage: null,
      urlLinkCopy: "",
      successMessage: null,
      isLoadingArray: [],
      isLoadingArrayMember: [],
      addMultipleUsers: [],
      roomId: this.selectedRoomTeam.roomId,
      selectedUsers: [],
      isLoading: false,

      teamSelectedUser: [],
      teamUserUncount: [],
      teamSelectedUserRole: [],
      allUsersRef: [],
      printTeamInfoList: [],
      linkTobe: false,
      mobilePreview: true,
      roleType: "0",
      selectedCode: "+1",
      showConfirmButton: false,
      newArray: [],
      details: {
        code: "+1",
        mob: "",
        email: "",
        role: "0",
        grpID: this.selectedRoomTeam.roomId,
        grpImage: this.selectedRoomTeam.teamAvatar,
        grpName: this.selectedRoomTeam.teamName,
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
      roleList: [
        { value: "0", text: "Teammate" },
        { value: "2", text: "Fan" },
      ],
    };
  },

  computed: {
    htmlToPdfOptions() {
      return {
        filename: this.selectedRoomTeam.teamName,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          // useCORS: true,
          proxy: this.$groupAvatarStorageURL + this.selectedRoomTeam.teamAvatar,
        },
        jsPDF: { orientation: "landscape" },
      };
    },
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    this.MobileHidePdfContent();
    // this.isDisabledMmeber = true;
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    MobileHidePdfContent: function () {
      let w = innerWidth;
      if (w < 768) {
        this.mobilePreview = false;
      }
    },
    addNewMemberModal() {
      this.selectedUsers = [];
      this.showLoaderMember = 0;
      this.isDisabledMmeber = true;
      this.asyncFind();
    },
    copyUrl() {
      let testingCodeToCopy = document.querySelector("#link");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        console.log(successful);
        this.$refs.btnToggle.innerText = this.show ? "Copy" : "Copied";
      } catch (err) {
        alert("Oops, unable to copy");
      }
    },
    ...mapActions("auth", ["sendRegisterRequest"]),

    showMemberSearchBar: function () {
      this.searchMember = !this.searchMember;
    },

    closeDiv: function () {
      // $(".chat-right-sidebar")
      //   .slideUp(200)
      //   .delay(200)
      //   .slideDown(200);
      this.$root.$emit("fetchEvents");
      this.$root.$emit("ChatSettings", 0);
    },

    // printTeamInfo() {
    //   this.isLoadingPrint = true;
    //   axios
    //     .post(
    //       `${process.env.VUE_APP_API_URL}user/usersListData`,
    //       this.selectedRoomTeam.users
    //     )
    //     .then((response) => {
    //       this.printTeamInfoList = response.data.data;
    //       this.$refs.teamPdf.generatePdf();
    //       this.isLoadingPrint = false;
    //     });
    // },

    // ---- Add Members ----------------------------------------------------------------------

    errorNull: function () {
      this.errorMessage = null;
      this.successMessage = null;
      if (
        (this.details.mob == "" && this.details.email == "") ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
};
</script>
<style scoped>
.member-wrapper {
  padding-top: 10px;
  /* animation: mymove 0.5s ease-in-out 0s 1; */
  position: relative;
}
.animate {
  animation: moveback 1s linear 0s 1;
}
.mr-3 {
  margin-right: 30px;
}
.team-top {
  display: flex;
  align-items: center;
}
.team-top .back-arrow {
  margin-right: 15px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-top .back-arrow:hover {
  background-color: #e2f5fe;
  border-radius: 5px;
}

.team-top .back-arrow img {
  width: 20px;
}
.team-search {
  margin-left: auto;
  background-color: #e2f5fe;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.team-member-actions .team-add-new:hover,
.team-member-actions .team-invite-link:hover {
  background-color: #e5f2fe;
  border-radius: 5px;
}

.team-member-actions .team-add-new h6,
.team-member-actions .team-invite-link h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #254c72;
}
.copy-link-btn {
  background-color: #188ef5;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  min-height: 50px;
  margin-left: 10px;
}
.copy-link-btn:hover {
  color: #ffffff;
}
@media screen and (max-width: 420px) {
  .copy-link-btn {
    margin-top: 8px !important;
    width: 100%;
    margin-left: 0;
  }
}
.add-new img,
.invite-link img {
  width: 20px;
}

.team-info .image-wrapper {
  min-width: 100px;
  max-width: 100px;
  overflow: hidden;
  border: 2px solid #e5f2fe;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  margin-right: 10px;
}
.team-info .image-wrapper img {
  width: 100%;
  border-radius: 100px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.team-info .user-details .team-name {
  font-size: 16px;
  font-weight: 500;
  color: #333333 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.team-info .user-details p {
  font-size: 14px;
  font-weight: 400;
}

.team-info .user-details .type {
  color: #188ef5;
}
.team-info .user-details .created-by,
.team-info .user-details .date {
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

.modal__btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.loader_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
