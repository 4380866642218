<template>
  <div class="menuMain">
    <servicesModal :singleEvent="singleEvent" />
    <div v-if="showMain == 0" class="menu-items">
      <div v-if="showEventShimmerCounter == 1" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/Svg/team/team-event.svg" alt="" />
          </div>
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="1" class="content-margin-reaction" />
          </content-placeholders>
        </div>
      </div>

      <div v-else @click="showEventMainDiv()" class="member-list" :class="{ 'member-list-no-border': eventsDetails.length > 0 }">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/Svg/team/team-event.svg" alt="" />
          </div>
          <span class="counter-wrapper">Events
            <span class="nav-counter-team">{{ selectedRoomTeam.eventCount }}</span></span>
        </div>
        <img src="../../../../assets/Svg/arrow-right.svg" alt="" />
      </div>

      <div v-if="eventsDetails.length > 0">
        <div class="main-page-event" v-for="(eventWeather,
          index) in eventsDetails.slice(0, 1)" :key="index">
          <div @click="showDetails(eventWeather)">
            <div class="">
              <div class="d-flex align-items-center justify-content-between" style="gap: 5px;">
                <div>
                  <div class="d-flex align-items-center">
                    <p class="event-tag mr-2">Upnext</p>
                    <div class="content">
                      <span v-if="
                        eventWeather.exactStartDate == eventWeather.exactEndDate
                      ">
                        {{ eventWeather.exactStartDate }}</span>

                      <span v-else>
                        {{ eventWeather.exactStartDate.split(",")[0] }} to
                        {{ eventWeather.exactEndDate }}
                      </span>

                      <!-- <span v-if="eventWeather.allDay == 'true'"> All Day.</span>
                    <span v-else>
                      From {{ eventWeather.exactStartTime }} to
                      {{ eventWeather.exactEndTime }}</span
                    > -->
                      <span v-if="eventWeather.time_zone !== null">
                        ({{ eventWeather.time_zone.split(")").pop() }})
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <content-placeholders :rounded="true" v-if="goingLoader[eventWeather.id]">
                    <content-placeholders-text :lines="1" class="content-margin-reaction" />
                  </content-placeholders>

                  <div class="attendee-sec" v-else>
                    <!-- Dropdown Button -->
                    <button type="button" @click.stop="toggleDropdown(eventWeather.id)" v-click-outside="closeDropdown"
                      class="dropdown-btn px-2 py-1" :class="{ 'selected': selectedOptions[eventWeather.id] !== 'Are you going?' }">
                      <span>{{ selectedOptions[eventWeather.id] }}</span>
                      <!-- <span>Are you Going?</span> -->
                    </button>

                    <!-- Dropdown Menu -->
                    <div v-if="showDropdowns[eventWeather.id]" class="attendee-dropdown">
                      <button v-for="option in options" :key="option.value"
                        @click.stop="selectOption(eventWeather.id, option)" class="dropdown-option w-100"
                        :class="{ 'selected': selectedOptions[eventWeather.id] === option.label }">
                        <span>{{ option.label }}</span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="event-list-details">
              <p class="gateway text-capitalize">
                {{
                  eventWeather.event_tournaments &&
                    eventWeather.event_tournaments.length > 0 &&
                    eventWeather.event_tournaments[0].tournamentName
                    ? eventWeather.eventName +
                    "/" +
                    eventWeather.event_tournaments[0].tournamentName
                    : eventWeather.eventName
                }}
              </p>
              <p class="event-asso-type">
                {{ eventWeather?.association }}
                <span v-if="eventWeather.association">-</span>
                {{ eventWeather?.eventType }}
              </p>
              <div class="location-content" v-if="
                (eventWeather.status == 1 &&
                  eventWeather.address != null &&
                  eventWeather.address != '' &&
                  eventWeather.address != 'null') ||
                (eventWeather.city != null &&
                  eventWeather.city != '' &&
                  eventWeather.city != 'null') ||
                (eventWeather.state != null &&
                  eventWeather.state != '' &&
                  eventWeather.state != 'null')
              ">
                <img @click="openGoogleMap(eventWeather)" src="../../../../assets/Svg/location.svg" />
                <!-- <span @click="openGoogleMap(eventWeather)" class="content">{{
              eventWeather.address != "" &&
              eventWeather.address != null &&
              eventWeather.address != "null"
                ? eventWeather.address
                : eventWeather.city
            }}</span> -->
                <span @click="openGoogleMap(eventWeather)" class="content">{{ eventWeather.city }}, {{
                  eventWeather.state }}</span>
              </div>
              <div class="location-content" v-if="eventWeather.status == 2">
                <img src="../../../../assets/images/link.png" />
                <a class="content" :href="eventWeather.Url" target="_blank">
                  {{ eventWeather.Url }}
                </a>
              </div>
            </div>

            <WeatherData v-if="isEventWithinFiveDays(eventWeather)" :eventId="eventWeather.id" />
            <MatesEventData :members="eventWeather.event_attendee_all" />

            <!-- <div class="images-overlap">
              <div class="team-members d-flex">
                <div class="team-details mb-0" v-for="user in membersAttendee.slice(0, 7)" :key="user.id">
                  <div class="team-image">
                    <abbr :title="user.username">
                      <img v-if="user.avatar != ''" :src="user.avatar"
                        onerror="this.src='https://api.whoifollow.tech/user-default2.png'" alt=""
                        class="upload-image" />
                      <img v-else src="../../../../assets/Svg/member.svg" alt="" />
                    </abbr>
                  </div>
                </div>
              </div>
              <MatesEventData :members="eventWeather.event_attendee_all" />

              <UserGoingList :selectedRoomTeam="selectedRoomTeam" :attendeeListing="eventWeather.event_attendee_all" />
            </div> -->
          </div>
        </div>
      </div>

      <div v-if="showEventShimmerCounter == 1" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/Svg/team/team-teammates.svg" alt="" />
          </div>
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="1" class="content-margin-reaction" />
          </content-placeholders>
        </div>
      </div>
      <div v-else @click="showMembersDiv" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/Svg/team/team-teammates.svg" alt="" />
          </div>
          <span class="counter-wrapper">Teammates
            <span class="nav-counter-team">{{
              teamMatesCount
              }}</span></span>
        </div>
        <img src="../../../../assets/Svg/arrow-right.svg" alt="" />
      </div>
      <div @click="showSharedFilesDiv" v-if="currentIsFan != '1'" class="member-list">
        <div class="member-detail">
          <div class="member-img">
            <img src="../../../../assets/Svg/team/team-sharedfile.svg" alt="" />
          </div>
          <span>Shared Files</span>
        </div>
        <img src="../../../../assets/Svg/arrow-right.svg" alt="" />
      </div>
    </div>

    <div v-else-if="showMain == 1">
      <transition name="slide" enter-active-class="slideInLeft">
        <Members v-if="showMembers == 0" :selectedRoomTeam="selectedRoomTeam" :currentUserId="currentUserId"
          :currentIsAdmin="currentIsAdmin" :currentIsFan="currentIsFan" :currentTeamIsActive="currentTeamIsActive" />

        <SharedFiles v-if="showSharedFiles == 0" :selectedRoomTeam="selectedRoomTeam" :currentUserId="currentUserId" />

        <TeamOptions v-if="showTeamOptions == 0" :selectedRoomTeam="selectedRoomTeam" :currentUserId="currentUserId"
          :currentIsAdmin="currentIsAdmin" :currentIsFan="currentIsFan" :currentTeamIsActive="currentTeamIsActive" />
      </transition>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SharedFiles from "../ChatSettingComponents/SharedFiles.vue";
import TeamOptions from "../ChatSettingComponents/TeamOptions.vue";
import Members from "../ChatSettingComponents/Members.vue";
// import Weather from "../ChatEvents/Weather.vue";
// import UserGoingList from "../ChatEvents/UserGoingList.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";
import servicesModal from "@/components/Teams/ChatRight/ChatEvents/servicesModal.vue";
import MatesEventData from "@/components/NewGameTime/Events/MatesEventData.vue";


export default {
  components: {
    TeamOptions,
    SharedFiles,
    Members,
    servicesModal,
    // Weather,
    // UserGoingList,
    WeatherData,
    MatesEventData
  },
  name: "SettingMenu",
  data: function() {
    return {
      goingLoader: {},
      showEventShimmerCounter: 1,
      showMembers: 1,
      showSharedFiles: 1,
      showTeamOptions: 1,
      showMain: 0,
      selectAttendeeTab: 0,
      membersAttendeeComplete: [],
      eventsDetails: [],
      membersAttendee: [],
      goingMemberCount: 0,
      options: [
        { label: "Going", value: "going", status: "1" },
        { label: "Not Going", value: "not-going", status: "2" },
        { label: "Maybe", value: "maybe", status: "3" }
      ],
      selectedOptions: {}, // Stores selectedOption for each event by event.id
      showDropdowns: {},
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      teamMatesCount: "",
      exactRoomStartDate: "",
      singleEvent: {},
      userId: localStorage.getItem("userId"),

    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  watch: {
    selectedRoomTeam: {
      handler(newVal) {
       
        this.eventsDetails = newVal?.mostUpcomingEvent;
        

        if (this.eventsDetails.length > 0) {
          this.eventsDetails.forEach(event => {
            this.initializeSelectedOptions(event.id, event.loggedInUserAttendee);
            
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    // filteredEvents() {
    //   // Replace the condition in the find() method with your specific condition
    //   return this.selectedRoomTeam.events.filter(event => Object.hasOwn(event, 'weatherData') && event.weatherData !== null);
    //   }
  },
  methods: {
    initializeSelectedOptions(eventId, loggedInUserAttendee) {
      if (!loggedInUserAttendee) {
        this.$set(this.selectedOptions, eventId, "Are you going?");
      } else {
        switch (loggedInUserAttendee.member_status) {
          case "1":
            this.$set(this.selectedOptions, eventId, "Going");
            break;
          case "2":
            this.$set(this.selectedOptions, eventId, "Not Going");
            break;
          case "3":
            this.$set(this.selectedOptions, eventId, "Maybe");
            break;
          default:
            this.$set(this.selectedOptions, eventId, "Are you going?");
        }
      }
    },
    toggleDropdown(eventId) {
      // Toggle dropdown visibility for the specific event
      this.$set(this.showDropdowns, eventId, !this.showDropdowns[eventId]);
    },
    closeDropdown(eventId) {
      if (eventId && this.showDropdowns[eventId]) {
        this.$set(this.showDropdowns, eventId, false);
      } else {
        // Close all dropdowns as a fallback
        Object.keys(this.showDropdowns).forEach(id => {
          this.$set(this.showDropdowns, id, false);
        });
      }
    },
    selectOption(eventId, option) {
    this.singleEvent = {};
    if (option.status !== "1") {
        this.$set(this.selectedOptions, eventId, option.label);
    }
    this.closeDropdown(eventId);

    const userId = localStorage.getItem("userId");
    const statusValue = this.getStatusValue(option.value);

    // Remap eventsDetails to ensure reactivity
    this.eventsDetails = this.eventsDetails.map(event => {
        if (event.id === eventId) {
            // Update loggedInUserAttendee status
            event.loggedInUserAttendee = event.loggedInUserAttendee || {};
            this.previousGoing = event.loggedInUserAttendee?.member_status === "1" || event.loggedInUserAttendee?.member_status === 1;
            if (statusValue == '2' || statusValue == '3') {
                event.event_attendee_all = event.event_attendee_all.filter(attendee => attendee.member_id !== userId);
                event.loggedInUserAttendee.member_status = statusValue;
            } else {
                event.event_attendee_all = event.event_attendee_all.map(attendee => {
                    if (attendee.member_id === userId) {
                        return { ...attendee, member_status: statusValue };
                    }
                    return attendee;
                });
            }

            this.singleEvent = event;
        }

        return event;
    });
    const updatedEvent = this.eventsDetails.find(e => e.id === eventId);
    if (updatedEvent) {
        this.selectStatus(updatedEvent.team_id, updatedEvent.id, option.status, this.singleEvent);
    }
},
    getStatusValue(value) {
      // Convert option value to memberStatus value
      switch (value) {
        case "going":
          return "1";
        case "not-going":
          return "2";
        case "maybe":
          return "3";
        default:
          return null;
      }
    },
    async selectStatus(team_id, event_id, member_status, singleEvent) {

    if (this.previousGoing && member_status == 1) {
      return;
    }
    if (member_status == 1) {
      this.$root.$emit("openServicesModal", singleEvent);
    } else {
      this.editTravel = false;
      
      // Create form data
      var formData = new FormData();
      formData.append("event_id", event_id);
      formData.append("member_status", member_status);
      formData.append("team_id", team_id);

      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // this.getAttendee(); // Refresh attendee list after success
      } catch (error) {
        console.error("Error updating status:", error);
      }
    }
    },
    async selectStatusWithServices(status) {
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_status", status);
      formData.append("services", this.selectedArray);
      formData.append("team_id", this.singleEvent.team_id);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);

      if (this.userMember_id) {
        formData.append("member_id", this.userMember_id);
      }

      // Start Loader for this event
      this.$set(this.goingLoader, this.singleEvent.id, true);

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (response.data.statusCode === 200) {
          const userId = this.userMember_id || localStorage.getItem("userId");

          // Ensure selectedArray is an array
          const selectedServices = Array.isArray(this.selectedArray) ? this.selectedArray.join(",") : null;

          // Create the new attendee object
          const newAttendee = {
            id: localStorage.getItem("userId"),
            event_id: this.singleEvent.id,
            team_id: this.singleEvent.team_id,
            member_id: userId,
            member_status: status,
            services: selectedServices, // Use the validated selectedServices
            room_count: this.room_count,
            exactStartDate: this.exactRoomStartDate,
            exactEndDate: this.exactRoomEndDate,
            note: this.note,
            adult_count: this.adult_count,
          };

          // Update the event details
          this.eventsDetails = this.eventsDetails.map((event) => {
            if (event.id === this.singleEvent.id) {
                // Add new attendee if not already present
                if (!event.event_attendee_all.some(attendee => attendee.member_id === userId)) {
                    event.event_attendee_all = [...event.event_attendee_all, newAttendee];
                }

                // Update loggedInUserAttendee member_status
                if (event.loggedInUserAttendee) {
                    event.loggedInUserAttendee.member_status = "1";
                } else {
                    event.loggedInUserAttendee = { member_status: "1" };
                }
            }
            return event;
        });

          // Update the dropdown text
          this.$set(this.selectedOptions, this.singleEvent.id, "Going");
        }
      } catch (error) {
        console.error("Error updating travel details:", error);
      } finally {
        // Stop Loader after API response
        this.$set(this.goingLoader, this.singleEvent.id, false);
      }
},
    filterGoing: function(memberStatus) {
      this.selectAttendeeTab = 0;
      const filterGoing = memberStatus;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (attendee.member_status == 1 || attendee.member_status == "1") {
          this.membersAttendee.push(attendee);
        }
      }
      var count = this.membersAttendee.length;
      if (count > 7) {
        this.goingMemberCount = count - 7;
      }
    },

    showEventMainDiv: function() {
      const teamId = this.selectedRoomTeam.id
      this.$router.push(`/my-team/events/${teamId}`);
      // if (this.user.pro_status == null) {
      //   this.$router.push({ name: "GoPro" });
      // } else {
      // this.$root.$emit("ChatSettingEvent", 1, 0);
      // }
    },
    isEventWithinFiveDays(event) {
      if (!event?.startDate) return false;
      const eventDate = new Date(event.startDate);

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      eventDate.setHours(0, 0, 0, 0);

      const diffDays = (eventDate - today) / (1000 * 60 * 60 * 24);

      if (diffDays <= 5 && diffDays >= 0) {
        return true;
      }

      return false;
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    showMembersDiv: function() {
      const teamId = this.selectedRoomTeam.id
      this.$router.push(`/my-team/teammates/${teamId}`);
      // this.showMembers = 0;
      // this.showSharedFiles = 1;
      // this.showTeamOptions = 1;
      // this.showMain = 1;
      // this.$root.$emit("ChatSettings", 1);
    },
    showSharedFilesDiv: function() {
      this.showMembers = 1;
      this.showSharedFiles = 0;
      this.showTeamOptions = 1;
      this.showMain = 1;
      this.$root.$emit("ChatSettings", 1);
    },
    showTeamOptionsDiv: function() {
      this.showMembers = 1;
      this.showSharedFiles = 1;
      this.showTeamOptions = 0;
      this.showMain = 1;
      this.$root.$emit("ChatSettings", 1);
    },
    showDetails: function(event) {
      const eventId = event.id;
      this.$router.push({ name: 'SingleEventDetail', params: { id: eventId } });
      this.$root.$emit("showEventDetailPage", event);
    },
  },

  mounted() {
    this.$root.$on("MenuSettingsDiv", (id) => {
      this.showMain = id;
    });
    this.$root.$on("updateTeammatesCounter", (data) => {
      this.teamMatesCount = data;
    });
    this.$root.$on("showEventShimmerCounter", (id) => {
      this.showEventShimmerCounter = id;
    });
    this.$root.$on(
    "attendeesServiceForMyEvent",
    (
      data,
      room_count,
      adultQuantity,
      note,
      exactRoomStartDate,
      exactRoomEndDate
    ) => {
      this.selectedArray = data;
      this.room_count = room_count;
      this.note = note;
      this.adult_count = adultQuantity;
      this.exactRoomEndDate = exactRoomEndDate;
      this.exactRoomStartDate = exactRoomStartDate;
      this.selectStatusWithServices();
    }
  );
  },
};
</script>
<style scoped>
.attendee-sec .attendee-btns button {
  background-color: #E5F2FE;
  border: 0.5px solid #CCE6FD;
  padding: 4px 10px;
  border-radius: 5px;
}

.attendee-sec button span,
.attendee-sec .attendee-btns button span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: #254C72;
  white-space: nowrap;
}

.attendee-sec > button {
  background-color: #F0F2F5;
  border: none;
  outline: none;
  padding: 4px 10px;
  border-radius: 5px;
  height: 35px;
  border: 0.5px solid #CFD7E0;
}
.attendee-dropdown .dropdown-option:nth-child(1) {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.attendee-dropdown .dropdown-option:nth-child(2) {
    padding-top: 16px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0px;

}

.attendee-dropdown .dropdown-option:nth-child(3) {
    padding-top: 16px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0px;

}
</style>
