<template>
  <div class="game-actions-wrapper">
    <div class="action-ellipsis">
      <h6
        class="d-none"
        ref="getUpdate"
        data-toggle="modal"
        data-target="#exampleModalEdit"
      ></h6>
      <div class="actions-list">
        <ul class="mb-0 border-bottom-0">
          <li class="del-game" >
            <div class="played-data">
              <span class="tie" v-if="singleGame.game_live == 1"
                >{{ singleGame.team_score }} - {{ singleGame.opponent_score }}</span
              >

              <span class="tie" v-else-if="singleGame.win_status == 0"
                >{{ singleGame.team_score }} - {{ singleGame.opponent_score }}</span
              >
              <span class="win-data" v-else-if="singleGame.win_status == 1"
                >W{{ singleGame.team_score }} -
                {{ singleGame.opponent_score }}</span
              >
              <span class="loss-data" v-else-if="singleGame.win_status == 2"
                >L{{ singleGame.team_score }} -
                {{ singleGame.opponent_score }}</span
              >
              <span class="tie" v-else>
                {{ singleGame.team_score }} - {{ singleGame.opponent_score }}</span
              >
            </div>
          </li>

          <li
            class="del-game"
            data-toggle="modal"
            data-target="#exampleModalEdit"
            @click="checkUpdate"
          >
            Update
          </li>
          <li class="del-game"  @click="deleteGame()">Delete</li>
          <li class="del-game"  @click="callModal()">Scoring</li>
          <li class="del-game" @click="callModalLineup()">Lineup</li>
          <!-- <li class="del-game"  @click="callModal()">Scoring</li> -->
         
          <!-- <li class="del-game">
            <a
              v-if="
                selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
                selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
                selectedRoomTeam.sportType == 'Softball' ||
                selectedRoomTeam.sportType == 'Baseball'
              "
              class="m-0 font-weight-normal"
              href="https://playersurveyor.azurewebsites.net/Identity/Account/Login"
              target="_blank"
              >Keep Score</a
            >
          </li> -->
        </ul>
      </div>
    </div>
    <EditGame :singleGame="singleGame" :eventAlertOptions="eventAlertOptions" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import EditGame from "../Games/EditGame.vue";
export default {
  name: "GameActions",
  data() {
    return {
      gameOptions: false,
    };
  },

  props: {
    singleGame: {
      type: Object,
    },
    selectedRoomTeam: {
      type: Object,
    },
    eventAlertOptions: {
      type: Array,
    },
  },

  components: {
    EditGame,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isTeamsPage() {
      return window.location.pathname === '/teams';
    }
  },

  methods: {
    callModalLineup: function() {
      this.$root.$emit("openTeamLineUpModal", this.singleGame);
    },
    openGameOptions() {
      this.gameOptions = !this.gameOptions;
    },
    onClickOutside() {
      this.gameOptions = false;
    },

    checkUpdate() {
      this.$root.$emit("checkUpdateGame", this.singleGame);
    },

    async deleteGame() {
      this.$confirm(
        "About to remove this game. Continue ?",
        "Remove Game."
      ).then(() => {
        var formData = new FormData();
        formData.append("id", this.singleGame.id);

        axios
          .post(process.env.VUE_APP_API_URL + "game/delete", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            var linkUrl = window.location.pathname;
            var segment_array = linkUrl.split("/");
            var lastSegment = segment_array.pop();
            if (lastSegment == "teams") {
              this.$root.$emit("showGameList");
            }
          });
      });
    },
    callModal() {
      // if (this.singleGame.opponent_flag == null) {
      //   this.$root.$emit("openSelectHomeTeamModal", this.singleGame);
      // } else {
      var combineUrl = this.selectedRoomTeam.id + "-" + this.singleGame.guid;
      const urlToOpen = `/score-game/${combineUrl}`;
      window.location.href = urlToOpen;
      // }
    },
  },
  mounted() {
    this.$root.$on("checkUpdateEdit", () => {
      var elem = this.$refs.getUpdate;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
<style>
.cursor-not-allowed {
  cursor: not-allowed !important;
  position: relative;
}
.del-game,
.win-data,
.loss-data {
  color: #188ef5; /* Apply the specified color */
}
.cursor-not-allowed .tooltiptext {
  visibility: hidden;
  width: 90px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.cursor-not-allowed:hover .tooltiptext {
  visibility: visible;
}
</style>
