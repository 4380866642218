<template>
    <div>
      <button
        class="d-none"
        data-toggle="modal"
        data-target="#EditEventAttendessModal"
        ref="openEditAttendessModal"
      ></button>
      <div
        class="modal fade"
        id="EditEventAttendessModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Event Attendees
              </h5>
              <button
                type="button"
                class="close text-primary"
                data-dismiss="modal"
                aria-label="Close"
                ref="openEditAttendessModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row" v-if="showEventMemeberCountShimmer == 1">
                <div class="col-12">
                  <div class="friends-requests">
                    <content-placeholders :rounded="true">
                      <content-placeholders-heading :img="false" />
                    </content-placeholders>
                  </div>
                </div>
              </div>

              <div v-if="showEventMemeberCountShimmer == 0 && currentUserEvent != null ">
                <div class="event-label" >
                    <ul  class="mb-0 border-bottom-0">
                        <!-- Upcoming -->
                        <li
                        v-on:click="selectStatus(1)"
                        v-bind:class="[
                            currentUserEvent.member_status == 1 ? 'active disabled' : '',
                        ]"
                        class="cursor-p"
                        >
                        Going
                        </li>

                        <li
                        v-on:click="selectStatus(2)"
                        v-bind:class="[
                            currentUserEvent.member_status == 2 ? 'active disabled' : '',
                        ]"
                        class="cursor-p"
                        >
                        Not Going
                        </li>

                        <li
                        v-on:click="selectStatus(3)"
                        v-bind:class="[
                            currentUserEvent.member_status == 3 ? 'active disabled' : '',
                        ]"
                        class="cursor-p"
                        >
                        Maybe
                        </li>
                    </ul>
                </div>
                    <div class="edit-event-div pb-3" v-if="currentUserEvent.member_status == 1" @click="openEditTravel(currentUserEvent)">
                        <a class="create-event">
                            <img src="../../../../assets/images/edit.png" alt="" />
                            <span v-if="currentUserEvent.fname && currentUserEvent.lname">Edit travel details for {{currentUserEvent.fname}} {{currentUserEvent.lname}} </span>
                            <span v-else>Edit teammates travel details</span>
                        </a>
                    </div>
              </div>
              <div v-else-if="showEventMemeberCountShimmer == 0 && currentUserEvent == null ">
                <div class="event-label" >
                    <ul  class="mb-0 border-0">
                        <!-- Upcoming -->
                        <li
                        v-on:click="selectStatus(1)"
                        
                        class="cursor-p"
                        >
                        Going
                        </li>

                        <li
                        v-on:click="selectStatus(2)"
                        
                        class="cursor-p"
                        >
                        Not Going
                        </li>

                        <li
                        v-on:click="selectStatus(3)"
                        
                        class="cursor-p "
                        >
                        Maybe
                        </li>
                    </ul>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <servicesModal :singleEvent="singleEvent" />
      <EditServicesModal/>

    </div>
  </template>
  
  <script>
  import axios from "axios";
  import servicesModal from "./servicesModal.vue";
  import EditServicesModal from './EditServicesModal.vue';



  export default {
    name: "EditEventAttendessModal",
    props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
  },
    data() {
      return {
        showLoader: 0,
        imgLoader: 1,
        servicesModal,
        showDefualt: 1,
        totalGoing: 0,
        totalNotGoing: 0,
        totalMayBe: 0,
        totalNotRespond: 0,
        editTravel: true,
        userData: "",
        showEventMemeberCountShimmer: 0,
        currentUserEvent: {},
        Services: {},
        singleServices: {},

      };
    },
    components: {
        servicesModal,
        EditServicesModal,
    },
    methods: {
      onImageLoad() {
        this.imgLoader = 0;
      },
      async selectStatus(status) {
        var elem = this.$refs.openEditAttendessModalClose;
        if (elem) {
          elem.click();
        }
      if (status == 1) {

        this.$root.$emit("openServicesModal", { singleEvent: this.singleEvent, userData: this.userData });
        
      } else {
        this.editTravel = false;
        var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
        formData.append("member_status", status);
        formData.append("team_id", this.selectedRoomTeam.roomId);
        formData.append("member_id", this.userData.c_id);

        await axios
          .post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.getAttendee();
            this.$root.$emit("showAttendeesList");
          });
          
      }
    },
    openEditTravel(currentUserEvent) {
      this.$root.$emit("openEditServicesModal", {event: this.singleEvent, services: this.singleServices, member_id: currentUserEvent.member_id});
      var elem = this.$refs.openEditAttendessModalClose;
        if (elem) {
          elem.click();
        }
    },
    async getAttendee() {
    this.showEventMemeberCountShimmer = 1;
    var formData = new FormData();
    formData.append("event_id", this.singleEvent.id);
    formData.append("team_id", this.selectedRoomTeam.roomId);
    formData.append("member_id", this.userData.c_id);

    await axios
        .post(
        process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
        formData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        }
        )
        .then((response) => {
        this.currentUserEvent = response.data.data.attendeeListing[0];
        this.Services = response.data.data.attendeeListing[0];

        this.showEventMemeberCountShimmer = 0;
        })
        .catch((error) => {
        console.error("Error fetching event attendees:", error);
        this.showEventMemeberCountShimmer = 0;
        });
    },
    async getMarkAttendee() {
      this.showEventMemeberCountShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_id", this.userData.c_id);

      try {
          const response = await axios.post(
              process.env.VUE_APP_API_URL + "event/getEventMemberDetails",
              formData,
              {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              }
          );

          this.currentUserEvent = response.data.data.memberDetails;
          this.singleServices = response.data.data.memberDetails;

          this.showEventMemeberCountShimmer = 0;
        } catch (error) {
          console.error("Error fetching mark event attendees:", error);

          if (error.response && error.response.status === 404) {
            this.showEventMemeberCountShimmer = 0;
            console.log("Error fetching mark event attendees:", error);

          } else {
              console.log("Error fetching mark event attendees:", error);
          }

          this.showEventMemeberCountShimmer = 0;
      }
    },
     
    },
    mounted() {
      this.$root.$on("openEditAttendeesModal", (user) => {
        this.userData = user;
        var elem = this.$refs.openEditAttendessModal;
        if (elem) {
          elem.click();
          this.getMarkAttendee();
        }
      });
      this.$root.$on("openEditMarkAttendeesModal", (user) => {
        this.userData = user;
        var elem = this.$refs.openEditAttendessModal;
        if (elem) {
          elem.click();
          this.getMarkAttendee();
        }
      });
    },
  };
  </script>
  <style>
  .event-details-wrapper {
    overflow-x: hidden;
  }
  .event-details-top {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
  }
  .event-details-top .back-arrow {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .event-details-top .back-arrow:hover {
    background-color: #e2f5fe;
  }
  
  .event-details-top .back-arrow img {
    width: 20px;
  }
  .event-details-top h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #254c72;
  }
  
  .event-details-top .edit-event-div a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #188ef5;
    margin: 0 !important;
  }
  .event-image-name .image {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
  }
  .event-image-name .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }
  .event-image-name .name {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-transform: capitalize;
  }
  
  #PrintRoasterModal .user-info,
  .images-overlap .team-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: -10px;
  }
  
  #PrintRoasterModal .user-info {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 8px;
    align-items: flex-start !important;
  }
  
  #PrintRoasterModal .user-info .data-wrapper {
    width: calc(100% - 70px);
  }
  
  #PrintRoasterModal .image-wrapper {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 10px;
    background-color: #f1f3f6;
    text-align: center;
  }
  #PrintRoasterModal .image-wrapper img.user-default,
  .images-overlap .image-wrapper img.user-default {
    width: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: 100px;
  }
  #PrintRoasterModal .image-wrapper img,
  .images-overlap .image-wrapper img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    border-radius: 100px;
  }
  
  .images-overlap span {
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  
  .event-label h6 {
    color: #333333;
    font-weight: 400;
    margin: 10px 5px;
    font-size: 16px;
  }
  
  .cursor-p {
    cursor: pointer;
  }
  .event-label ul li.active {
    background-color: #188ef5;
    color: #ffffff;
  }
  .event-label ul li.active:before {
    content: "✓";
    position: absolute;
    top: -10px;
    background-color: #ff5b66;
    width: 15px;
    height: 15px;
    font-size: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .view-details {
    border-bottom: none;
    padding-bottom: 0;
  }
  .view-details h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  
  .view-details .print-roaster-btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .view-details .view-details-link span,
  .view-details .print-roaster-btn span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #254c72;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .game-content-section {
    max-width: 350px;
    overflow-x: auto;
  }
  .game-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #cfd7e0;
  }
  .game-top .game-heading h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
  }
  .game-top .game-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .game-top .game-details a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #188ef5;
    margin: 0;
  }
  .game-wrap {
    margin-bottom: 10px;
  }
  
  .game-wrap:last-child {
    margin-bottom: 0;
  }
  
  .date-content p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ff5b66;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .games-actions {
    position: relative;
  }
  
  .content-margin-reaction {
    /* margin-top: -6px; */
    margin-bottom: -10px;
    margin-left: -5px;
    width: 40px;
    margin-left: 10px;
  }
  
  #PrintRoasterModal h6 {
    color: #333333;
    font-weight: 400;
    margin: 10px 5px;
    font-size: 16px;
  }
  
  .user-data button {
    border: none;
    background-color: #188ef5;
    color: #ffffff;
    border-radius: 5px;
    padding: 6px 8px;
    margin-top: 10px;
  }
  div.form-group textarea {
    border: 1px solid #ced4da;
    font-size: 14px;
    color: #495055;
    width: 100%;
    padding: 3px 15px;
    border-radius: 0.7rem;
    outline: none;
    height: 150px;
    margin-bottom: 15px;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  div.form-group textarea:valid + span.placeholder,
  div.form-group textarea:focus + span.placeholder {
    -webkit-transform: scale(0.8) translateY(-25px);
    -moz-transform: scale(0.8) translateY(-25px);
    -ms-transform: scale(0.8) translateY(-25px);
    -o-transform: scale(0.8) translateY(-25px);
    transform: scale(0.8) translateY(-25px);
    background: #fff;
  }
  
  div.form-group textarea:focus {
    color: #333333;
    font-weight: 500;
    border-color: #188ef5 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  
  div.form-group textarea:focus + span.placeholder {
    color: #188ef5;
  }
  
  #PrintRoasterModal .user-content {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #c4c4c4;
    padding: 6px 0;
    margin-bottom: 5px;
  }
  
  #PrintRoasterModal .user-content:nth-child(3) {
    border-bottom: none !important;
  }
  
  #PrintRoasterModal .user-content .title {
    color: #188ef5;
    font-size: 1rem;
    margin-right: 10px;
  }
  
  #PrintRoasterModal .user-content .data {
    color: #000000;
    font-size: 1rem;
    margin-left: 10px;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 768px) {
    .vue-html2pdf .pdf-preview {
      display: none !important;
    }
  }
  
  .roaster-top .association .content {
    font-size: 30px;
  }
  
  .view-details-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .view-details-link .print-roaster-btn img {
    width: 20px;
  }
  
  .images-overlap {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  </style>