<template>
  <div>
    <TeamLineUpModal :selectedRoomTeam="selectedRoomTeam" />
    <PrintRoasterModal
      :users="eventGoingForpdf"
      :InvitedList="eventInvitedList"
      :fileName="singleEvent.eventName"
    />
    <EventAttendeesModal
      :singleEvent="singleEvent"
      :selectedRoomTeam="selectedRoomTeam"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
    />
    <servicesModal :singleEvent="singleEvent" />
    <EditServicesModal />
    <EditEvent
      :singleEvent="singleEvent"
      :selectedRoomTeam="selectedRoomTeam"
      v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
      :currentTeamIsActive="currentTeamIsActive"
    />
    <div class="event-details-wrapper pt-0">
      <div class="event-top">
        <div class="event-top-content">
          <div class="back-arrow" @click="closeDiv">
            <img src="../../../../assets/images/arrow-left.png" />
          </div>
          <h6 class="team-name">Back to Events</h6>
        </div>
        <div class="edit-event-div">
          <a class="create-event" @click="callModalEditEvent()">
            <img src="../../../../assets/images/edit.png" alt="" />
            <span>Edit Event</span>
          </a>
        </div>
      </div>
      <div class="event-list main-details-teams-event pb-0">
        <EventWrapper
          :event="singleEvent"
          :selectedRoomTeam="selectedRoomTeam"
        />
        <Weather
          v-if="singleEvent.status == 1"
          :eventsForWeather="singleEvent"
        />
      </div>

      <div v-if="showEventMemeberCountShimmer == 1">
        <div class="products-list-shim px-0 pt-2 pb-1">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="false" />
          </content-placeholders>
        </div>
        <div class="products-list-shim px-0 py-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="false" />
          </content-placeholders>
        </div>
      </div>

      <div v-if="showEventMemeberCountShimmer == 0">
        <div class="view-details">
          <h6 v-if="currentIsFan != '1'">Are you coming ?</h6>
          <div class="view-details-link">
            <!-- <span @click="showEventsMemberDiv" v-if="showDefualt == 1"
              >View Details</span
            > -->
            <!-- <div
              v-if="
                showDefualt == 1 &&
                showEventMemeberCountShimmer == 0 &&
                currentIsAdmin == '1' &&
                currentTeamIsActive == 1
              "
              class="print-roaster-btn"
              @click="openPrintOptions"
              v-click-outside="onClickOutside"
            >
              <img src="../../../../assets/images/printer.png" alt="" />
              <span>Print</span>
            </div> -->
            <div class="print-list">
              <ul>
                <li v-if="selectedRoomTeam.teamType == 'sports'">
                  <a tabindex="0" class="p-0" @click="callModal()"
                    ><img
                      src="../../../../assets/images/printer.png"
                      alt=""
                    /><span>Roster</span></a
                  >
                  <a v-if="showEventMemeberCountShimmer == 1">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        class="print-roaster-btn content-margin-reaction width-40"
                      />
                    </content-placeholders>
                  </a>
                </li>
                <li>
                  <!-- <a @click="printTravelDetails" class="p-0" -->
                  <a @click="generateTravelExcelFile()" class="p-0"
                    ><img
                      src="../../../../assets/images/printer.png"
                      alt=""
                    /><span>Travel</span></a
                  >
                </li>
              </ul>
            </div>
            <span @click="showDefualtDiv" v-if="showEventsMember == 1"
              >Back</span
            >
          </div>
        </div>
        <div class="event-label">
          <ul v-if="currentIsFan != '1'" class="mb-0">
            <!-- Past -->
            <!-- <li
              v-if="singleEvent.eventTypeA == 'past'"
              v-bind:class="[currentUserEventStatus == 1 ? 'active' : '']"
            >
              Going
            </li>
            <li
              v-if="singleEvent.eventTypeA == 'past'"
              v-bind:class="[currentUserEventStatus == 2 ? 'active' : '']"
            >
              Not Going
            </li>
            <li
              v-if="singleEvent.eventTypeA == 'past'"
              v-bind:class="[currentUserEventStatus == 3 ? 'active' : '']"
            >
              Maybe
            </li> -->

            <!-- Upcoming -->
            <li
              v-on:click="selectStatus(1)"
              v-bind:class="[
                currentUserEventStatus == 1 ? 'active disabled' : '',
              ]"
              class="cursor-p"
            >
              Going
            </li>

            <li
              v-on:click="selectStatus(2)"
              v-bind:class="[
                currentUserEventStatus == 2 ? 'active disabled' : '',
              ]"
              class="cursor-p"
            >
              Not Going
            </li>

            <li
              v-on:click="selectStatus(3)"
              v-bind:class="[
                currentUserEventStatus == 3 ? 'active disabled' : '',
              ]"
              class="cursor-p"
            >
              Maybe
            </li>
          </ul>
          <div v-if="showLoader == 0">
            <div
              class="images-overlap"
              @click="showEventsMemberDiv"
              v-if="showDefualt == 1 && membersAttendee.length > 0"
            >
              <div class="team-members d-flex">
                <div
                  class="team-details mb-0"
                  v-for="user in membersAttendee.slice(0, 7)"
                  :key="user.id"
                >
                  <div class="team-image">
                    <abbr style="width: 100%" :title="user.username">
                      <div class="users-notification" v-if="imgLoader == 1">
                        <!-- <div class="lds-dual-ring-refresh"></div> -->
                        <img src="../../../../assets/loader.gif" alt="" />
                      </div>
                      <img
                        @load="onImageLoad"
                        v-if="user.avatar != ''"
                        :src="user.avatar"
                        onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                        alt=""
                        class="upload-image"
                      />
                      <img
                        @load="onImageLoad"
                        v-else
                        src="../../../../assets/images/user-default-new.png"
                        alt=""
                      />
                    </abbr>
                  </div>
                </div>
              </div>
              <span class="more-user" v-if="goingMemberCount > 0"
                >+{{ goingMemberCount }} more </span
              ><span class="more-user">going</span>
            </div>
          </div>
        </div>
        <!-- <div
          class="user-counter event-label"
          v-if="showEventsMember == 1 || showDefualt == 1"
        >
          <ul class="counting mb-0">
            <li>
              <span>{{ totalGoing }}</span>
              <span>Going</span>
            </li>
            <li>
              <span>{{ totalNotGoing }}</span>
              <span>Not Going</span>
            </li>
            <li>
              <span>{{ totalMayBe }}</span>
              <span>Maybe</span>
            </li>
            <li>
              <span>{{ totalNotRespond }}</span>
              <span>Not Responded</span>
            </li>
          </ul>
        </div> -->

        <div
          class="edit-event-div pb-3"
          v-if="editTravel && currentIsFan != '1'"
          @click="openEditTravel(Services)"
        >
          <a class="create-event">
            <img src="../../../../assets/images/edit.png" alt="" />
            <span>Edit your travel details</span>
          </a>
        </div>
      </div>

      <div
        class="games-section p-0"
        v-if="
          (showGameList == 1 || showDefualt == 1) &&
          selectedRoomTeam.teamType == 'sports'
        "
      >
        <div class="game-top">
          <div class="game-heading">
            <h6>Games</h6>
          </div>
          <div class="game-details">
            <NewGame
              v-if="
                currentIsAdmin == '1' &&
                selectedRoomTeam.teamType == 'sports' &&
                currentTeamIsActive == 1
              "
              :selectedRoomTeam="selectedRoomTeam"
              :singleEvent="singleEvent"
            />
          </div>
        </div>
        <div class="container-fluid no-pdd">
          <div
            class="row no-gutters"
            v-if="showGameShimmer == 0 && gameList.length > 0"
          >
            <div
              class="col-12 game-wrap"
              v-for="game in gameList"
              :key="game.id"
            >
              <div class="game-detail-wrapper">
                <div class="game-content">
                  <div class="game-name">
                    <h6>{{ game.name }}</h6>
                  </div>
                  <div class="date-content">
                    <p>{{ game.exactDateFormat }}</p>
                  </div>
                  <span
                    class="game-location cursor-pointer"
                    @click="openGoogleMapforGamePark(game)"
                    v-if="game.park_name != null || game.park_name != ''"
                  >
                    <img
                      src="../../../../assets/images/location.png"
                      class="mr-2"
                    />
                    <p>{{ game.park_name }}</p>
                  </span>
                  <div
                    class="game-location"
                    v-if="game.park_address != null || game.park_address != ''"
                  >
                    <p>{{ game.park_address }}</p>
                  </div>
                  <div
                    class="game-location"
                    v-if="game.park_number != null || game.park_number != ''"
                  >
                    <p>{{ game.park_number }}</p>
                  </div>
                  <div class="opponent-name">
                    <img src="../../../../assets/images/teammates.png" alt="" />
                    <h5>{{ game.opponent_name }}</h5>
                  </div>
                  <div
                    v-if="
                      game.comment != null &&
                      game.comment != '' &&
                      game.comment != 'null'
                    "
                    class="game-description"
                  >
                    <p>{{ game.comment }}</p>
                  </div>
                </div>
                <div class="games-actions">
                  <GameActions
                    :singleGame="game"
                    :eventAlertOptions="eventAlertOptions"
                    :selectedRoomTeam="selectedRoomTeam"
                    v-if="
                      currentIsAdmin == '1' &&
                      selectedRoomTeam.teamType == 'sports' &&
                      currentTeamIsActive == 1
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="showGameShimmer == 0 && gameList.length == 0">
            <div class="no-game-section">
              <div class="no-content">
                <div class="img">
                  <img src="../../../../assets/images/games.png" alt="" />
                </div>
                <p class="text">There are no games added for this event yet</p>
              </div>
            </div>
          </div>

          <div class="row no-gutters" v-if="showGameShimmer == 1">
            <div class="col-12 p-0">
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="false" />
              </content-placeholders>
              <br />
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="false" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="mobilePreview"
      :paginate-elements-by-height="1400"
      :filename="singleEvent.eventName"
      :pdf-quality="2"
      :manual-pagination="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <div class="col-12 p-0 text-center roaster-top">
          <div
            class="association"
            v-if="
              singleEvent.association != '' &&
              singleEvent.association != null &&
              singleEvent.association != 'null'
            "
          >
            <span class="content">{{ singleEvent.association }}</span>
          </div>
        </div>
        <diV class="pdf-content mt-3 ml-4">
          <div class="event-image-name">
            <div class="image">
              <img
                v-if="singleEvent.avatar == '' || singleEvent.avatar == null"
                src="../../../../assets/event-avatar.png"
                alt=""
              />

              <img v-else :src="eventURL + singleEvent.avatar" />
            </div>
            <div>
              <h3 class="name">{{ singleEvent.eventName }}</h3>
              <div class="created-by mt-1">
                <span class="heading">Created By: </span>
                <span class="content">{{ singleEvent.createdByName }}</span>
              </div>
              <div class="date">
                <span class="heading">Date:</span>
                <span class="content">{{
                  singleEvent.createdByDate | formatDateChat
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="event-list-details"
            v-if="
              showDefualt == 1 && showEventsMember == 0 && showGameList == 0
            "
          >
            <div class="event-type">
              <span class="heading">Event Type :</span>
              <span class="content"
                >{{ singleEvent.eventType }}
                <span v-if="singleEvent.status == 2">(Online)</span></span
              >
            </div>
            <div
              class="event-location d-flex align-items-center"
              v-if="
                (singleEvent.status == 1 &&
                  singleEvent.address != null &&
                  singleEvent.address != '' &&
                  singleEvent.address != 'null') ||
                (singleEvent.city != null &&
                  singleEvent.city != '' &&
                  singleEvent.city != 'null' &&
                  singleEvent.address != 'null')
              "
            >
              <img
                @click="openGoogleMap(singleEvent)"
                src="../../../../assets/images/location.png"
                class="mr-2"
              />
              <!-- <span @click="openGoogleMap(singleEvent)" class="content">{{
                singleEvent.address != "" &&
                singleEvent.address != null &&
                singleEvent.address != "null"
                  ? singleEvent.address
                  : singleEvent.city
              }}</span> -->
              <span @click="openGoogleMap(singleEvent)" class="content"
                >{{ singleEvent.address }} {{ singleEvent.city }},
                {{ singleEvent.state }}</span
              >
            </div>
            <div class="event-location" v-if="singleEvent.status == 2">
              <img src="../../../../assets/images/link.png" />
              <a class="content" :href="singleEvent.Url" target="_blank">
                {{ singleEvent.Url }}
              </a>
              <!-- <a
          class="content"
          :href="singleEvent.Url"
          target="_blank"
          v-if="singleEvent.Url.length <= 40"
        >
          {{ singleEvent.Url }}
        </a> -->
              <!-- <a
          class="content"
          :href="singleEvent.Url"
          target="_blank"
          v-if="singleEvent.Url.length > 40"
        >
          {{ singleEvent.Url.substring(0, 40) + "..." }}
        </a> -->
            </div>
            <div class="event-date">
              <span class="heading">Date :</span>
              <span class="content"
                >{{ singleEvent.exactStartDate }} to
                {{ singleEvent.exactEndDate }}</span
              >
              <!-- <span class="content" v-if="singleEvent.allDay == true"
                >All Day.</span
              >
              <span class="content" v-else>
                From {{ singleEvent.exactStartTime }} to
                {{ singleEvent.exactEndTime }}</span
              >
              <span class="content" v-if="singleEvent.time_zone !== null">
                {{ singleEvent.time_zone.split(")").pop()}}
              </span> -->
            </div>
          </div>
        </diV>
        <pdf-html :users="finalArray" :selectedRoomTeam="selectedRoomTeam">
        </pdf-html>
      </section>
    </VueHtml2pdf>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="mobilePreview"
      :paginate-elements-by-height="1400"
      :filename="singleEvent.eventName"
      :pdf-quality="2"
      :manual-pagination="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="travelPdf"
    >
      <section slot="pdf-content">
        <div class="col-12 p-0 text-center roaster-top">
          <div class="association">
            <span class="content">{{ singleEvent.association }}</span>
          </div>
        </div>
        <diV class="pdf-content mt-3 ml-4">
          <div class="event-image-name">
            <div class="image">
              <img
                v-if="singleEvent.avatar == '' || singleEvent.avatar == null"
                src="../../../../assets/event-avatar.png"
                alt=""
              />

              <img v-else :src="eventURL + singleEvent.avatar" />
            </div>
            <div>
              <h3 class="name">{{ singleEvent.eventName }}</h3>
              <div class="created-by mt-1">
                <span class="heading">Created By: </span>
                <span class="content">{{ singleEvent.createdByName }}</span>
              </div>
              <div class="date">
                <span class="heading">Date:</span>
                <span class="content">{{
                  singleEvent.createdByDate | formatDateChat
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="event-list-details"
            v-if="
              showDefualt == 1 && showEventsMember == 0 && showGameList == 0
            "
          >
            <div class="event-type">
              <span class="heading">Event Type :</span>
              <span class="content"
                >{{ singleEvent.eventType }}
                <span v-if="singleEvent.status == 2">(Online)</span></span
              >
            </div>
            <div
              class="event-location"
              v-if="
                (singleEvent.status == 1 &&
                  singleEvent.address != null &&
                  singleEvent.address != '' &&
                  singleEvent.address != 'null') ||
                (singleEvent.city != null &&
                  singleEvent.city != '' &&
                  singleEvent.city != 'null') ||
                (singleEvent.state != null &&
                  singleEvent.state != '' &&
                  singleEvent.state != 'null')
              "
            >
              <img
                @click="openGoogleMap(singleEvent)"
                src="../../../../assets/images/location.png"
                class="mr-2"
              />
              <!-- <span @click="openGoogleMap(singleEvent)" class="content">{{
                singleEvent.address != "" &&
                singleEvent.address != null &&
                singleEvent.address != "null"
                  ? singleEvent.address
                  : singleEvent.city
              }}</span> -->
              <span @click="openGoogleMap(singleEvent)" class="content"
                >{{ singleEvent.address }} {{ singleEvent.city }},
                {{ singleEvent.state }}</span
              >
            </div>
            <div class="event-location" v-if="singleEvent.status == 2">
              <img src="../../../../assets/images/link.png" />
              <a class="content" :href="singleEvent.Url" target="_blank">
                {{ singleEvent.Url }}
              </a>
            </div>
            <div class="event-date">
              <span class="heading">Date :</span>
              <span class="content"
                >{{ singleEvent.exactStartDate }} to
                {{ singleEvent.exactEndDate }}</span
              >
              <!-- <span class="content" v-if="singleEvent.allDay == true"
                >All Day.</span
              >
              <span class="content" v-else>
                From {{ singleEvent.exactStartTime }} to
                {{ singleEvent.exactEndTime }}</span
              >
              <span class="content" v-if="singleEvent.time_zone !== null">
                {{ singleEvent.time_zone.split(")").pop() }}
              </span> -->
            </div>
          </div>
        </diV>
        <TravelDetails
          :users="eventGoingForpdf"
          :selectedRoomTeam="selectedRoomTeam"
        />
      </section>
    </VueHtml2pdf>
    <ScoreModal />
  </div>
</template>
<script>
import axios from "axios";
import EditEvent from "../ChatEvents/EditEvent.vue";
import NewGame from "../../Games/NewGame.vue";
import GameActions from "../../Games/GameActions.vue";
import ScoreModal from "../../Games/ScoreModal.vue";
import PrintRoasterModal from "./PrintRoasterModal.vue";
import servicesModal from "./servicesModal.vue";
import VueHtml2pdf from "vue-html2pdf";
import PdfHtml from "./pdfHtml.vue";
import TravelDetails from "./TravelDetails.vue";
import EventAttendeesModal from "./EventAttendeesModal.vue";
import Weather from "./Weather.vue";
import EventWrapper from "./EventWrapper.vue";
import TeamLineUpModal from "../../../SingleBoxScore/SubLeft/TeamLineUpModal.vue";
import EditServicesModal from "./EditServicesModal.vue";
import ExcelJS from "exceljs";

export default {
  name: "EventDetails",
  components: {
    EditEvent,
    NewGame,
    GameActions,
    PrintRoasterModal,
    VueHtml2pdf,
    PdfHtml,
    ScoreModal,
    servicesModal,
    TravelDetails,
    EventAttendeesModal,
    Weather,
    EventWrapper,
    TeamLineUpModal,
    EditServicesModal,
  },
  data() {
    return {
      imgLoader: 1,
      goingMemberCount: 0,
      printOptions: false,
      eventURL: this.$eventAvatarStorageURL,
      membersAttendee: [],
      gameList: [],
      eventAlertOptions: [],
      city: "",
      address: "",
      membersAttendeeComplete: [],
      eventInvitedList: [],
      selectAttendeeTab: 0,
      currentUserEventStatus: 0,
      showLoader: 0,
      showEventsMember: 0,
      showGameList: 0,
      showDefualt: 1,
      totalGoing: 0,
      totalNotGoing: 0,
      totalMayBe: 0,
      totalNotRespond: 0,
      showEventMemeberCountShimmer: 0,
      showGameShimmer: 0,
      eventGoingForpdf: [],
      eventGoingForExcel: [],
      loading: false,
      mobilePreview: true,
      rawData: [],
      dateComp: 1,
      finalArray: [],
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      editTravel: false,
      Services: {},
      attendingList: [],
      userMember_id: "",
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentUserId: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  computed: {
    htmlToPdfOptions() {
      return {
        filename: this.singleEvent.eventName,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          // useCORS: true,
          proxy: this.eventURL + this.singleEvent.avatar,
        },
      };
    },
  },
  methods: {
    callModalEditEvent() {
      this.$root.$emit("openEditEvent");
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    openPrintOptions: function () {
      this.printOptions = !this.printOptions;
    },
    onClickOutside: function () {
      this.printOptions = false;
    },
    // generateTravelPdf() {
    //   this.$refs.travelPdf.generatePdf();
    // },
    // async printTravelDetails() {
    //   await this.getAttendee();
    //   this.generateTravelPdf();
    // },
    async generateReport() {
      await this.getAttendee();
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
      this.loading = false;
    },
    closeDiv: function () {
      this.$root.$emit("showEventDetailPageBack");
    },

    allfilterMember: function () {
      this.selectAttendeeTab = 0;
      const filterAll = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterAll) {
        this.membersAttendee.push(attendee);
      }
      var count = this.membersAttendee.length;
      if (count > 7) {
        this.goingMemberCount = count - 7;
      }
    },
    filterGoing: function () {
      this.selectAttendeeTab = 0;
      const filterGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterGoing) {
        if (
          attendee.event_attendee_status == 1 ||
          attendee.event_attendee_status == "1"
        ) {
          this.membersAttendee.push(attendee);
          this.editTravel = true;
        }
      }
      var count = this.membersAttendee.length;
      if (count > 7) {
        this.goingMemberCount = count - 7;
      }
    },

    filterNotGoing: function () {
      this.selectAttendeeTab = 1;
      const filterNotGoing = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterNotGoing) {
        if (
          attendee.event_attendee_status == 2 ||
          attendee.event_attendee_status == "2"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterMay: function () {
      this.selectAttendeeTab = 2;
      const filterMay = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterMay) {
        if (
          attendee.event_attendee_status == 3 ||
          attendee.event_attendee_status == "3"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },
    filterNotResponded: function () {
      this.selectAttendeeTab = 3;
      const filterNotResponded = this.membersAttendeeComplete;
      this.membersAttendee = [];
      for (const attendee of filterNotResponded) {
        if (
          attendee.event_attendee_status == 0 ||
          attendee.event_attendee_status == "0"
        ) {
          this.membersAttendee.push(attendee);
        }
      }
    },

    showEventsMemberDiv: function () {
      this.$root.$emit("openEventAttendeesModal");
    },

    callModal: function () {
      this.$root.$emit("openPrintRoasterModal");
      // document.querySelector("body").style.overflow = "hidden";
    },

    showDefualtDiv: function () {
      this.showDefualt = 1;
      this.showGameList = 0;
      this.showEventsMember = 0;
    },
    async selectStatusWithServices(status) {
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_status", status);
      formData.append("services", this.selectedArray);
      formData.append("team_id", this.selectedRoomTeam.roomId);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);
      if (
        this.userMember_id !== null &&
        this.userMember_id !== undefined &&
        this.userMember_id !== ""
      ) {
        formData.append("member_id", this.userMember_id);
      }
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getAttendee();
        });
    },

    openEditTravel() {
      // const relevantArray = this.attendingList.find((item) => item.id === Services.id);
      if (this.Services) {
        this.$root.$emit("openEditServicesModal", {
          event: this.singleEvent,
          services: this.Services,
        });
      }
    },
    async selectStatus(status) {
      if (status == 1) {
        this.$root.$emit("openServicesModal", this.singleEvent);
      } else {
        this.editTravel = false;
        var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
        formData.append("member_status", status);
        formData.append("team_id", this.selectedRoomTeam.roomId);
        await axios
          .post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.getAttendee();
          });
      }
    },

    async generateTravelExcelFile() {
      try {
        this.showEventMemeberCountShimmer = 1;
        var formData = new FormData();
        formData.append("event_id", this.singleEvent.id);
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.attendeeListing
        ) {
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          const tempMembers = this.selectedRoomTeam.users;
          const tempMembersRole = this.selectedRoomTeam.userRole;
          this.eventGoingForExcel = [];

          for (const memberRole of tempMembers) {
            for (const memberRoles of tempMembersRole) {
              if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
                for (const attendeeRole of eventAttendeeFromDB) {
                  if (
                    memberRole.c_id == attendeeRole.member_id &&
                    (attendeeRole.member_status == 1 ||
                      attendeeRole.member_status == "1")
                  ) {
                    const foundUser = tempMembersRole.find(
                      (user) => user?.id === memberRole.id
                    );
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForExcel.push(attendeeRole);
                  }
                }
              }
            }
          }

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("EventAttendees");

          const headers = ["Name", "Phone/Email", "Travel Arrangements"];
          worksheet.addRow(headers).eachCell((cell) => {
            cell.font = { bold: true };
          });

          if (this.eventGoingForExcel.length === 0) {
            worksheet.addRow(["No One marked going yet!"]);
          } else {
            this.eventGoingForExcel.forEach((user) => {
              let services = user.services
                ? user.services.split(",").map((service) => service.trim())
                : [];
              let hotelServiceIndex = services.findIndex((service) =>
                service.toLowerCase().includes("hotel")
              );

              // Move the "Hotel" service to the beginning if it exists
              if (hotelServiceIndex !== -1) {
                const hotelService = services.splice(hotelServiceIndex, 1)[0];
                services.unshift(hotelService);
              }

              let servicesInfo = services.join(" / ");

              // Check if the hotel service exists and user has exactStartDate and exactEndDate
              if (
                hotelServiceIndex !== -1 &&
                user.exactStartDate &&
                user.exactEndDate
              ) {
                servicesInfo = `${servicesInfo.replace(
                  "Hotel",
                  `Hotel (${user.exactStartDate} - ${user.exactEndDate}) Room ${user.room_count}x Adults ${user.adult_count}x`
                )} /`;
              }
              const rowData = [
                user.fname && user.lname ? `${user.fname} ${user.lname}` : "",
                user.mob_code && user.mob && user.email
                  ? `${user.mob_code}${user.mob} ${user.email}`
                  : user.mob_code && user.mob && !user.email
                  ? `${user.mob_code}${user.mob}`
                  : !user.mob_code && user.mob && user.email
                  ? `${user.mob}${user.email}`
                  : user.mob_code && !user.mob && user.email
                  ? `${user.mob_code}${user.email}`
                  : !user.mob_code && !user.mob && user.email
                  ? `${user.email}`
                  : !user.mob_code && !user.mob && !user.email
                  ? "No contact information available"
                  : "",
                // user.services ? user.services.split(",").map(service => service.trim()).join(" / ") : '',
                servicesInfo,
              ];
              worksheet.addRow(rowData);
            });
          }

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fileName = this.singleEvent.eventName;

            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              const link = document.createElement("a");
              const url = URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
            this.showEventMemeberCountShimmer = 0;
          });
        } else {
          console.error("Invalid response from the server");
          this.showEventMemeberCountShimmer = 0;
        }
      } catch (error) {
        console.error("Error generating Excel file:", error);
        this.showEventMemeberCountShimmer = 0;
      }
    },

    async getAttendee() {
      this.showEventMemeberCountShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeListing",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const userId = localStorage.getItem("userId");
          const eventAttendeeFromDB = response.data.data.attendeeListing;
          this.attendingList = response.data.data.attendeeListing;
          this.eventInvitedList = response.data.data.inviteListing;
          const tempMembers = this.selectedRoomTeam.users;
          const tempMembersRole = this.selectedRoomTeam.userRole;
          // this.Services = response.data.data.attendeeListing[0];
          const userInAttendeeList = this.attendingList.find(
            (user) => user.user_id == userId
          );
          if (userInAttendeeList) {
            this.Services = userInAttendeeList;
          }
          this.eventGoingForpdf = [];

          // Add Uniform ID to Going Members
          for (const memberRole of tempMembers) {
            for (const memberRoles of tempMembersRole) {
              if (memberRoles.id == memberRole.id && memberRoles.fan != "1") {
                for (const attendeeRole of eventAttendeeFromDB) {
                  if (
                    memberRole.c_id == attendeeRole.member_id &&
                    (attendeeRole.member_status == 1 ||
                      attendeeRole.member_status == "1")
                  ) {
                    const foundUser = tempMembersRole.find(
                      (user) => user?.id === memberRole.id
                    );
                    attendeeRole.uniform = foundUser.uniform;
                    this.eventGoingForpdf.push(attendeeRole);
                  }
                }
              }
            }
          }
          // Add Uniform ID to Going Members

          this.membersAttendeeComplete = [];
          var $i = 0;
          this.totalGoing = 0;
          this.totalNotGoing = 0;
          this.totalMayBe = 0;
          this.totalNotRespond = 0;
          for (const member of tempMembers) {
            for (const memberRole of tempMembersRole) {
              if (memberRole.id == member.id && memberRole.fan != "1") {
                this.membersAttendeeComplete.push(member);
                this.membersAttendeeComplete[$i].event_attendee_status = 0;
                for (const attendee of eventAttendeeFromDB) {
                  if (member.c_id == attendee.member_id) {
                    this.membersAttendeeComplete[$i].event_attendee_status =
                      attendee.member_status;
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        1 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "1"
                    ) {
                      this.totalGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        2 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "2"
                    ) {
                      this.totalNotGoing++;
                    }
                    if (
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        3 ||
                      this.membersAttendeeComplete[$i].event_attendee_status ==
                        "3"
                    ) {
                      this.totalMayBe++;
                    }
                  }
                }

                if (
                  this.membersAttendeeComplete[$i].event_attendee_status == 0 ||
                  this.membersAttendeeComplete[$i].event_attendee_status == "0"
                ) {
                  this.totalNotRespond++;
                }

                if (member.id == this.currentUserId) {
                  this.currentUserEventStatus =
                    this.membersAttendeeComplete[$i].event_attendee_status;
                }
                $i++;
              }
            }
          }
          this.showEventMemeberCountShimmer = 0;
          this.showLoader = 1;
          this.filterGoing();
          this.showLoader = 0;
        });
    },

    getEventAlert() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getEventAlert`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.eventAlertOptions = response.data.data.alerts;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },

    async getGames() {
      this.showGameShimmer = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("team_id", this.selectedRoomTeam.roomId);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          if (this.gameList.length > 0) {
            this.getEventAlert();
          }
          this.showGameShimmer = 0;
        });
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    MobileHidePdfContent: function () {
      let w = innerWidth;
      if (w < 768) {
        this.mobilePreview = false;
      }
    },
    compareDate() {},
  },

  mounted() {
    window.scrollTo(0, 0);
    this.compareDate();
    this.getAttendee();
    this.getGames();
    this.$root.$on("showGameList", () => {
      this.getGames();
    });
    this.MobileHidePdfContent();

    this.$root.$on("openprintPdf", (data) => {
      this.finalArray = data;
      this.generateReport();
    });
    this.$root.$on(
      "dataForservice",
      (
        data,
        user_id,
        room_count,
        adultQuantity,
        note,
        exactRoomStartDate,
        exactRoomEndDate
      ) => {
        this.selectedArray = data;
        this.userMember_id = user_id;
        this.room_count = room_count;
        this.note = note;
        this.adult_count = adultQuantity;
        this.exactRoomEndDate = exactRoomEndDate;
        this.exactRoomStartDate = exactRoomStartDate;
        this.selectStatusWithServices();
      }
    );
  },
};
</script>
<style>
.event-details-wrapper {
  overflow-x: hidden;
}
.event-details-top {
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
}
.event-details-top .back-arrow {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-details-top .back-arrow:hover {
  background-color: #e2f5fe;
}

.event-details-top .back-arrow img {
  width: 20px;
}
.event-details-top h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #254c72;
}

.event-details-top .edit-event-div a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #188ef5;
  margin: 0 !important;
}
.event-image-name .image {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  overflow: hidden;
}
.event-image-name .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.event-image-name .name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-transform: capitalize;
}

#PrintRoasterModal .user-info,
.images-overlap .team-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -10px;
}

#PrintRoasterModal .user-info {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 8px;
  align-items: flex-start !important;
}

#PrintRoasterModal .user-info .data-wrapper {
  width: calc(100% - 70px);
}

#PrintRoasterModal .image-wrapper {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 10px;
  background-color: #f1f3f6;
  text-align: center;
}
#PrintRoasterModal .image-wrapper img.user-default,
.images-overlap .image-wrapper img.user-default {
  width: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 100px;
}
#PrintRoasterModal .image-wrapper img,
.images-overlap .image-wrapper img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  border-radius: 100px;
}

.images-overlap span {
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.event-label h6 {
  color: #333333;
  font-weight: 400;
  margin: 10px 5px;
  font-size: 16px;
}

.cursor-p {
  cursor: pointer;
}
.event-label ul li.active {
  background-color: #188ef5;
  color: #ffffff;
}
.event-label ul li.active:before {
  content: "✓";
  position: absolute;
  top: -10px;
  background-color: #ff5b66;
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.view-details {
  border-bottom: none;
  padding-bottom: 0;
}
.view-details h6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.view-details .print-roaster-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}
.view-details .view-details-link span,
.view-details .print-roaster-btn span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #254c72;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.game-content-section {
  max-width: 350px;
  overflow-x: auto;
}

#PrintRoasterModal h6 {
  color: #333333;
  font-weight: 400;
  margin: 10px 5px;
  font-size: 16px;
}

.user-data button {
  border: none;
  background-color: #188ef5;
  color: #ffffff;
  border-radius: 5px;
  padding: 6px 8px;
  margin-top: 10px;
}
div.form-group textarea {
  border: 1px solid #ced4da;
  font-size: 14px;
  color: #495055;
  width: 100%;
  padding: 3px 15px;
  border-radius: 0.7rem;
  outline: none;
  height: 150px;
  margin-bottom: 15px;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
div.form-group textarea:valid + span.placeholder,
div.form-group textarea:focus + span.placeholder {
  -webkit-transform: scale(0.8) translateY(-25px);
  -moz-transform: scale(0.8) translateY(-25px);
  -ms-transform: scale(0.8) translateY(-25px);
  -o-transform: scale(0.8) translateY(-25px);
  transform: scale(0.8) translateY(-25px);
  background: #fff;
}

div.form-group textarea:focus {
  color: #333333;
  font-weight: 500;
  border-color: #188ef5 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

div.form-group textarea:focus + span.placeholder {
  color: #188ef5;
}

#PrintRoasterModal .user-content {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #c4c4c4;
  padding: 6px 0;
  margin-bottom: 5px;
}

#PrintRoasterModal .user-content:nth-child(3) {
  border-bottom: none !important;
}

#PrintRoasterModal .user-content .title {
  color: #188ef5;
  font-size: 1rem;
  margin-right: 10px;
}

#PrintRoasterModal .user-content .data {
  color: #000000;
  font-size: 1rem;
  margin-left: 10px;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .vue-html2pdf .pdf-preview {
    display: none !important;
  }
}

.roaster-top .association .content {
  font-size: 30px;
}

.view-details-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.view-details-link .print-roaster-btn img {
  width: 20px;
}

.images-overlap {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
