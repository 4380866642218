<template>
  <div>
    <div class="new my-2" v-if="showLoader == 1">
      <div class="friends-requests p-0">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
        </content-placeholders>
      </div>
    </div>

    <router-link v-if="showLoader == 0" class="mx-0" exact :to="{ name: 'TeamStatistics', params: { teamId: selectedRoomTeam.roomId } }">
      <div class="d-flex align-items-center justify-content-between w-100 w-lg-25" style="gap: 4px">
        <div class="counter-widget">
          <h3 class="mb-0">Games</h3>
          <span class="counter-value">{{ totalGame }}</span>
        </div>
      
        <div class="counter-widget">
          <h3 class="mb-0">Won</h3>
          <span class="counter-value">{{ win }}</span>
        </div>
      
        <div class="counter-widget">
          <h3 class="mb-0">Lost</h3>
          <span class="counter-value">{{ loss }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "TeamRecord",
  props: {
    showLoader: {
      type: Number,
    },
    selectedRoomTeam: {
      type: Object,
    },
   totalGame: {
      type: Number,
    },
    win: {
      type: Number,
    },
    loss: {
      type: Number,
    },
  },
};
</script>