<template>
  <div>
    <div class="chat-system-top" v-if="showChatSystemDiv">
      <ul class="chat-selection" v-if="showArchiveTeams == 0">
        <li>
          <a
            :class="{
              active: pointActiveTeam,
              'disabled-button': isButtonExecuting,
            }"
            v-on:click="showTeamList"
          >
            Teams
          </a>
          <span class="counter" v-if="teamsChatCounter > 0">{{teamsChatCounter}}</span>
        </li>
        <li>
          <a
            :class="{
              active: pointActiveIndividual,
              'disabled-button': isButtonExecuting,
            }"
            v-on:click="showIndividualList"
            >Individuals
          </a>
           <span class="counter" v-if="individualChatCounter > 0">{{individualChatCounter}}</span>
        </li>
      </ul>
      <ul class="event-top archive mb-0 border-0" v-if="showArchiveTeams == 1">
        <div
          class="back-arrow"
          v-if="showArchiveTeams == 1"
          v-on:click="showTeamList"
        >
          <img src="../../assets/images/arrow-left.png" alt="" />
        </div>
        <li>
          <a v-on:click="showArchiveTeamList"> Archived Teams</a>
        </li>
      </ul>
      <div class="chat-options" v-if="showArchiveTeams == 0">
        <button
          class="btn btn-primary wh"
          v-if="showTeams == 0"
          data-toggle="modal"
          data-target="#teamModal"
          alt=""
          v-on:click="resetForms(), getData()"
        >
          Add Team
        </button>

        <button
          class="btn btn-primary wh"
          v-if="showIndividual == 0"
          data-toggle="modal"
          data-target="#individualModal"
          alt=""
          v-on:click="resetForms()"
        >
          New Chat
        </button>

        <!-- <div
          class="chat-filter-options"
          v-on:click="showFilterOption"
          v-if="showTeams == 0"
        >
          <span>Filter</span>
          <span class="filter-counter">{{
            Object.keys(this.filter).length
          }}</span>
          <img src="../../assets/images/arrow-down.png" alt="" />
        </div> -->

        <div
          class="chat-options chat-option-archive cursor-pointer"
          v-on:click="showArchiveOption"
          v-click-outside="onClickOutside"
        >
          <img src="../../assets/Svg/ellipse.svg" alt="" />
        </div>

        <div v-if="archiveOption" class="sub-setting-options">
          <ul>
            <li v-on:click="showArchiveTeamList">Archived Teams</li>
          </ul>
        </div>

        <div class="popup" v-if="filterOption">
          <div class="blocker" @click="hidePopup()"></div>

          <div class="filter-options">
            <div>
              <label for="">Year</label>
              <v-select
                v-model="filter.chat_year"
                :options="years"
                label="name"
                :placeholder="yearPlaceholder"
                @input="setSelectedYear"
              />
            </div>
            <div class="mt-2">
              <label for="filterTeam">Team Type</label>
              <v-select
                v-model="filter.team_type"
                :options="teamTypeFilters"
                label="label"
                :placeholder="teamTypePlaceholder"
                @input="setSelectedTeamType"
              />
            </div>
            <div class="mt-2" v-if="filter.team_type === 'sports'">
              <label for="sportType">Sport Type</label>
              <v-select
                v-model="filter.sportTypeFilter"
                :options="sportOptions"
                label="name"
                :placeholder="sportTypePlaceholder"
                @input="setSelectedSportType"
              />
            </div>
            <div class="event-btns-wrapper p-0 mt-4">
              <button
                v-if="this.isDisabledReset == false"
                type="button"
                class="btn btn-secondary w-50"
                @click="clearFilter(0)"
                :disabled="isLoadingArrayReset[0]"
              >
                <div v-if="isLoadingArrayReset[0]" class="lds-dual-ring"></div>
                <span v-else>Reset</span>
              </button>
              <button
                v-else-if="this.isDisabledReset == true"
                type="button"
                class="btn btn-secondary-disabled w-50"
                :disabled="(true, isLoadingArrayReset[0])"
              >
                <div v-if="isLoadingArrayReset[0]" class="lds-dual-ring"></div>
                <span v-else>Reset</span>
              </button>

              <button
                v-if="this.isDisabledFilter == false"
                type="button"
                class="btn btn-primary w-50"
                @click="getFilters(0)"
                :disabled="isLoadingArrayFilter[0]"
              >
                <div v-if="isLoadingArrayFilter[0]" class="lds-dual-ring"></div>
                <span v-else>Apply</span>
              </button>
              <button
                v-else-if="this.isDisabledFilter == true"
                type="button"
                class="btn btn-primary-disabled w-50"
                :disabled="(true, isLoadingArrayFilter[0])"
              >
                <div v-if="isLoadingArrayFilter[0]" class="lds-dual-ring"></div>
                <span v-else>Apply</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-section" v-if="showFilters && showTeams == 0">
      <div class="filter-content">
        <ul>
          <li class="list" v-for="(f, i) in filter" :key="i">
            <span class="tags">
              {{ f }}
              <img
                src="../../assets/close-grey.png"
                alt=""
                @click="removeTag(f)"
              />
            </span>
          </li>
        </ul>
      </div>

      <!-- <div class="filter-info" v-if="Object.keys(this.filter).length > 0">
        <p>
          View collective stats for the {{ filter.chat_year }}
          {{ filter.team_type }} {{ filter.sportTypeFilter }} teams
        </p>
      </div> -->
    </div>

    <button
      class="d-none"
      data-toggle="modal"
      data-target="#teamModal"
      ref="openAddteam"
    ></button>

    <div
      class="modal fade"
      id="teamModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">New Team</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalTeam"
              v-on:click="resetForms()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
          >
            <form>
              <div class="team-top-image mb-3">
                <div class="team-image-create" v-on:click="openinput">
                  <img
                    v-if="this.groupAvatar.length == 0"
                    src="../../assets/images/main-team-avatar.png"
                    alt=""
                    class="default-image"
                  />
                  <clipper-basic
                    v-else-if="this.groupAvatar.length > 0"
                    :src="this.rawData"
                    preview="preview"
                    :border="4"
                    :outline="0"
                    :corner="false"
                    :ratio="1"
                    :wrap-ratio="1"
                     :init-width="100"
                        :init-height="100"
                    ref="clipper"
                  ></clipper-basic>
                  <div class="edit-icon-wrapper">
                    <img src="../../assets/images/edit.png" alt="" />
                  </div>
                </div>
                <div class="preview-image">
                  <div v-if="this.groupAvatar.length > 0">
                    <clipper-preview name="preview"></clipper-preview>
                  </div>
                  <div
                    v-else-if="
                      this.groupAvatar.length == 0 ||
                      this.groupAvatar.length == ''
                    "
                    class="no-image-wrapper"
                  >
                    <div slot="placeholder">No image</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="team-name-type">
                  <div class="mt-1">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        required
                        autocomplete="off"
                        v-model="teamName"
                        v-on:keyup="errorNullTeam"
                        spellcheck="false"
                        trim
                      />
                      <span class="placeholder">Team Name</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                      v-model="teamType"
                      @change="errorNullTeam"
                    >
                    <option value="" selected disabled>Select Team Type</option>
                    <option v-for="option in teamTypeOptions" :key ="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                    </select>
                  </div>
                  <div class="form-group" v-if="showSportType == 1">
                    <select
                      class="form-control form-control-lg"
                      id="sportType"
                      v-model="sportType"
                      @change="errorNullTeam"
                    >
                      <option value="" selected disabled>
                        Select Sport Type
                      </option>
                      <option
                        v-for="option in sportOptions"
                        :key="option.id"
                        :value="option.name"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group" v-if="showSportType == 1">
                    <select
                      class="form-control form-control-lg"
                      id="ageGroup"
                      v-model="ageGroup"
                      @change="errorNullTeam"
                    >
                      <option value="" selected disabled>
                        Select Age Group
                      </option>
                      <option
                        v-for="option in ageOptions"
                        :key="option.id"
                        :value="option.name"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group" v-if="showSportType == 1">
                    <select
                      class="form-control form-control-lg"
                      id="gender"
                      v-model="gender"
                      @change="errorNullTeam"
                    >
                      <option value="" selected disabled>Select Gender</option>
                      <option
                        v-for="option in genderOptions"
                        :key="option.id"
                        :value="option.name"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="member-select mt-2">
                <label class="typo__label" for="ajax">Select friends</label>
                <multiselect
                  v-model="selectedUsers"
                  id="ajax"
                  label="fname"
                  class="select-friends"
                  track-by="id"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="addMultipleUsers"
                  :multiple="true"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="300"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="asyncFind"
                  @scroll.stop
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <div class="custom__content">
                        <div class="user-image">
                          <img
                            v-if="option.picture !== 'yes'"
                            src="../../assets/images/user-default-new.png"
                            alt=""
                            class="user-default"
                          />
                          <img
                            v-else
                            :src="
                              API_URL +
                              option.id +
                              '/profile_picture.jpg?v=' +
                              time
                            "
                            alt=""
                            style="
                              width: 40px;
                              height: 40px;
                              background-size: cover;
                              background-position: center;
                              background-repeat: no-repeat;
                              object-fit: cover;
                              border-radius: 100px;
                            "
                          />
                        </div>
                        <span class="user__name text-capitalize">
                          {{ option.user_name }}</span
                        >
                      </div>
                      <span
                      v-if="!creatingTeam"
                        class="custom__remove cursor-pointer"
                        v-on:click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                  <template slot="clear" slot-scope="props">
                    <div
                      class="multiselect__clear"
                      v-if="selectedUsers.length"
                      @mousedown.prevent.stop="clearAll(props.search)"
                    ></div> </template
                  ><span slot="noResult"
                    >Oops! No User found. Consider changing the search
                    query.</span
                  >
                  <span slot="noOptions"> No User Found </span>
                  <template slot="option" slot-scope="{ option }">
                    <div class="option__desc">
                      <div class="user-image">
                        <div class="users-notification" v-if="imgLoader == 1">
                          <!-- <div class="lds-dual-ring-refresh"></div> -->
                          <img src="../../assets/loader.gif" alt="" />
                        </div>
                        <img
                          @load="onImageLoad"
                          v-if="option.picture !== 'yes'"
                          src="../../assets/images/user-default-new.png"
                          alt=""
                        />
                        <img
                          @load="onImageLoad"
                          v-else
                          :src="
                            API_URL +
                            option.id +
                            '/profile_picture.jpg?v=' +
                            time
                          "
                          alt=""
                          style="
                            width: 40px;
                            height: 40px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            object-fit: cover;
                            border-radius: 100px;
                          "
                        />
                      </div>
                      <span class="text-capitalize">
                        {{ option.user_name }}</span
                      >
                    </div>
                  </template>
                </multiselect>
              </div>

              <input
                type="file"
                class="d-none"
                id="vue-file-upload-input"
                @change="addGroupAvatar"
                accept="image/png, image/gif, image/jpeg"
              />
            </form>
          </div>
          <div class="event-btns-wrapper justify-content-end pr-4 pb-3">
            <button
              v-if="this.isDisabledTeam == false"
              type="button"
              class="btn btn-primary w-25"
              v-on:click="createTeam(user.name, 0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabledTeam == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="individualModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">New Chat</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalIndividual"
              v-on:click="resetForms()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="min-height: 200px">
            <div v-if="showLoader == 1">
              <div class="lds-dual-ring-media"></div>
            </div>
            <div v-if="showLoader == 0">
              <div class="mb-2">
                <label class="typo__label" for="ajaxsingle"
                  >Select friends</label
                >
                <multiselect
                  v-model="selectedIndUsers"
                  id="ajaxsingle"
                  label="fname"
                  track-by="id"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="addMultipleUsers"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="asyncFind"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <div class="user-image">
                        <img
                          v-if="option.picture !== 'yes'"
                          src="../../assets/images/user-default-new.png"
                          alt=""
                        />
                        <img
                          v-else
                          :src="
                            API_URL +
                            option.id +
                            '/profile_picture.jpg?v=' +
                            time
                          "
                          alt=""
                          style="
                            width: 40px;
                            height: 40px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            object-fit: cover;
                            border-radius: 100px;
                          "
                        />
                      </div>
                      <!-- <span> {{ option.user_name }}</span -->
                      ><span class="custom__remove" v-on:click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                  <template slot="clear" slot-scope="props">
                    <div
                      class="multiselect__clear"
                      v-if="selectedUsers.length"
                      @mousedown.prevent.stop="clearAll(props.search)"
                    ></div> </template
                  ><span slot="noResult"
                    >Oops! No User found. Consider changing the search
                    query.</span
                  >
                  <span slot="noOptions"> No User Found </span>
                  <template slot="option" slot-scope="{ option }">
                    <div class="option__desc">
                      <div class="user-image">
                        <div class="users-notification" v-if="imgLoader == 1">
                          <!-- <div class="lds-dual-ring-refresh"></div> -->
                          <img src="../../assets/loader.gif" alt="" />
                        </div>
                        <img
                          @load="onImageLoad"
                          v-if="option.picture !== 'yes'"
                          src="../../assets/images/user-default-new.png"
                          alt=""
                        />
                        <img
                          @load="onImageLoad"
                          v-else
                          :src="
                            API_URL +
                            option.id +
                            '/profile_picture.jpg?v=' +
                            time
                          "
                          alt=""
                          style="
                            width: 40px;
                            height: 40px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            object-fit: cover;
                            border-radius: 100px;
                          "
                        />
                      </div>
                      <span class="text-capitalize">
                        {{ option.user_name }}</span
                      >
                    </div>
                  </template>
                </multiselect>
                <!-- <select2Ajax
                v-model="addIndividualUser"
                @change="errorNullIndividual"
              >
              </select2Ajax> -->
              </div>
              <div class="form-group">
                <textarea
                  type="text"
                  class="form-control message"
                  required
                  rows="5"
                  placeholder="Message"
                  id="individualMessage"
                  name="individualMessage"
                  :value="individualMessage"
                  @input="(e) => (individualMessage = e.target.value)"
                  @keyup="errorNullIndividual()"
                ></textarea>
              </div>

              <div class="event-btns-wrapper justify-content-end">
                <button
                  v-if="this.isDisabledIndividual == false"
                  type="button"
                  class="btn btn-primary w-25"
                  v-on:click="createChat(user.name, 0)"
                  :disabled="isLoadingArraySingle[0]"
                >
                  <div
                    v-if="isLoadingArraySingle[0]"
                    class="lds-dual-ring"
                  ></div>
                  <span v-else>Save</span>
                </button>
                <button
                  v-else-if="this.isDisabledIndividual == true"
                  type="button"
                  class="btn btn-primary-disabled w-25"
                  :disabled="(true, isLoadingArraySingle[0])"
                >
                  <div
                    v-if="isLoadingArraySingle[0]"
                    class="lds-dual-ring"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as firestoreService from "../../database/firestore";
import { mapGetters } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { parseTimestamp } from "../../utils/dates";
import $ from "jquery";
import { clipperBasic, clipperPreview } from "vuejs-clipper";

export default {
  name: "ChatSystem",
  components: {
    Multiselect,
    clipperBasic,
    clipperPreview,
  },
  data() {
    return {
      currentTabUrl: "",
      showChatSystemDiv: false,
      isButtonExecuting: true,
      imgLoader: 1,
      isLoadingArray: [],
      isLoadingArraySingle: [],
      isLoadingArrayFilter: {},
      isLoadingArrayReset: {},
      isDisabledIndividual: true,
      isDisabledTeam: true,
      isDisabledFilter: true,
      isDisabledReset: true,
      showLoaderIndividual: 0,
      showLoaderTeam: 0,
      showLoader: 0,
      showTeams: 0,
      showIndividual: 1,
      showArchiveTeams: 0,
      pointActiveTeam: "active",
      pointActiveIndividual: "",
      addIndividualUser: "-1",
      addMultipleUsers: [],
      individualMessage: "",
      teamName: "",
      teamType: "",
      sportType: "",
      isGameTime: false,
      teamTypeFilter: "",
      ageGroup: "",
      gender: "",
      teamAvatar: "",
      foundSentUser: "",
      sentUserId: "",
      selectedAjax: "-1",
      selectedIndUsers: "",
      rawData: "",
      groupAvatar: [],
      selectedUsers: [],
      allUsersRef: [],
      teamSelectedUser: [],
      teamSelectedUserRole: [],
     localSelectedUserRole: [],
      teamUserUncount: [],
      isLoading: false,
      foundAlreadyCreatedRoom: "",
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      archiveOption: false,
      filterOption: false,
      sportOptions: [],
      ageOptions: [],
      genderOptions: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
        { name: "Coed", id: "Coed" },
      ],
      showSportType: 0,
      filter: {},
      years: [
        { code: "1", name: "2020" },
        { code: "2", name: "2021" },
        { code: "3", name: "2022" },
        { code: "3", name: "2023" },
      ],
      teamTypeFilters: [
        { code: "w", name: "work", label: "Work" },
        { code: "s", name: "sports", label: "Sports" },
        { code: "f", name: "family", label: "Family" },
      ],

      yearPlaceholder: "",
      teamTypePlaceholder: "",
      sportTypePlaceholder: "",
      filters: [],
      showFilters: false,
      individualChatCounter: 0,
      teamsChatCounter: 0,

      creatingTeam: false,
    };
  },
  props: {
    currentUserId: {
      type: String,
    },
  },
  
  methods: {
    ...mapActions("team", ["createIndividualChatAction"]),

    onImageLoad() {
      this.imgLoader = 0;
    },

    checkURL() {
      // const currentURL = window.location.href;

      // if (currentURL.includes("my-teams")) {
      //   if (this.teamType == "") {
      //     this.teamType = "sports";
      //   }
      // } else {
        this.teamType == "";
      // }
    },

    toggleDiv() {
      this.currentTabUrl = localStorage.getItem("currentUrl");
      if (this.currentTabUrl == "/my-teams") {
        this.showChatSystemDiv = false;
      } else if (this.currentTabUrl.startsWith("/chat")) {
        this.showChatSystemDiv = true;
      }
    },

    setSelectedYear(value) {
      if (value != null) {
        this.filter.chat_year = value.name;
        this.isDisabledFilter = false;
        this.isDisabledReset = false;
      }
    },

    setSelectedTeamType(value) {
      if (value != null) {
        this.filter.team_type = value.name;
        this.isDisabledFilter = false;
        this.isDisabledReset = false;
      }
      if (value.name != "sports") {
        delete this.filter.sportTypeFilter;
      }
    },

    setSelectedSportType(value) {
      if (value != null) {
        this.filter.sportTypeFilter = value.name;
        this.isDisabledFilter = false;
        this.isDisabledReset = false;
      }
    },

    getFilters(index) {
      this.$set(this.isLoadingArrayFilter, index, true);
      this.filters = Object.values(this.filter);
      this.showFilters = true;
      this.isDisabledFilter = true;
      this.$root.$emit("fetchTeamsByfilters", this.filter);
      setTimeout(() => {
        this.$set(this.isLoadingArrayFilter, index, false);
        this.filterOption = false;
      }, 2000);
      if (Object.keys(this.filter).length > 0) {
        let window_width = window.innerWidth;

        if (window_width <= 991) {
          $(".vac-rooms-container").css("height", "calc(100vh - 234px)");
        } else {
          $(".vac-rooms-container").css("height", "calc(100vh - 170px)");
          $(".vac-col-messages").css("margin-top", "-101.9px");
        }

        $(".no-chat-wrapper").css("height", "calc(100vh - 170px)");
      }
    },
    removeTag(f) {
      for (const key in this.filter) {
        if (
          Object.prototype.hasOwnProperty.call(this.filter, key) &&
          this.filter[key] === f
        ) {
          this.$delete(this.filter, key);
        }
      }
      this.$root.$emit("fetchTeamsByfilters", this.filter);
      if (Object.keys(this.filter).length == 0) {
        this.showFilters = false;
        $(".vac-rooms-container").css("height", "calc(100vh - 135px)");
        $(".vac-col-messages").css("margin-top", "-69.9px");

        let window_width = window.innerWidth;

        if (window_width <= 991) {
          $(".vac-rooms-container").css("height", "calc(100vh - 200px)");
        }
      }
    },

    limitText(count) {
      return `and ${count} others`;
    },
    showArchiveOption() {
      this.archiveOption = !this.archiveOption;
    },
    onClickOutside() {
      this.archiveOption = false;
    },

    hidePopup: function () {
      this.filterOption = false;
      // this.filter = {};
      // this.showFilters = false;
      // this.$root.$emit("fetchTeamsByfilters", this.filter);
      if (Object.keys(this.filter).length > 0 && this.showFilters == true) {
        let window_width = window.innerWidth;

        if (window_width <= 991) {
          $(".vac-rooms-container").css("height", "calc(100vh - 234px)");
        } else {
          $(".vac-rooms-container").css("height", "calc(100vh - 170px)");
          $(".vac-col-messages").css("margin-top", "-101.9px");
        }

        $(".no-chat-wrapper").css("height", "calc(100vh - 170px)");
      }

      // if (Object.keys(this.filter).length > 0 && this.showFilters == false) {
      //   this.filter = {};
      // }

      if (Object.keys(this.filter).length == 0) {
        let window_width = window.innerWidth;

        if (window_width <= 991) {
          $(".vac-rooms-container").css("height", "calc(100vh - 200px)");
        } else {
          $(".vac-rooms-container").css("height", "calc(100vh - 135px)");
          $(".vac-col-messages").css("margin-top", "-69.9px");
        }

        $(".no-chat-wrapper").css("height", "calc(100vh - 235px)");
      }
    },

    showFilterOption() {
      this.filterOption = !this.filterOption;
      this.getSportType();
      this.isDisabledFilter = true;
      this.isDisabledReset = true;
      if (
        this.filter.team_type != null ||
        this.filter.chat_year != null ||
        this.filter.sportTypeFilter != null
      ) {
        this.isDisabledReset = false;
      } else {
        this.isDisabledReset = true;
      }
    },
    clearFilter: function () {
      this.filter = {};
      this.showFilters = false;
      this.filterOption = false;
      this.isDisabledFilter = true;
      this.isDisabledReset = true;
      this.$root.$emit("fetchTeamsByfilters", this.filter);
      $(".vac-rooms-container").css("height", "calc(100vh - 135px)");
      $(".vac-col-messages").css("margin-top", "-69.9px");

      let window_width = window.innerWidth;

      if (window_width <= 991) {
        $(".vac-rooms-container").css("height", "calc(100vh - 200px)");
      }
    },

    async checkBlockUser(name, userID) {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("blocked_user_id", userID);
      axios
        .post(process.env.VUE_APP_API_URL + "block/checkBlockUser", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.blockUserFlag = response.data.data.user_blocked;
          if (this.blockUserFlag == 1) {
            var elem = this.$refs.closeModalIndividual;
            if (elem) {
              elem.click();
            }
            this.$alert(
              "You are not allowed to send direct message to " + name
            );
          }
          this.showLoader = 0;
        });
    },

    asyncFind(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}friends/friendList?search=${
            searchQuery ? searchQuery : ""
          }`,
          this.details,
          {}
        )
        .then((response) => {
          this.addMultipleUsers = response.data.data;
          this.isLoading = false;
        });
    },
    getSportType() {
      this.errorNullTeam();
      axios
        .get(`${process.env.VUE_APP_API_URL}getSportType`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.sportOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getAgeGroup() {
      this.errorNullTeam();
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    clearAll() {
      this.selectedUsers = [];
      this.teamSelectedUser = [];
      this.teamSelectedUserRole = [];
      this.localSelectedUserRole=[]
      this.teamUserUncount = [];
    },
    async showTeamList() {
      this.$router.push("/chat/team");
      this.isButtonExecuting = true;
      this.showArchiveTeams = 0;
      this.showIndividual = 1;
      this.showTeams = 0;
      this.pointActiveIndividual = "";
      this.pointActiveTeam = "active";
      this.$root.$emit("showTeamList");
      this.resetForms();
      this.filter = {};
      // this.filter = { chat_year: new Date().getFullYear() };
      this.showFilters = true;
      // let window_width = window.innerWidth;

      // if (window_width <= 991) {
      //   $(".vac-rooms-container").css("height", "calc(100vh - 235px)");
      // } else {
      //   $(".vac-rooms-container").css("height", "calc(100vh - 170px)");
      //   $(".vac-col-messages").css("margin-top", "-101.9px");
      // }
      // $(".no-chat-wrapper").css("height", "calc(100vh - 170px)");
    },
    async showIndividualList() {
      this.$router.push("/chat/user");
      this.isButtonExecuting = true;
      this.showArchiveTeams = 0;
      this.showIndividual = 0;
      this.showTeams = 1;
      this.pointActiveIndividual = "active";
      this.pointActiveTeam = "";
      this.$root.$emit("showIndividualList");
      this.resetForms();
      this.filterOption = false;
      this.filter = {};

      // let window_width = window.innerWidth;

      // if (window_width <= 991) {
      //   $(".vac-rooms-container").css("height", "calc(100vh - 200px)");
      // } else {
      //   $(".vac-rooms-container").css("height", "calc(100vh - 135px)");
      //   $(".vac-col-messages").css("margin-top", "-69.9px");
      // }
      // $(".no-chat-wrapper").css("height", "calc(100vh - 135px)");
    },

    showArchiveTeamList: function () {
      this.showArchiveTeams = 1;
      this.$root.$emit("showTeamArchiveList");
      this.resetForms();
    },

    errorNullIndividual: function () {
      if (this.selectedIndUsers == "" || this.individualMessage == "") {
        this.isDisabledIndividual = true;
      } else {
        this.isDisabledIndividual = false;
      }
    },

    errorNullTeam: function () {
      if (this.teamType == "sports") {
        this.showSportType = 1;
        if (
          this.teamName == "" ||
          this.teamType == "" ||
          this.sportType == "" ||
          this.ageGroup == "" ||
          this.gender == ""
        ) {
          this.isDisabledTeam = true;
        } else {
          this.isDisabledTeam = false;
        }
      } else {
        this.showSportType = 0;
        if (this.teamName == "" || this.teamType == "") {
          this.isDisabledTeam = true;
        } else {
          this.isDisabledTeam = false;
        }
      }
    },

    openinput: function () {
      document.getElementById("vue-file-upload-input").click();
    },

    addGroupAvatar: function (event) {
      const self = this;
      var fileInput = document.getElementById("vue-file-upload-input");
      var file_ = fileInput.value;
      var files = event.target.files;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        const file_ = files[0];
        this.groupAvatar.push({
          target: event.target.files[0],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();

        reader.onload = (e) => {
          self.rawData = e.target.result;
        };
        reader.readAsDataURL(file_);
      }
    },

    dataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    // ---- Individual ----------------------------------------------------------------------
    async createChat(currentUserName, index) {
      this.isDisabledIndividual = true;
      this.showLoaderIndividual = 1;
      this.$set(this.isLoadingArraySingle, index, true);
      const chatData = [];
      chatData["currentUserId"] = this.currentUserId;
      chatData["currentUserName"] = currentUserName;
      chatData["currentUserCid"] = localStorage.getItem("userId");
      chatData["selectedIndUsers"] = this.selectedIndUsers;
      chatData["individualMessage"] = this.individualMessage;

      this.createIndividualChatAction(chatData).then(() => {
        this.selectedIndUsers = "";
        this.individualMessage = "";
        this.showLoaderIndividual = 0;
        this.$set(this.isLoadingArraySingle, index, false);
        this.$root.$emit("showIndividualList");
        var elem = this.$refs.closeModalIndividual;
        elem.click();
        this.resetForms();
      });
    },

    // ---- Team ----------------------------------------------------------------------
    async createTeam(currentUserName, index) {
  this.isDisabledTeam = true;
  this.creatingTeam = true;
  this.showLoaderTeam = 1;
  this.$set(this.isLoadingArray, index, true);

  var currentUserId = this.currentUserId;

  // If currentUserId is null, fetch from Firestore using localStorage userId
  if (!currentUserId) {
    const localUserId = localStorage.getItem("userId");
    if (localUserId) {
      const userCidData = await firestoreService.getUserCid(localUserId.toString());
      if (userCidData?.data?.length > 0) {
        currentUserId = userCidData.data[0].id; // Assign fetched ID
      }
    }
  }

  // Push currentUserId to the team if valid
  if (currentUserId) {
    this.teamSelectedUser.push(currentUserId);

    // Add admin roles for team creator
    const arrayAdminUser = {
      id: currentUserId,
      admin: "1",
      notification: "on",
      pushNotification: "on",
      status: 1,
    };
    const arrayAdminUsers = {
      id: currentUserId,
      user_id: localStorage.getItem("userId"),
      admin: "1",
      notification: "on",
      pushNotification: "on",
      status: 1,
    };

    this.teamSelectedUserRole.push(arrayAdminUser);
    this.localSelectedUserRole.push(arrayAdminUsers);
  }

  console.log(this.selectedUsers);

  for (const user of this.selectedUsers) {
    console.log("hello");
    console.log(user, "hdhdh");

    const rawUserCID = [];
    this.foundSentUser = "";

    const promiseID = firestoreService.getUserCid(user.id.toString());
    rawUserCID.push(promiseID);

    const data = await Promise.all(rawUserCID);
    console.log(data);

    this.foundSentUser = data[0]?.data[0];
    console.log("jsjjs", this.foundSentUser);

    if (this.foundSentUser) {
      this.teamSelectedUser.push(this.foundSentUser.id);

      // Add admin roles for non-admin
      const arrayAdminUserS = {
        id: this.foundSentUser.id,
        admin: "0",
        notification: "on",
        pushNotification: "on",
        status: 1,
      };
      const arrayAdminUsers = {
        id: this.foundSentUser.id,
        user_id: user.id,
        admin: "0",
        notification: "on",
        pushNotification: "on",
        status: 1,
      };
      console.log(arrayAdminUsers, "dfksk");

      this.teamSelectedUserRole.push(arrayAdminUserS);
      this.localSelectedUserRole.push(arrayAdminUsers);
    }
  }

  // Add Avatar to storage
  if (this.groupAvatar.length > 0) {
    const canvas = this.$refs.clipper.clip();
    var dataURL = canvas.toDataURL("image/jpeg", 0.95);
    var blob = this.dataURItoBlob(dataURL);
    var formData = new FormData(document.forms[0]);

    this.groupAvatar.forEach((value, index) => {
      formData.append("images[" + index + "]", blob);
    });

    await axios.post(process.env.VUE_APP_API_URL + "chat/storeGroupAvatar", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      this.teamAvatar = response.data.data;
    });
  }

  // Add counter Array
  this.teamSelectedUser.forEach((userId) => {
    const checkCount = { id: userId, count: 0 };
    this.teamUserUncount.push(checkCount);
  });

  // Create Team
  var dataTeam = await firestoreService.addRoom({
    users: this.teamSelectedUser,
    userRole: this.teamSelectedUserRole,
    userCount: this.teamUserUncount,
    events: [],
    lastUpdated: new Date(),
    createdAtTimestamp: new Date(),
    chatType: "team",
    teamName: this.teamName,
    teamType: this.teamType,
    sportType: this.sportType,
    ageGroup: this.ageGroup,
    gender: this.gender,
    teamAvatar: this.teamAvatar,
    createdBy: currentUserName,
    createdAt: parseTimestamp(new Date(), "DD/MM/YY"),
  });

  await this.postTeamData(dataTeam, index);
},


    // --------------------------------------------------------------------------
    async postTeamData(dataTeam, index) {
      var formData = new FormData();
      formData.append("team_id", dataTeam.id);
      formData.append("team_name", this.teamName);
      formData.append("team_avatar", this.teamAvatar);
      formData.append("team_type", this.teamType);
      formData.append("sport_type", this.sportType);
      formData.append("gender", this.gender);
      formData.append("age_group", this.ageGroup);
      formData.append("created_by", localStorage.getItem("userId"));
      formData.append("users", JSON.stringify(this.localSelectedUserRole));
      console.log(formData,"allii")
      await axios
        .post(process.env.VUE_APP_API_URL + "chat/storeTeamInfo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // Send notification
          const formData2 = {
            team_id: dataTeam.id,
            users_id: this.selectedUsers,
            message_type: 13,
          };
          axios
            .post(
              process.env.VUE_APP_API_URL +
                "notification/sendAddIntoTeamNotification",
              formData2
            )
            .then(() => {
              this.currentTabUrl = localStorage.getItem("currentUrl");
              if (this.currentTabUrl == "/my-teams") {
                this.$root.$emit("showTeamListGameTime");
              } else if (this.currentTabUrl.includes("/chat/team")) {
                this.$root.$emit("showTeamList");
              }
              var elem = this.$refs.closeModalTeam;
              elem.click();
              this.$set(this.isLoadingArray, index, false);
              this.creatingTeam= false;
              this.resetForms();
            })
            .catch(() => {
              alert("Something went wrong");
              this.$set(this.isLoadingArray, index, false);
            });
        })
        .catch(() => {
          alert("Something went wrong");
          this.$set(this.isLoadingArray, index, false);
        });
    },
    resetForms() {
      this.currentTabUrl = localStorage.getItem("currentUrl");
      this.foundSentUser = "";
      this.selectedUsers = [];
      this.selectedIndUsers = "";
      this.teamSelectedUser = [];
      this.localSelectedUserRole = [];
      this.teamSelectedUserRole = [];
      this.teamUserUncount = [];
      this.addIndividualUser = "";
      this.individualMessage = "";
      this.teamName = "";
      if (this.currentTabUrl.includes("/chat/team")) {
        this.teamType = "";
      }
      this.sportType = "";
      this.ageGroup = "";
      this.gender = "";
      this.teamAvatar = "";
      this.isDisabledIndividual = true;
      this.isDisabledTeam = true;
      this.showLoaderTeam = 0;
      this.showLoaderIndividual = 0;
      this.allUsersRef = [];
      document.getElementById("vue-file-upload-input").value = null;
      this.groupAvatar = [];
      this.rawData = "";
      this.foundAlreadyCreatedRoom = "";
      $("#select2-template").val(null).trigger("change");
    },

    getData() {
      this.getSportType();
      this.getAgeGroup();
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    screenHeight() {
      return this.isDevice ? window.innerHeight + "px" : "calc(100vh - 80px)";
    },
    teamTypeOptions() {
    // if (this.isGameTime) {
    //   return [{ value: 'sports', label: 'Sports' }];
    // }
    return [
      { value: 'work', label: 'Work' },
      { value: 'sports', label: 'Sports' },
      { value: 'family', label: 'Family' }
    ];
  }
  },

  watch: {
    selectedIndUsers(newVal) {
      this.checkBlockUser(newVal.user_name, newVal.user_id);
    },
    $route() {
      this.checkURL();
    },
  },
  mounted() {
    this.toggleDiv();
    this.$root.$on(
      "teamChatCounterForChatSystem",
      (teamsChatCounter, individualChatCounter) => {
        this.teamsChatCounter = teamsChatCounter;
        this.individualChatCounter = individualChatCounter;
      }
    );
    this.$root.$on("openAddTeamModal", ( isGameTime) => {
      this.isGameTime = isGameTime; 
      var elem = this.$refs.openAddteam;
      if (elem) {
        elem.click();
        this.resetForms();
        this.getData();
      }
    });
    this.asyncFind();
    // const currentDate = new Date();
    // const currentYear = currentDate.getFullYear();
    // let value = {
    //   name: currentYear,
    // };
    // this.setSelectedYear(value);
    // this.getFilters();
    if (localStorage.getItem("openTabChat") == "Individual") {
      this.showIndividual = 0;
      this.showTeams = 1;
      this.pointActiveIndividual = "active";
      this.pointActiveTeam = "";
    } else {
      this.showIndividual = 1;
      this.showTeams = 0;
      this.pointActiveIndividual = "";
      this.pointActiveTeam = "active";
    }
    this.$root.$on("showIndividualListActive", () => {
      this.showIndividual = 0;
      this.showTeams = 1;
      this.pointActiveIndividual = "active";
      this.pointActiveTeam = "";
    });

    this.$root.$on("resetTeamFilter", () => {
      this.clearFilter();
    });

    this.$root.$on("enableChatSystemButton", () => {
      this.isButtonExecuting = false;
    });

    this.checkURL();
  },
};
</script>
<style>
.select2-container--default {
  width: 100% !important;
}
.chat-system-top {
  background-color: #ffffff;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 10px 10px 10px;
}
@media screen and (max-width: 480px) {
  .vac-text-ellipsis {
    width: 90%;
  }
}

@media screen and (max-width: 991.98px) and (min-width: 900px) {
  .no-data-message {
    left: 16% !important;
    transform: translate(-16%, -50%) !important
  }
  .chat-system-top {
    width: calc(100vw - 62vw);
  }
  .vac-card-window .vac-rooms-container {
    min-width: calc(100vw - 62vw) !important;
  }
  .shim-div {
    min-width: calc(100vw - 62vw) !important;
  }
}
@media screen and (max-width: 899.98px) {
  .chat-selection {
    min-width: calc(100vw - 230px);
  }
  .chat-selection-archive {
    min-width: calc(100vw - 40px);
  }
}

.team-top-image {
  display: flex;
  gap: 10px;
}

#heightAd {
  height: 110px;
}


@media screen and (max-width: 370px) {
  .team-name-type .form-group select {
    padding-right: 0px !important;
  }
}
.message {
  padding-top: 15px;
  font-size: 16px;
}
@media screen and (max-width: 991px) and (min-width: 900px) {
  .chat-selection a {
    font-size: 13px !important;
  }
}

.edit-icon-wrapper {
  position: absolute;
  top: 82%;
  position: absolute;
  top: 85%;
  background-color: #e5f2fe;
  right: 4%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.team-image-create .edit-icon-wrapper img {
  width: 20px !important;
  height: 20px !important;
}

.counter {
  background-color: #ff5b66;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 100px;
  text-align: center;
  color: #ffffff;
}

.chat-options {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.chat-filter-options {
  background: #cce6fd;
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}

.filter-counter {
  background: #254c72;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.filter-info {
  background: #ffe5cd;
  padding: 7px;
  width: calc(100% - 5%);
  margin: 10px auto 0 auto;
  border-radius: 5px;
  min-height: 55px;
  display: flex;
  align-items: center;
}

.filter-info p {
  color: #000000 !important;
  font-family: "Open Sans";
  font-size: 14px !important;
  font-weight: 400 !important;
}

.filter-content {
  width: calc(100% - 5%);
  margin: 0 auto;
}

.filter-content ul {
  display: flex;
  align-items: center;
  gap: 10px;
  list-style-type: none;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.filter-content span {
  padding: 7px;
  background: #f9f9f9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #869cb1 !important;
  font-family: "Open Sans";
  font-size: 14px !important;
  font-weight: 400 !important;
}

.filter-content span img {
  cursor: pointer;
  width: 13px;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
}
</style>
