<template>
  <div>
    <div class="products-list-shim" v-if="events.length == 0 && showLoader == 1">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                  <content-placeholders class="mt-3" :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                </div>
    <div class="events-wrapper" v-if="events.length > 0" >
      <div class="event-list" v-for="event in events" :key="event.index">
        <div
          class="event-list-details event-info-wrap"
          @click="showDetails(event)"
        >
          <EventWrapper :event="event" :selectedRoomTeam="selectedRoomTeam" />
          <WeatherData v-if="isEventWithinFiveDays(event)" :eventId="event.id" />
        </div>
        <UserGoingList
          :selectedRoomTeam="selectedRoomTeam"
          :attendeeListing="event.event_attendee_all"
        />
      </div>

      
    </div>
    <div
        class="no-event-content"
        v-if="events.length == 0"
      >
        <div class="no-event-list">
          <img src="../../../../assets/images/calendar-search.png" alt="" />
          <p class="no-event-header">No upnext events</p>
          <p class="no-event-text">
            All upnext events for this team will appear here.
          </p>
        </div>
      </div>
  </div>
</template>
<script>
import axios from "axios";
import UserGoingList from "./UserGoingList.vue";
// import Weather from "./Weather.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";
import EventWrapper from "./EventWrapper.vue";
export default {
  components: { UserGoingList, 
    // Weather,
    WeatherData,
     EventWrapper },
  name: "UpcomingEvents",
  data() {
    return {
      events: [],
      showLoader: 1,
      roomID: "",
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  methods: {
    isEventWithinFiveDays(event) {
  if (!event?.startDate) return false;
  const eventDate = new Date(event.startDate);
  console.log(eventDate, "kskd");

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  eventDate.setHours(0, 0, 0, 0);

  const diffDays = (eventDate - today) / (1000 * 60 * 60 * 24);

  if (diffDays <= 5 && diffDays >= 0) {
    console.log("Event is within the next 5 days! ✅");
    return true;
  }

  return false;
},

    async fetchUpcomingEvents() {
      try {
        this.showLoader = 1;
        const formData = new FormData();
        formData.append("team_id", this.selectedRoomTeam.id);
        let response = await axios.post("/event/eventUpcomingListing", formData);
        if (response.data.statusCode === 200) {
          this.events = response.data.data.listing.data;
          this.$root.$emit("eventsLength", this.events.length);
          this.showLoader = 0;
        } else {
          this.showLoader = 0;
          console.error("Error fetching upcoming events:", response.data.message);
        }
      } catch (error) {
        this.showLoader = 0;
        console.error("API call failed:", error);
      }
    },
  },
  mounted() {
    this.fetchUpcomingEvents();
    // this.events = this.selectedRoomTeam.upcomingEventsList;
    this.roomID = this.selectedRoomTeam.id;
    this.$root.$on("fetchEventIncoming", (events) => {
      if (this.events.length != events.length) {
        this.$root.$emit("showEventDetailPageBack");
      }
      this.events = events;
    });
  },
};
</script>
