<template>
  <div class="head-main">
    <div class="team-info team-right-info">
      <span class="main-back-arrow" @click="showChat">
        <img src="../../../../assets/images/arrow-left.png" alt="" />
      </span>
      <div class="team-content-wrapper" v-if="showLoader == 0">
        <div class="image-wrapper">
          <div class="shop-products-img-loader" v-if="imgLoader == 1">
            <div class="lds-dual-ring-media"></div>
          </div>
          <img @load="onImageLoad" v-if="foundCurrentUser.avatar != ''" :src="foundCurrentUser.avatar" alt="" />
          <img @load="onImageLoad" v-else src="../../../../assets/images/chat-user.png" alt="" />
        </div>
        <div class="chat-user-info">
          <div>
            <p class="team-name" :id="selectedRoomTeam.id">
              {{ selectedRoomTeam.roomName }}
            </p>
            <p class="user-link">@{{ user_link }}</p>
          </div>
          <div class="follow-wrap">
            <div>
              <span class="follow">Followers</span>
              <span class="count"> {{ followerCount }}</span>
            </div>

            <div>
              <span class="follow"> Followings</span>
              <span class="count"> {{ followingCount }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-wrap mt-1" style="gap: 5px">
            <div class="position-relative">
              <div class="d-flex align-items-center flex-wrap" style="gap: 5px">
                <button class="team-options d-flex align-items-center" style="gap: 5px" @click="showChatOptions"
                  v-click-outside="hideChatOptions">
                  <img src="../../../../assets/Svg/setting-grey.svg" alt="" />
                  <span>Settings</span>
                </button>
              </div>

              <div class="sub-setting-options chat-options-settings" v-if="showOptions">
                <ul>
                  <li data-toggle="modal" data-target="#reportChat">
                    <img src="../../../../assets/Svg/team/team-options/dislike-red.svg" alt="dislike" />
                    <span>Report Chat</span>
                  </li>
                  <li v-if="userBlockFlag == 0" @click="blockUser">
                    <img src="../../../../assets/Svg/slash.svg" alt="block" />
                    <span>Block User</span>
                  </li>
                  <li v-if="userBlockFlag == 1" @click="unblockUser">
                    <img src="../../../../assets/Svg/unlock.svg" alt="unblock" />
                    <span>Unblock User</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showLoader == 1">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
    </div>
    <Report :chatRoomId="roomId" :chatUserId="chatUserId" :chatRoomType="chatRoomType" />
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import report from "../../../Report/report.vue";
import * as firestoreService from "../../../../database/firestore";
export default {
  name: "IndHead",
  components: {
    Report: report,
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentIsFan: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentUserId: {
      type: String,
    },
    userBlockFlag: {
      type: Number,
    },
    showBlockLoader: {
      type: Number,
    },
  },
  data() {
    return {
      followerCount: "0",
      followingCount: "0",
      userId: localStorage.getItem("userId"),
      foundCurrentUser: [],
      showLoader: 0,
      user_link: "",
      imgLoader: 1,
      showOptions: false,
      firstName: "",
      lastName: "",
      chatUserId: "",
      chatRoomType: "",
      roomId: this.selectedRoomTeam.roomId,
    };
  },

  methods: {
    async reportIndividual() {
      if ("selected room", this.selectedRoomTeam) {
        this.selectedRoomTeam.users.forEach((dataU) => {
          if (dataU.c_id != this.userId) {
            this.foundCurrentUser = dataU;
          }
        });
        console.log(this.selectedRoomTeam)
        this.chatUserId = this.foundCurrentUser.c_id;
        this.chatRoomType = "Individual";
        this.roomId = this.selectedRoomTeam.roomId;
      }
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    showChatOptions() {
      this.showOptions = !this.showOptions;
    },
    hideChatOptions() {
      this.showOptions = false;
    },
    showChat() {
      let window_width = window.innerWidth;
      if (window_width <= 991.98) {
        $(".chat-right").hide();
        $(".vac-col-messages").show();
      } else if (window_width >= 992) {
        $(".chat-right").show();
        $(".vac-col-messages").show();
      }
    },
    async allCount() {
      console.log("Calling allCount...");
      console.log("foundCurrentUser:", this.foundCurrentUser);

      if (!this.foundCurrentUser || !this.foundCurrentUser.c_id) {
        console.error("Error: c_id is undefined in foundCurrentUser");
        return;
      }

      this.showLoader = 1;

      try {
        console.log(`Making API request: allCountChat?c_id=${this.foundCurrentUser.c_id}`);
        let allCounts = await axios.get(
          `allCountChat?c_id=${this.foundCurrentUser.c_id}`
        );

        console.log("API Response:", allCounts.data);

        if (allCounts.data.statusCode == 200) {
          this.followerCount = allCounts.data.data.followerCount;
          this.followingCount = allCounts.data.data.followingCount;
          this.user_link = allCounts.data.data.user.user_link;
          this.firstName = allCounts.data.data.user.fname;
          this.lastName = allCounts.data.data.user.lname;

          // Concatenate first and last name
          const fullName = `${this.firstName} ${this.lastName}`;
          this.$root.$emit("checkChatUserName", fullName);
          this.showLoader = 0;
        }
      } catch (error) {
        console.error("Error fetching allCount data:", error);
      }
    },

    async blockUser() {
      if (!this.selectedRoomTeam) {
        console.error("No selected room team found.");
        return;
      }

      let chatUserId = null;

      // Find the correct user c_id
      for (const user of this.selectedRoomTeam.users) {
        if (typeof user === "string") {
          try {
            const userData = await firestoreService.getUser(user);
            console.log("Fetched User Data:", userData);
            if (userData && userData.c_id) {
              chatUserId = userData.c_id;
              break;
            }
          } catch (error) {
            console.error(`Error fetching user with ID ${user}:`, error);
          }
        } else if (typeof user === "object" && user !== null && user.c_id) {
         
          chatUserId = user.c_id;
          break;
        }
      }

      if (!chatUserId) {
        console.error("No valid user found to block.");
        return;
      }

      // Confirmation before blocking
      this.$confirm("About to block the User, Continue?", "Block User").then(() => {
        const formData = new FormData();
        formData.append("blocked_user_id", chatUserId);
        formData.append("type", 1);

        axios
          .post(process.env.VUE_APP_API_URL + "block/blockUserAction", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            $(".vac-box-footer").hide();
            this.$root.$emit("checkBlockUserFlagOnTeam");
          })
          .catch((error) => {
            console.error("Error blocking user:", error);
          });
      });
    },
    async unblockUser() {
      if (!this.selectedRoomTeam) {
        console.error("No selected room team found.");
        return;
      }

      let chatUserId = null;

      // Find the correct user c_id
      for (const user of this.selectedRoomTeam.users) {
        if (typeof user === "string") {
          // Fetch Firestore data if user is a string
          try {
            const userData = await firestoreService.getUser(user);
            console.log("Fetched User Data:", userData);
            if (userData && userData.c_id) {
              chatUserId = userData.c_id;
              break;
            }
          } catch (error) {
            console.error(`Error fetching user with ID ${user}:`, error);
          }
        } else if (typeof user === "object" && user !== null && user.c_id) {
          // If user is an object, extract c_id directly
          chatUserId = user.c_id;
          break;
        }
      }

      if (!chatUserId) {
        console.error("No valid user found to unblock.");
        return;
      }

      // Confirmation before unblocking
      this.$confirm("About to unblock the User, Continue?", "Unblock User").then(() => {
        const formData = new FormData();
        formData.append("blocked_user_id", chatUserId);
        formData.append("type", 2);

        axios
          .post(process.env.VUE_APP_API_URL + "block/blockUserAction", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            $(".vac-box-footer").show();
            this.$root.$emit("checkBlockUserFlagOnTeam");
          })
          .catch((error) => {
            console.error("Error unblocking user:", error);
          });
      });
    },
  },
  mounted() {
    this.showOptions = false
    this.selectedRoomTeam.users.forEach((dataU) => {
      if (dataU.c_id != this.userId) {
        this.foundCurrentUser = dataU;
        this.allCount();
      }
    });
    this.$root.$on("updateIndividualRightSide", (room) => {
      room.users.forEach((dataU) => {
        if (dataU.c_id != this.userId) {
          this.foundCurrentUser = dataU;
          this.allCount();
        }
      });
    });
    this.reportIndividual();
  },
};
</script>
<style scoped>
.team-info .user-details .team-name {
  font-size: 16px;
  font-weight: 500;
  color: #333333 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.team-info .user-details p {
  font-size: 14px;
  font-weight: 400;
}

.team-info .user-details .type {
  color: #188ef5;
}

.team-info .user-details .created-by,
.team-info .user-details .date {
  color: #333333;
  font-size: 14px;
}
</style>
