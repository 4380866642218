<template>
  <div>
    <AddConfiguration :selectedRoomTeam="selectedRoomTeam" />
    <button class="d-none" data-toggle="modal" data-target="#TeamLineUp" ref="openModalLineUp"></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="TeamLineUp">
      <div class="modal-dialog modal-dialog-centered teammates-lineup-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0 text-capitalize">
              LineUp - {{ selectedRoomTeam.teamName }}
            </h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeLineUpModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="teammates-lineup-wrapper">
              <div class="available-teammates">
                <h6>Available Teammates</h6>
                <div class="teammates-info">
                  <draggable class="list-group" :list="this.teammates" group="people" @change="handleTeammateDrag"
                    v-if="showLoaderTeammates == 0">
                    <div class="list-group-item cursor-none" v-for="(element, index) in teammates" :key="index">
                      <div class="shirt-no-wrapper">
                        <div class="shirt-no">
                          <!-- Show the shirt icon only if the user is a player -->
                          <img v-if="element?.isPlayer || element?.teamMember?.isPlayer" src="../../../assets/images/shirt.png" alt="Image" />

                         <!-- Display uniform number only when isPlayer is true, otherwise show nothing -->
                        <span v-if="element?.isPlayer || element?.teamMember?.isPlayer">
                          {{ element?.uniform_no || element?.teamMember?.uniform_no || '-' }}
                        </span>

                        </div>
                        <small class="name">
                          {{
                            (element?.user_profile?.fname && element?.user_profile?.lname)
                              ? element.user_profile.fname + ' ' + element.user_profile.lname
                              : element?.user_profile?.email ||
                              (element?.user_profile?.mob
                                ? (element?.user_profile?.mob_code || '') + element?.user_profile?.mob
                                : '')
                          }}
                        </small>

                      </div>
                      <div class="handle cursor-pointer">
                        <img src="../../../assets/images/line-ellipsis.png" alt=""
                          title="Click an icon to drag and drop the option." />
                      </div>
                    </div>
                  </draggable>
                  <div class="no-line-up" v-if="showLoaderTeammates == 0 && teammates.length == 0">
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No teammates found.</p>
                  </div>
                  <div class="lds-dual-ring-media" v-if="showLoaderTeammates == 1"></div>
                </div>
              </div>
              <div class="team-lineup">
                <h6>Team Lineup</h6>
                <div class="teammates-info position-relative">
                  <draggable class="list-group" :list="lineUp" group="people" @change="log()"
                    v-if="showLoaderLineUp == 0">
                    <div class="list-group-item cursor-none" v-for="(element, index) in lineUp"
                      :key="element.position_index">
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="form-group">
                          <select class="form-control" v-model="element.position_id" :id="element.position_id"
                            @change="setValue($event, element, index)">
                            <option v-for="option in teamLineUp" :key="option.id" :value="option.id">
                              {{ option.position_name }}
                            </option>
                          </select>
                        </div>
                        <div class="shirt-no">
                          <img src="../../../assets/images/shirt.png" alt="Image"
                            title="Click an icon to drag and drop the option." />
                          <span>{{
                            element?.teamMember?.uniform_no ? element?.teamMember?.uniform_no : element?.uniform_no ||
                            '-'
                            }}</span>
                        </div>
                        <small class="name">
                          {{
                          (element?.user_profile?.fname && element?.user_profile?.lname)
                          ? element.user_profile.fname + ' ' + element.user_profile.lname
                          : element?.user_profile?.email ||
                          (element?.user_profile?.mob
                          ? (element?.user_profile?.mob_code || '') + element?.user_profile?.mob
                          : '')
                          }}
                        </small>


                      </div>
                      <div class="handle cursor-pointer">
                        <img src="../../../assets/images/line-ellipsis.png" alt=""
                          title="Click an icon to drag and drop the option." />
                      </div>
                    </div>
                  </draggable>
                  <div class="no-line-up" v-if="showLoaderLineUp == 0 && lineUp.length == 0">
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No lineup found.</p>
                  </div>
                  <div class="lds-dual-ring-media" v-if="showLoaderLineUp == 1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button v-if="this.isDisabledLineUp == false" type="button" class="btn btn-primary w-25"
              v-on:click="saveLineUp(0)" :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button v-else-if="this.isDisabledLineUp == true" type="button" class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import * as firestoreService from "../../../database/firestore";
import AddConfiguration from "./AddConfiguration.vue";
export default {
  name: "TeamLineUpModal",
  data: function() {
    return {
      teammates: [],
      lineUp: [],
      teamLineUp: [],
      rawUsers: [],
      showLoaderTeammates: 1,
      showLoaderLineUp: 1,
      errorMessage: "",
      isLoadingArray: [],
      isDisabledLineUp: true,
      EHid: 0,
      currentUrl: "",
      gameFecthed: {},
    };
  },
  components: {
    draggable,
    AddConfiguration,
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleGame: {
      type: Object,
    },
  },

  methods: {
    handleTeammateDrag: function() {
      this.removePositionFromTeammates();
      if (this.EHid != 0) {
        this.setEHBydefault();
      }
    },

    checkData: function() {
      console.log("hello")
      if (this.lineUp.length > 0) {
        var check = 0;
        this.lineUp.forEach((player) => {
          if (!player.position_id) {
            check = 1;
          }
        });
        if (check == 0) {
          this.isDisabledLineUp = false;
        } else {
          this.isDisabledLineUp = true;
        }
      } else {
        this.isDisabledLineUp = true;
      }
    },

    log: function() {
      this.checkData();
    },

    async fetchUserData(userId) {
      const userFireBase = await firestoreService.getUser(userId);
      this.rawUsers.push(userFireBase);
    },

    fetchData: function() {
      this.showLoaderTeammates = 1;
      const userRoles = this.selectedRoomTeam.userRole;

      (async () => {
        const gameLineUpsWithUserRole = [];

        for (const user of userRoles) {
          await this.fetchUserData(user.id);

          const userData = this.rawUsers[this.rawUsers.length - 1];

          if (user.fan != "1") {
            const isPlayerInLineUp = this.lineUp.some(
              (lineUpPlayer) => lineUpPlayer.player_id === user.id
            );

            if (!isPlayerInLineUp) {
              gameLineUpsWithUserRole.push({
                ...user,
                username: userData.username,
                userId: userData.c_id,
              });
            }
          }
        }

        // Extract last names from full names
        gameLineUpsWithUserRole.forEach((teammate) => {
          const fullName = teammate.username;
          const lastName = fullName.split(' ').pop(); // Assumes last word is the last name
          teammate.lastName = lastName;
        });

        // Sort teammates by last names alphabetically
        gameLineUpsWithUserRole.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );

        // Remove the added last name property
        gameLineUpsWithUserRole.forEach((teammate) => {
          delete teammate.lastName;
        });

        this.teammates = gameLineUpsWithUserRole;
        this.showLoaderTeammates = 0;
      })();
    },


    getLabels: function() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            `game/getPositionsForLineups?field_configuration_id=${this.gameFecthed.field_configuration_id}`
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamLineUp = response.data.data;

            if (this.teamLineUp.length == 0) {
              this.$alert("Add field configuration first.");
            }

            let ehArray = this.teamLineUp.find(
              (option) => option.position_name === "EH"
            );
            if (ehArray) {
              this.EHid = ehArray.id;
            }
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },

    setValue: function(event, player, index) {
      console.log("hellow",event)
      console.log("hellow",player)
      console.log("hellow",event.target.value)
      this.lineUp.forEach((player, playerIndex) => {
        if (player.position_id == event.target.value) {
          this.$set(this.lineUp[playerIndex], "position_id", this.EHid);
          this.lineUp[playerIndex].position_id = this.EHid;
        }
      });
      this.$set(this.lineUp[index], "position_id", event.target.value);
      this.lineUp[index].position_id = event.target.value;
      this.checkData();
    },
    
   
    setEHBydefault: function() {
      this.lineUp.forEach((player, playerIndex) => {
        console.log(player)
        console.log(playerIndex)
        if (!player.position_id) {
          this.$set(this.lineUp[playerIndex], "position_id", this.EHid);
          this.lineUp[playerIndex].position_id = this.EHid;
        }
      });
      this.checkData();
    },

    removePositionFromTeammates: function() {
      this.teammates.forEach((player, playerIndex) => {
        delete this.teammates[playerIndex].position_id;
        delete this.teammates[playerIndex].position_index;
      });
    },

    async saveLineUp(index) {
      this.isDisabledLineUp = true;
      this.$set(this.isLoadingArray, index, true);

      let lineUpApiArray = [];
      this.lineUp.forEach((player, playerIndex) => {
        console.log(player)
        console.log(playerIndex)
        const newArray = {
          game_id: this.gameFecthed.id,
          event_id: this.gameFecthed.event
            ? this.gameFecthed.event.id
            : this.gameFecthed.event_id,
          team_id: this.selectedRoomTeam.id,
          player_id: player.user_id ? player.user_id : player?.id,
          position_id: parseInt(player.position_id),
          position_index: playerIndex + 1,
          status: player.status,
          user_id: player.user_id? player.user_id : "",
        };
        lineUpApiArray.push(newArray);
      });

      const formData = {
        game_id: this.gameFecthed.id,
        data: lineUpApiArray,
      };

      await axios
        .post(process.env.VUE_APP_API_URL + "game/storeLineups", formData, {})
        .then((response) => {
          var elem = this.$refs.closeLineUpModal;
          if (elem) {
            elem.click();
          }
          this.$root.$emit("HitfetchTeamPosition");
          if (this.currentUrl == "/teams") {
            var combineUrl =
              this.selectedRoomTeam.id + "-" + this.gameFecthed.guid;
            const urlToOpen = `/score-game/${combineUrl}`;
            window.location.href = urlToOpen;
          }
          if (this.currentUrl != "/teams") {
            this.$root.$emit("HitTeamsHistory");
          }
          this.$set(this.isLoadingArray, index, false);
          this.isDisabledLineUp = false;
          if (response.data.statusCode == 404) {
            this.errorMessage = response.data.message;
          }
        })
        .catch(() => {
          this.$set(this.isLoadingArray, index, false);
          this.isDisabledLineUp = true;
          alert("Something went wrong");
        });
    },

    async fetchLineups() {
      this.showLoaderTeammates = 1;
      
      this.showLoaderLineUp = 1;
      var formData = new FormData();
      formData.append("gameId", this.gameFecthed.id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.lineUp = response.data.data.gameLineUps;
          this.teammates = response.data.data.teamMemberData.sort((a, b) => {
    const nameA = (a.member?.name || a.user?.name || a.member?.email || a.user?.email || '').toString();
    const nameB = (b.member?.name || b.user?.name || b.member?.email || b.user?.email || '').toString();
    return nameA.localeCompare(nameB);
});


          // const extractedData = this.lineUp.map((item) => ({
          //   game_id: item.game_id,
          //   event_id: item.event_id,
          //   team_id: item.team_id,
          //   player_id: item.player_id,
          //   position_id: parseInt(item.position_id),
          //   position_index: item.position_index,
          //   status: item.status,
          // }));
          // const rawUsers = [];
          // const fetchUserData = async (userId) => {
          //   const userFireBase = await firestoreService.getUser(userId);
          //   rawUsers.push(userFireBase);
          // };

          // (async () => {
          //   const gameLineUpsWithUserRole = [];

          //   for (const user of extractedData) {
          //     await fetchUserData(user.player_id);
          //     const userData = rawUsers[rawUsers.length - 1];

          //     const userRoles = this.selectedRoomTeam.userRole;
          //     let ehArray = userRoles.find(
          //       (option) => option.id === user.player_id
          //     );

          //     gameLineUpsWithUserRole.push({
          //       ...user,
          //       uniform: ehArray ? ehArray.uniform : "",
          //       username: userData.username,
          //       userId: userData.c_id,
          //     });
          //   }
          //   this.lineUp = gameLineUpsWithUserRole;
            this.lineUp.sort((a, b) => a.position_index - b.position_index);
            this.showLoaderLineUp = 0;
          //   this.fetchData();
          // })();
          this.showLoaderTeammates = 0;
          this.checkData();
        });
    },
  },
  mounted() {
    this.$root.$on("openTeamLineUpModal", (gameToGet) => {
      this.gameFecthed = gameToGet;
      this.currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", this.currentUrl);
      if (gameToGet.field_configuration_id == null) {
        if (this.currentUrl == "/teams") {
          this.$root.$emit("AddConfiguration", this.gameFecthed);
        }
        if (this.currentUrl != "/teams") {
          this.$alert("Add field configuration first.");
        }
      } else {
        var elem = this.$refs.openModalLineUp;
        if (elem) {
          elem.click();
        }
        this.getLabels();
        this.fetchLineups();
      }
    });
  },
};
</script>

<style></style>
