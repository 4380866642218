<template>
  <div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="ModalPARK"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">New Park</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEventModalpark"
              @click="closeParkModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <span
              class="label-type error event-error mt-2"
              v-if="errorMessage != ''"
            >
              Park name already added.
            </span>
            <br v-if="errorMessage != ''" />
            <div class="container-fluid no-pdd mt-2">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="parkName"
                      v-model="parkName"
                      @keyup="errorNull"
                      required
                    />
                    <span class="placeholder">Park Name</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <!-- <span>Address</span> -->
                    <gmap-autocomplete
                      :value="address"
                      placeholder="Enter an address, or location"
                      @place_changed="setPlace"
                      ref="autocompleteRef"
                    >
                    </gmap-autocomplete>
                    <!-- <place-autocomplete-field
                      id="address"
                      v-model="address"
                      placeholder="Enter an address/location"
                      label="Address"
                      name="address"
                      class="border-0"
                      @autocomplete-select="errorNull"
                      api-key="AIzaSyAEKVEK50teUWnFIJ6vA4ONqe8ZI27V6J8"
                      v-place-autofill:latitude="marker.position.lat"
                      v-place-autofill:longitude="marker.position.lng"
                    ></place-autocomplete-field> -->
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="city"
                      
                    />
                    <span class="placeholder">City</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="state"
                      
                    />
                    <span class="placeholder">State</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="zipCode"
                      
                    />
                    <span class="placeholder">Zip Code</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      @keyup="errorNull"
                      required
                      v-model="number"
                    />
                    <span class="placeholder">Number</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="event-btns-wrapper px-3 pt-0 pb-3 justify-content-end">
            <button
              v-if="this.isDisabledGame == false"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="createPark(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Park</span>
            </button>
            <button
              v-else-if="this.isDisabledGame == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Park</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "NewPark",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  data() {
    return {
      userName: localStorage.getItem("userName"),
      isDisabledGame: true,
      showLoaderGame: 0,
      address: "",
      parkName: "",
      number: "",
      city: "",
      state: "",
      zipCode: "",
      marker: { position: { lat: 10, lng: 10 } },
      flag: 0,
      parkNameError: "",
      errorMessage: "",
      isLoadingArray: [],
    };
  },

  methods: {
    setPlace(place) {
      if (!place) return;
      this.$refs.autocompleteRef.$el.classList.add("custom-dropdown-open");
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.address = place.formatted_address;
      const addressComponents = place.address_components;

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          this.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        } else if (types.includes("postal_code")) {
          this.zipCode = component.long_name;
        }
      }
      this.errorNull();
    },
    closeParkModal: function() {
      this.address = "";
      var Tabs = 1;
      if (this.flag == 1) {
        this.$root.$emit("checkUpdateEdit", Tabs);
      } else if (this.flag == 2) {
        this.$root.$emit("editedPark", Tabs);
      } else {
        this.$root.$emit("addedPark", Tabs);
      }
      var elem = this.$refs.closeEventModalpark;
      elem.click();
    },
    errorNull: function() {
      if (this.address == "" || this.parkName == "") {
        this.isDisabledGame = true;
      } else {
        this.isDisabledGame = false;
      }
      this.errorMessage = "";
    },

    // Create Park
    async createPark(index) {
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("address", this.address);
      formData.append("park_name", this.parkName);
      formData.append("lat", this.marker.position.lat);
      formData.append("lng", this.marker.position.lng);
      formData.append("number", this.number);
      formData.append("city", this.city);
      formData.append("state", this.state);
      formData.append("zip_code", this.zipCode);

      await axios
        .post(process.env.VUE_APP_API_URL + "game/parkCreate", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var Tabs = 1;
            if (this.flag == 1) {
              this.$root.$emit("checkUpdateEdit", Tabs);
            } else if (this.flag == 2) {
              this.$root.$emit("editedPark", Tabs);
            } else {
              this.$root.$emit("addedPark", Tabs);
            }
            var elem = this.$refs.closeEventModalpark;
            elem.click();
            this.$set(this.isLoadingArray, index, false);
          } else {
            this.errorMessage = response.data.message;
            this.$set(this.isLoadingArray, index, false);
            this.isDisabledGame = true;
          }
        });
    },

    checkCurdPark(index) {
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, false);
      this.address = "";
      this.parkName = "";
      this.number = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.parkNameError = "";
      this.errorNull();
    },
  },
  mounted: function() {
    this.$root.$on("AddPark", () => {
      this.checkCurdPark(1);
    });
    this.$root.$on("AddParkInitiate", (tabs) => {
      this.flag = tabs;
    });
  },
};
</script>
<style>
.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}

.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}

.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

a {
  font-size: 12px;
  font-weight: 500;
  margin: 0 7px;
  color: #188ef5 !important;
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
div.form-group .add-notes {
  font-size: 14px;
}
</style>
