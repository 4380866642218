<template>
  <div>
    <InviteToTeamModal
      :currentUserId="currentUserId"
      :selectedRoomTeam="selectedRoomTeam"
    />

    <div class="chat-right-setting-wrap">
      <div class="head-main">
        <div class="team-info team-right-info">
          <span class="main-back-arrow" @click="showChat">
            <img src="../../../../assets/images/arrow-left.png" alt="" />
          </span>
          <div class="team-content-wrapper">
            <div class="image-wrapper">
              <div class="shop-products-img-loader" v-if="showLoader == 1">
                <div class="lds-dual-ring-media"></div>
              </div>
              <div
                class="default-team-avatar"
                v-if="selectedRoomTeam.teamAvatar == ''"
              >
                <img
                  @load="onImageLoad"
                  src="../../../../assets/Svg/team/right-team-avatar.svg"
                  alt=""
                />
              </div>
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar != ''"
                :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
                alt=""
                @click="OpenTeamPhotoPreview"
              />
            </div>
            <div class="cover-photo-modal" v-if="openTeamPhoto">
              <button
                class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
                @click="CloseTeamPhotoPreview"
              >
                <i class="fa fa-times"></i>
              </button>
              <div
                class="cover-img-full-preview"
                @click="CloseTeamPhotoPreview"
              >
                <img
                  @load="onImageLoad"
                  v-if="selectedRoomTeam.teamAvatar != ''"
                  :src="
                    this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="user-details data-info">
              <div
                class="d-flex flex-column align-items-center justify-content-between flex-wrap w-100"
              >
                <span class="name-team" :id="selectedRoomTeam.id">
                  {{ selectedRoomTeam.teamName }}
                </span>
              </div>

              <div
                class="d-flex flex-column align-items-center justify-content-between flex-wrap w-100"
              >
                <div>
                  <div class="w-100 w-lg-75">
                    <div
                      class="d-flex align-items-center flex-column flex-wrap mb-3"
                      style="gap: 4px"
                    >
                      <span
                        class="type-team text-capitalize"
                        v-if="
                          selectedRoomTeam.teamType == 'sports' &&
                          selectedRoomTeam.sportType != undefined
                        "
                      >
                        {{ selectedRoomTeam.sportType }}
                      </span>

                      <span v-else class="type-team text-capitalize">{{
                        selectedRoomTeam.teamType
                      }}</span>

                      <div
                        class="d-flex align-items-center flex-wrap"
                        style="gap: 4px"
                      >
                        <span
                          class="gender-group"
                          v-if="
                            selectedRoomTeam.teamType == 'sports' &&
                            selectedRoomTeam.sportType != undefined
                          "
                        >
                          {{ selectedRoomTeam.ageGroup }}
                        </span>

                        <span
                          class="gender-group"
                          v-if="
                            selectedRoomTeam.teamType == 'sports' &&
                            selectedRoomTeam.sportType != undefined
                          "
                        >
                          {{ selectedRoomTeam.gender }}
                        </span>
                      </div>
                    </div>
                    <TeamRecord
                      :selectedRoomTeam="selectedRoomTeam"
                      :showLoader="showLoader"
                      :totalGame="totalGame"
                      :win="win"
                      :loss="loss"
                      v-if="selectedRoomTeam.teamType == 'sports'"
                    />
                  </div>
                </div>

                <div
                  class="d-flex align-items-center flex-wrap mt-1"
                  style="gap: 5px"
                >
                  <div class="position-relative">
                    <div
                      class="d-flex align-items-center flex-wrap"
                      style="gap: 5px"
                    >
                      <button
                        v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
                        class="team-options d-flex align-items-center"
                        style="gap: 5px"
                        @click="inviteToTeamModalCall()"
                      >
                        <img
                          src="../../../../assets/Svg/team/invite-teammates.svg"
                          alt=""
                        />
                        <span>Invite To Team</span>
                      </button>
                      <button
                        v-if="selectedRoomTeam.teamType === 'sports'"
                        class="team-options d-flex align-items-center"
                        style="gap: 5px"
                        @click="goToPlayerStatistics"
                      >
                        <img
                          src="../../../../assets/Svg/team/presention-chart.svg"
                          alt=""
                        />
                        <span style="">Statistics</span>
                      </button>
                    </div>

                    <!-- <div class="sub-setting-options" v-if="showOptions">
                      <ul>
                        <li
                          v-if="selectedRoomTeam.teamType === 'sports'"
                          @click="goToPlayerStatistics"
                        >
                          Player Statistics
                        </li>
                        <li
                          v-if="selectedRoomTeam.teamType === 'sports'"
                          @click="goToTeamStatistics"
                        >
                          Team Statistics
                        </li>
                      </ul>
                    </div> -->
                  </div>

                  <div class="position-relative">
                    <TeamOptions
                      :selectedRoomTeam="selectedRoomTeam"
                      :currentUserId="currentUserId"
                      :currentIsAdmin="currentIsAdmin"
                      :currentIsFan="currentIsFan"
                      :currentTeamIsActive="currentTeamIsActive"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import TeamRecord from "./TeamRecord.vue";
import TeamOptions from "./TeamOptions.vue";
import InviteToTeamModal from "./InviteToTeamModal.vue";
export default {
  name: "SettingHead",
  components: {
    TeamRecord,
    TeamOptions,
    InviteToTeamModal,
  },
  data: function () {
    return {
      showLoader: 1,
      openTeamPhoto: false,
      showOptions: false,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentIsFan: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    totalGame: {
      type: Number,
    },
    win: {
      type: Number,
    },
    loss: {
      type: Number,
    },
  },
  methods: {
    inviteToTeamModalCall() {
      this.$root.$emit("openInviteToTeamModal");
    },
    goToPlayerStatistics() {
      if (this.selectedRoomTeam && this.selectedRoomTeam.id) {
        this.$router.push({
          path: `/my-team/player-statistics/${this.selectedRoomTeam.id}`,
        });
      } else {
        console.error("No team selected.");
      }
    },
    goToTeamStatistics() {
      if (this.selectedRoomTeam && this.selectedRoomTeam.id) {
        this.$router.push({
          path: `/my-team/team-statistics/${this.selectedRoomTeam.id}`,
        });
      } else {
        console.error("No team selected.");
      }
    },
    showStatsOptions() {
      this.showOptions = !this.showOptions;
    },
    hideStatsOptions() {
      this.showOptions = false;
    },
    OpenTeamPhotoPreview() {
      this.openTeamPhoto = true;
    },
    CloseTeamPhotoPreview() {
      this.openTeamPhoto = false;
    },
    onImageLoad() {
      this.showLoader = 0;
    },
    showChat() {
      let window_width = window.innerWidth;
      if (window_width <= 991.98) {
        $(".chat-right").hide();
        $(".vac-col-messages").show();
        this.$root.$emit("ChatSettings", 0);
        this.$root.$emit("fetchEvents");
      } else if (window_width >= 992) {
        $(".chat-right").show();
        $(".vac-col-messages").show();
      }
    },
  },
};
</script>
