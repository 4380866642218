<template>
  <div>
    <button
    class="d-none"
      data-toggle="modal"
      data-target="#exampleModalEdit"
      ref="openModalXl"
      @click="checkUpdate"
    >
      
    </button>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalEdit"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6
              class="modal-title"
             
              id="exampleModalEditLabel"
            >
              Update Game
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEventModal"
              @click="closePark"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid no-pdd">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="gameName"
                      v-model="gameName"
                      @keyup="errorNull"
                      required
                      maxlength="30"
                    />
                    <span class="placeholder">Game Name</span>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="opponentName"
                      v-model="opponentName"
                      @keyup="errorNull"
                      required
                      maxlength="30"
                    />
                    <span class="placeholder">Opponent Name</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group pdd-adjust-type">
                    <label for="startDateCheck">Opponent Country</label>
                    <country-select
                      v-model="country"
                      :country="country"
                      :countryName="true"
                      @change.native="errorNull"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 pl-md-0">
                  <div class="form-group">
                    <label for="startTimeCheck">Opponent Region</label>
                    <region-select
                      v-model="region"
                      :country="country"
                      :countryName="true"
                      :regionName="true"
                      :region="region"
                      @change.native="errorNull"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="opponentCity">Opponent City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="opponentCity"
                      v-model="opponentCity"
                      @keyup="errorNull"
                      required
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 pl-md-0">
                  <div class="form-group">
                    <label class="typo__label" for="ajaxsingle"
                      >Event Alert</label
                    >
                    <select
                      class="form-control form-control-lg"
                      id="eventAlert"
                      v-model="eventAlert"
                      @change="errorNull"
                    >
                      <option value="" selected disabled>Event Alert</option>
                      <option
                        v-for="option in eventAlertOptionSelect"
                        :key="option.id"
                      >
                        {{ option.statement }} {{ option.hours }} hour(s)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group pdd-adjust-type">
                    <label for="startDateCheck">Start Date</label>
                    <span
                      class="label-type error event-error ml-2"
                      v-if="startDateError != ''"
                    >
                      Please Select Start Date.
                    </span>
                    <date-picker
                      :open="open"
                      @close="open = false"
                      :editable="false"
                      :clearable="false"
                      @click.native="handleClickStart"
                      v-model="startDate"
                      valueType="format"
                      format="YYYY-MM-DD"
                      placeholder="yyyy-mm-dd"
                      @change="checkdate"
                    >
                      <i slot="icon-calendar" class="cursor-pointer">
                        <img src="../../../assets/images/calendar-input.png" />
                      </i>
                    </date-picker>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 pl-md-0">
                  <div class="form-group">
                    <label for="startTimeCheck">Start Time</label>
                    <span
                      class="label-type error event-error ml-2"
                      v-if="timeError != ''"
                    >
                      Event Time is Invalid.
                    </span>
                    <div
                      class="input-group time"
                      id="startTimeCheck"
                      data-target-input="nearest"
                    >
                      <vue-timepicker
                        id="startTime"
                        class="time-input"
                        placeholder="Start Time"
                        required="required"
                        v-model="startTime"
                        format="hh:mm A"
                        v-on:change="checkTime"
                        close-on-complete
                        hide-clear-button
                      >
                        <template v-slot:icon>
                          <img src="../../../assets/images/clock-input.png" />
                        </template>
                      </vue-timepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-sm-12 col-md-6">
                  <div class="position-relative">
                    <label class="typo__label" for="ajaxsingle"
                      >Park Name</label
                    >
                    <button @click="openPark" class="button-overlay"></button>
                    <multiselect
                      ref="multiselectParkName"
                      v-model="partName"
                      id="ajaxsingle"
                      label="park_name"
                      track-by="id"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="parkNameOptions"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="getGamePark"
                      @input="checkParkField"
                      @select="dispatchAction"
                      @open="isOpenPark = true"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag"
                          >&nbsp;<span> {{ option.park_name }}</span
                          ><span
                            class="custom__remove"
                            v-on:click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      >
                      <template slot="clear" slot-scope="props">
                        <div
                          class="multiselect__clear"
                          v-if="parkNameOptions.length"
                          @mousedown.prevent.stop="clearAll(props.search)"
                        ></div> </template
                      ><span slot="noResult"
                        >Oops! No Park found. Consider changing the search
                        query.</span
                      >
                      <span slot="noOptions"> No Park Found </span>
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <span> {{ option.park_name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 mt-2 mt-md-0 pl-md-0">
                  <div class="">
                    <label class="typo__label" for="ajaxsingle"
                      >Field Name</label
                    >
                    <button @click="openField" class="button-overlay"></button>
                    <multiselect
                      ref="multiselectParkField"
                      v-model="parkField"
                      id="ajaxsingle"
                      label="field_name"
                      track-by="id"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="fieldNameOptions"
                      :searchable="true"
                      :loading="isLoadingField"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="getParkField"
                      @input="errorNull"
                      @select="dispatchfieldAction"
                      @open="isOpenField = true"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag"
                          >&nbsp;<span> {{ option.field_name }}</span
                          ><span
                            class="custom__remove"
                            v-on:click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      ><span slot="noResult"
                        >Oops! No Field found. Consider changing the search
                        query.</span
                      >
                      <span slot="noOptions"> No Field Found </span>
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <span> {{ option.field_name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <textarea
                      type="text"
                      class="form-control pt-3 mt-2 add-notes"
                      id="note"
                      rows="4"
                      v-model="note"
                      @keyup="errorNull"
                      maxlength="200"
                      placeholder="Comments"
                    ></textarea>
                  </div>
                </div>
                <a
                  class="d-none"
                  data-toggle="modal"
                  data-target="#ModalPARK"
                  ref="callPark"
                  @click="closeMain"
                >
                  Add New Park
                </a>
                <a
                  class="d-none"
                  data-toggle="modal"
                  data-target="#ModalField"
                  ref="callField"
                  @click="newField"
                >
                  Add New Field
                </a>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabledGame == false"
              type="button"
              class="btn btn-primary w-25"
              v-on:click="updateGame(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabledGame == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewPark />
    <NewField />
  </div>
</template>
<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import Multiselect from "vue-multiselect";
import NewPark from "./AddPark";
import NewField from "./AddField";

export default {
  components: {
    Multiselect,
    NewPark,
    NewField,
    VueTimepicker,
  },
  name: "NewGame",
  data() {
    return {
      open: false,
      isLoadingArray: [],
      userName: localStorage.getItem("userName"),
      isDisabledGame: true,
      showLoaderGame: 0,
      singleGame: [],
      gameName: "",
      eventId: "",
      opponentName: "",
      association: "",
      eventAlert: "",
      partName: "",
      parkField: "",
      exactStartTime: "10:00 AM",
      exactStartDate: "",
      startDate: "",
      startTime: "10:00",
      note: "",
      startDateError: "",
      exactDateformat: "",
      timeError: "",
      eventAlertOptionSelect: this.eventAlertOptions,
      fieldNameOptions: [],
      parkNameOptions: [],
      originalParkNameOptions: [],
      isLoading: false,
      isLoadingField: false,
      changePark: 0,
      changeField: 0,
      country: "",
      region: "",
      opponentCity: "",
      previousName: "",
      parkId: "",
      isOpenPark: false,
      isOpenField: false,
      currentUrl: "/",
      currentTabUrl: "",
    };
  },

  props: {
    eventAlertOptions: {
      type: Array,
    },
  },

  methods: {
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    openPark() {
      this.getGamePark();
      setTimeout(() => {
        this.$refs.multiselectParkName.activate();
      }, 1000);
    },

    openField() {
      this.getParkField();
      setTimeout(() => {
        this.$refs.multiselectParkField.activate();
      }, 1000);
    },

    checkParkField: function() {
      this.parkId = this.partName.id;
      this.getParkField();
      this.errorNull();
    },
    errorNull: function() {
      if (
        this.gameName == "" ||
        this.exactStartDate == "" ||
        this.exactStartTime == "" ||
        this.opponentName == "" ||
        this.eventAlert == "" ||
        this.partName.id == 1 ||
        this.opponentCity == "" ||
        this.country == "" ||
        this.region == ""
      ) {
        this.isDisabledGame = true;
      } else {
        this.isDisabledGame = false;
      }
    },
    closePark() {
      var start_time = document.getElementById("startTime");
      if (start_time.value != "") {
        start_time.value = "";
      }
    },

    // Check dates
    checkdate: function() {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear; //dd-mm-YYYY
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate = month[startMonth - 1] + " " + startDay + ", ";
      }

      this.checkTimeDate();
    },

    dispatchAction(actionName) {
      this.parkField = "";
      this.fieldNameOptions = [];
      switch (actionName.park_name) {
        case "Add Park":
          var tabs = 2;
          this.$root.$emit("AddParkInitiate", tabs);
          var elem = this.$refs.callPark;
          elem.click();
          break;
        default:
          this.changePark = 1;
      }
    },
    dispatchfieldAction(actionName) {
      console.log("Dispatch Field Action:", actionName);
      switch (actionName.field_name) {
        case "Add field":
          var tabs = 2;
          this.$root.$emit("AddFieldInitiate", {
            tabs,
            park_id: this.parkId,
          });
          var elem = this.$refs.callField;
          console.log("Triggering Add Field Modal");
          elem.click();
          break;
        default:
          this.changeField = 1;
      }
    },
    closeMain() {
      this.$root.$emit("AddPark");
      var elem = this.$refs.closeEventModal;
      elem.click();
    },
    newField() {
      this.$root.$emit("AddField");
      var elem = this.$refs.closeEventModal;
      elem.click();
    },

    checkTime: function() {
      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          hours -= 12;
        } else if (hours < 12) {
          if (hours == 0) {
            hours = 12;
          }
        }
        this.exactStartTime = hours + ":" + minutes;
        this.timeError = "";
        this.isDisabledGame = false;
      }

      this.checkTimeDate();
    },

    checkTimeDate() {
      this.startDateError = this.exactStartDate != "" ? "" : "on";
      this.errorNull();
      if (this.exactStartTime != "" && this.exactStartDate != "") {
        //   var today = new Date();
        //   // Start Date
        //   const startDateArray = this.startDate.split("-");
        //   var startMonth = startDateArray[1];
        //   var startDay = startDateArray[2];
        //   var startYear = startDateArray[0];

        //   const startTimeArray = this.exactStartTime.split(":");
        //   const startTimeAMArray = startTimeArray[1].split(" ");

        //   var hours = startTimeArray[0];
        //   var minutes = startTimeAMArray[0];
        //   var AMPM = startTimeAMArray[1];
        //   if (AMPM == "PM" && hours < 12) hours = parseInt(hours) + 12;
        //   if (AMPM == "AM" && hours == 12) hours = parseInt(hours) - 12;
        //   var sHours = hours;
        //   var sMinutes = minutes;
        //   if (hours < 10) sHours = "0" + sHours;
        //   if (minutes < 10) sMinutes = "0" + sMinutes;
        //   var finalStartDateTime = new Date(
        //     startYear,
        //     startMonth - 1,
        //     startDay,
        //     sHours,
        //     sMinutes
        //   );
        //   if (finalStartDateTime < today) {
        //     this.timeError = "on";
        //     this.isDisabledGame = true;
        //   } else {
        //     this.timeError = "";
        this.exactDateformat = this.exactStartDate + " " + this.exactStartTime;
      }
      this.errorNull();
      // }
    },

    getEventAlert() {
      this.eventAlertOptionSelect = this.eventAlertOptions;
    },

    getGamePark(searchQuery) {
      this.isLoading = true;
      console.log(this.singleGame,"add")
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getGamePark?search=${
            searchQuery ? searchQuery : ""
          }&event_id=${this.singleGame.event_id}`, // Include event_id in query params
          this.details
        )
        .then((response) => {
          this.parkNameOptions = response.data.data.parkName;
          console.log(this.parkNameOptions);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching game park:", error);
          this.isLoading = false;
        });
    },

    getParkField(searchQuery) {
      this.isLoadingField = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getParkField?park_id=${
            this.parkId
          }&search=${searchQuery ? searchQuery : ""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.fieldNameOptions = response.data.data.fields;
//           this.fieldNameOptions.sort((a, b) => {
//   if (a.field_name === "Add Field") return -1; // Keep "Add Field" at the top
//   if (b.field_name === "Add Field") return 1;  // Keep "Add Field" at the top
//   return a.field_name.localeCompare(b.field_name, undefined, { numeric: true });
// });this.fieldNameOptions.sort((a, b) => {
//   // Skip the first element ("Add Field") since it's always at the top
//   if (a.field_name === "Add field") return -1;
//   if (b.field_name === "Add field") return 1;

//   // Check if both are numeric
//   const isANumber = /^\d+$/.test(a.field_name);
//   const isBNumber = /^\d+$/.test(b.field_name);

//   // If both are numeric, sort them numerically
//   if (isANumber && isBNumber) {
//     return parseInt(a.field_name, 10) - parseInt(b.field_name, 10);
//   }

//   // If only one is numeric, prioritize it over non-numeric fields
//   if (isANumber) return -1;
//   if (isBNumber) return 1;

//   // For non-numeric fields, sort alphanumerically
//   return a.field_name.localeCompare(b.field_name, undefined, { numeric: true });
// });


          this.isLoadingField = false;
        });
    },

    // Create Event
    async updateGame(index) {
      this.showLoaderGame = 1;
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();

      formData.append("id", this.singleGame.id);
      formData.append("name", this.gameName ? this.gameName : "");
      formData.append(
        "opponent_name",
        this.opponentName ? this.opponentName : ""
      );
      formData.append("start_date", this.startDate);
      const startTimeWithoutMeridian = this.startTime.replace(/\s*([AaPp][Mm])\s*/, "");
      formData.append("start_time", startTimeWithoutMeridian);
      formData.append("exactDateFormat", this.exactDateformat);
      formData.append("event_alert", this.eventAlert);
      formData.append("opponent_country", this.country);
      formData.append("opponent_state", this.region);
      formData.append("opponent_city", this.opponentCity);
      console.log(this.changeField,"jsjs")
      if (this.changePark == 1) {
        formData.append("park_name", this.partName.id);
        
      }
      if (this.changeField==1) {
          formData.append("field_id", this.parkField.id);
        }
      formData.append("comment", this.note ? this.note : "");

      await axios
        .post(process.env.VUE_APP_API_URL + "game/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 300) {
            alert(response.data.message);
          }

          // var linkUrl = window.location.pathname;
          // var segment_array = linkUrl.split("/");
          // var lastSegment = segment_array.pop();
          // if (lastSegment == "teams") {
          //   this.$root.$emit("showGameList");
          // } 
          // else {
          //   this.$root.$emit("showSingleBoxScore");
          // }

          var currentRouteName = this.$router.currentRoute.name;

if (currentRouteName === "Teams") {
  this.$root.$emit("showGameList");
} 
else if (currentRouteName === "SingleEventDetail") {
  this.$root.$emit("showEventPageGames")
} else {
  this.$root.$emit("showSingleBoxScore");
}
          this.tabsTo = 0;
          var elem = this.$refs.closeEventModal;
          elem.click();
          this.showLoaderGame = 0;
          this.$set(this.isLoadingArray, index, false);
          this.isDisabledGame = false;

          this.changePark = 0;
          this.changePark = 0;
        });
    },

    checkUpdate() {

      this.isDisabledGame = true;
      this.showLoaderGame = 0;
      const exactDateFormat = this.singleGame.exactDateFormat.trim();

      // Extract time using regex (matches "10:34 AM" format)
      const timeMatch = exactDateFormat.match(/(\d{1,2}):(\d{2})\s?(AM|PM)/i);

      if (timeMatch) {
        let hours = parseInt(timeMatch[1], 10);
        let minutes = parseInt(timeMatch[2], 10);
        let ampm = timeMatch[3].toUpperCase();

        if (ampm === 'PM' && hours !== 12) {
          hours += 12;
        } else if (ampm === 'AM' && hours === 12) {
          hours = 0;
        }

        this.startTime = this.formatTime(
          `${hours % 12 === 0 ? 12 : hours % 12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`
        );
      }
      // const date = new Date(this.singleGame.exactDateFormat);
      // const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const ampm = hours >= 12 ? 'PM' : 'AM';
      // this.startTime = this.formatTime(`${hours % 12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`);
      this.gameName = this.singleGame.name;
      this.opponentName = this.singleGame.opponent_name;
      this.eventAlert = this.singleGame.event_alert;
      this.partName = this.singleGame;
      this.parkField = this.singleGame.field;
      this.parkId = this.singleGame.park_id;
      this.eventId = this.singleGame.event_id;
      this.previousName = this.singleGame;
      this.exactStartTime = "10:00 AM";
      this.exactStartDate = "";
      this.startDate = this.singleGame.start_date;
      // this.startTime = this.formatTime(this.singleGame.start_time);
      this.note = this.singleGame.comment;
      this.startDateError = "";
      this.exactDateformat = "";
      this.timeError = "";
      this.parkNameOptions = [];
      this.country = this.singleGame.opponent_country;
      this.region = this.singleGame.opponent_state;
      this.opponentCity = this.singleGame.opponent_city;
      console.log("hi", this.singleGame);
      this.getEventAlert();
      this.checkdate();
      this.checkTime();
      this.errorNull();
    },

formatTime(timeString) {
  const [hours, minutes] = timeString.split(":");
  let formattedHours = parseInt(hours, 10) % 12 || 12; // Convert hours to 12-hour format
  const meridian = parseInt(hours, 10) >= 12 ? "PM" : "AM"; // Determine meridian (AM or PM)
  formattedHours = formattedHours < 10 ? "0" + formattedHours : formattedHours; // Add leading zero if necessary
  return `${formattedHours}:${minutes} ${meridian}`;
}
  },

  mounted() {
    //  Don't allow to select past date
    // var dtToday = new Date();
    // var month = dtToday.getMonth() + 1;
    // var day = dtToday.getDate();
    // var year = dtToday.getFullYear();

    // if (month < 10) month = "0" + month.toString();
    // if (day < 10) day = "0" + day.toString();

    // var maxDate = year + "-" + month + "-" + day;
    // $("#startDateS").attr("min", maxDate);

    this.$root.$on("checkUpdateGame", (data, eventOptions) => {
      this.eventAlertOptions = eventOptions
      this.singleGame = data;
      var elem = this.$refs.openModalXl;
      if(elem){
        elem.click();
      }
      this.startDateError = "";
      this.checkUpdate();
      
    });
    // -----------------------------------
    this.$root.$on("editedPark", (Tabs) => {
      this.tabsTo=Tabs
      var elem = this.$refs.openModalXl;
      if(elem){
      elem.click();
      }
    });
    this.$root.$on("editedField", (Tabs) => {
      this.tabsTo=Tabs
      var elem = this.$refs.openModalXl;
      if(elem){
      elem.click();
      }
    });

    this.$root.$on("checkMain", () => {
      this.closeMain();
    });
  },
};
</script>
<style>
.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}

.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}

.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

a {
  font-size: 12px;
  font-weight: 500;
  color: #188ef5 !important;
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
div.form-group .add-notes {
  font-size: 14px;
}
.form-group select {
  font-size: 14px !important;
  min-height: 45px !important;
  display: block !important;
  padding: 8px 40px 8px 8px !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
  background: #fff !important;
  border-radius: 5px !important;
}

.button-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transform: translate(0, -100%);
  background: transparent;
  border: none;
  z-index: 99;
  height: 50px;
}
</style>
